import React, { KeyboardEvent, useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { AppState, checkSession, setLoggedIn, showSnackbar } from "../../AppState";
import { Column, Filler, FormFieldText, Page } from "../../Components";
import { ILoginRequest, login } from "../../Managers/API";
import { JumbotronHeader } from "./JumboTron";
import "./Login.scss";
import { LoginForm } from "./Forms";
import { URLS } from "../URLS";
import { useTranslation } from "react-i18next";
import { ValidationMessages } from "../../Enums";
import styled from "@emotion/styled";

export const FormColumn = styled(Column)`
  padding: 0 15px;
`;

export const Login: React.FC = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation("login");
  const [capsLockOn, setCapsLockOn] = useState(false);

  const schema = yup.object({
    identifier: yup.string().email(t(ValidationMessages.EMAIL)).required(t("login:errors.username")),
    password: yup.string().min(8, t("login:errors.password")).required(t("login:errors.password")),
  });

  useEffect(() => {
    // We are the default route. If we arrive here, and we're already logged in, hop over to dashboard
    if (AppState.isAuthenticated) {
      // Handle the case we land here but are already logged in
      console.log("[LOGIN] Already authenticated, going to dashboard");
      navigate(URLS.Dashboard);
    } else {
      // We often end up here via live-reload if it wipes too much of our state. So we need to repeat the session check.
      checkSession()
        .then((success) => {
          if (success) {
            navigate(URLS.Dashboard);
            console.log("[LOGIN] Loaded session, navigating to dashboard");
          }
        })
        .catch(() => {});
    }
  }, []);

  const handleLogin = (values: ILoginRequest, formik: any) =>
    login(values)
      .then((b) => {
        let r = b.data;
        console.log("Login result", r);
        setLoggedIn(true, r.bapi_refresh_token, r.user);
        navigate(URLS.Dashboard);
      })
      .catch((e) => {
        console.log("Login error:", e.response.data);
        const error = t("login:errors.login", { context: e.response.status });
        showSnackbar(error, "error");
        formik.setFieldError("identifier", error);
        formik.setFieldError("password", error);
      });

  const handleRegister = () => navigate(URLS.Register);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const caps = event.getModifierState("CapsLock");
    if (caps && !capsLockOn) {
      setCapsLockOn(true);
    } else if (!caps && capsLockOn) {
      setCapsLockOn(false);
    }
  };

  const initialValues: ILoginRequest = {
    identifier: "",
    password: "",
  };

  return (
    <Page>
      <div className={"jumbotron mobile-fullscreen"}>
        <JumbotronHeader />
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleLogin} validateOnMount={true} enableReinitialize>
          {({ isSubmitting, handleSubmit }) => (
            <LoginForm>
              <div className={"row-to-tablet-mobile-column"}>
                <FormColumn>
                  <FormFieldText
                    displayError={true}
                    name="identifier"
                    disabled={isSubmitting}
                    style={{ width: "100%" }}
                    label={t("login:username")}
                    type="text"
                    onKeyDown={handleKeyDown}
                  />
                </FormColumn>

                <FormColumn>
                  <FormFieldText
                    displayError={true}
                    disabled={isSubmitting}
                    name="password"
                    style={{ width: "100%" }}
                    label={t("login:password")}
                    type="password"
                    hint={
                      capsLockOn
                        ? {
                            className: "u-color-warning",
                            message: t("common:errors.caps_lock"),
                          }
                        : undefined
                    }
                    onKeyDown={handleKeyDown}
                  />
                  <div className={"forgot-password"}>
                    <Button variant="text" onClick={() => navigate(URLS.ForgotPassword)} disabled={isSubmitting} sx={{ paddingTop: 0 }}>
                      {t("login:forgot_password")}
                    </Button>
                  </div>
                </FormColumn>
              </div>

              <div className={"row-to-tablet-mobile-column"}>
                <div className={"left-button column-to-mobile-row"}>
                  <div style={{ fontSize: 12 }}> {t("login:new_account")}</div>
                  <Button variant="contained" key={0} className={"u-desktop-only"} onClick={handleRegister} disabled={isSubmitting}>
                    {t("login:register")}
                  </Button>
                  <Button
                    variant="text"
                    key={1}
                    className={"mobile-register u-desktop-hide"}
                    onClick={handleRegister}
                    disabled={isSubmitting}>
                    {t("login:register_now")}
                  </Button>
                </div>
                <FormColumn className={"right-button"}>
                  <Button
                    className="flat-button"
                    style={{ marginTop: 0 }}
                    variant="contained"
                    onClick={() => handleSubmit()}
                    type={"submit"}
                    disabled={isSubmitting}>
                    {t("login:login")}
                  </Button>
                </FormColumn>
              </div>
            </LoginForm>
          )}
        </Formik>
      </div>
      <Filler />
    </Page>
  );
});
