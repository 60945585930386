import React from "react";
import { IStandardInput } from "./Types";
import { CountrySelect } from "./CountrySelect";
import { Row } from "./PageLayout";
import "./PhoneInput.scss";
import { FormFieldText } from "./FormFieldText";
import { InputLabel } from "./InputLabel";
import { useField } from "formik";
import { ICountry } from "../Managers";

interface IPhoneInput extends Omit<IStandardInput, "onChange"> {
  countryName: string;
  onChange?: (country: ICountry, phone: string) => void;
}

export const PhoneInput: React.FC<IPhoneInput> = ({
  className = "",
  name,
  countryName,
  label,
  type = "text",
  required = false,
  autoComplete = "off",
  onChange,
  ...rest
}) => {
  const [field] = useField(name);
  const [countryField] = useField(countryName);

  const handleCountryChange = (country: ICountry) => {
    if (!onChange) {
      return;
    }
    onChange(country, field.value);
  };

  const handleNumberChange = (number: string) => {
    if (!onChange) {
      return;
    }

    onChange(countryField.value, number);
  };

  return (
    <div className={className}>
      <InputLabel name={name} label={label} required={required} />
      <Row className="phone-input-wrapper">
        <CountrySelect onChange={handleCountryChange} name={countryName} selectedCountryLabel="dial_code" showOnlyFlagAndDialCode={true} />
        <FormFieldText
          onChange={(e) => handleNumberChange(e.target.value)}
          displayError={true}
          {...rest}
          name={name}
          required={required}
          type={type}
          autoComplete={autoComplete}
        />
      </Row>
    </div>
  );
};
