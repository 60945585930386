import { ISelectOption, RangePicker, SelectInput } from "../../Components";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IDeviceType, IExportPreset, ISensorType } from "../../Managers/Types";
import { getExportFilters } from "../../Managers";
import { ExportFilter, IExportFilter } from "./Export";
import { DrawerIcon, FiltersIcon } from "../../icon";
import { showAppModal } from "../../AppState";
import { PresetModal } from "./Modals/PresetModal";

interface IExportHeaderProps {
  deviceTypes: IDeviceType[];
  filters: ExportFilter;
  sensorTypes: ISensorType[];
  setDateRange: (start: Date, end: Date) => void;
  setDrawerOpen: (open: boolean) => void;
  setFilters: (filters: ExportFilter) => void;
  setMobileFiltersOpen: (open: boolean) => void;
  handleSelectPreset: (preset?: IExportPreset) => void;
  preset?: IExportPreset;
  savePreset: (preset: IExportPreset) => Promise<void>;
}

export const ExportHeader: React.FC<IExportHeaderProps> = ({
  setDrawerOpen,
  setMobileFiltersOpen,
  filters,
  setFilters,
  setDateRange,
  deviceTypes,
  sensorTypes,
  handleSelectPreset,
  preset,
  savePreset,
}) => {
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { t } = useTranslation(["common", "export"]);

  useEffect(() => setDateRange(startDate, endDate), [startDate, endDate]);

  const allOption: ISelectOption = { value: "", label: t("common:all") };

  const selectsOptions = getExportFilters(deviceTypes, sensorTypes, t);

  const processFilter = (key: keyof IExportFilter, value: boolean | string | number) => {
    const newFilters = filters.slice();
    const index = filters.findIndex((item) => item.key === key);

    if (value === undefined && index > -1) {
      newFilters.splice(index, 1);
    } else if (index > -1) {
      newFilters[index].value = value;
    } else {
      newFilters.push({ key, value });
    }

    setFilters(newFilters);
  };

  return (
    <div className="header-row export-header">
      <h3 className="u-mobile-only">{t("common:export")}</h3>
      <button onClick={() => setDrawerOpen(true)} className="btn btn-icon u-tablet-only">
        <DrawerIcon />
      </button>
      <RangePicker
        style={{ display: "flex" }}
        className="u-mobile-hide"
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        hasTimeSelector={true}
      />
      {selectsOptions.map((selectOption) => (
        <SelectInput
          key={selectOption.key}
          displayEmpty={true}
          className="input-holder filter-holder u-mobile-hide"
          label={t(`export:${selectOption.key}`)}
          onChange={(value) => processFilter(selectOption.key, value)}
          options={[allOption, ...selectOption.options]}
          value={filters.find((filter) => filter.key === selectOption.key)?.value}
        />
      ))}
      <button className="btn btn-icon u-mobile-only" onClick={() => setMobileFiltersOpen(true)}>
        <FiltersIcon />
      </button>
      <button
        className="btn btn-plain u-text-teal u-mobile-hide"
        onClick={() =>
          showAppModal(<PresetModal preset={preset} savePreset={savePreset} handleSelectPreset={handleSelectPreset} filters={filters} />)
        }>
        <i className="fa fa-floppy-o" /> {t("export:save_or_load_preset")}
      </button>
    </div>
  );
};
