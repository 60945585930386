import { StyledTooltip } from "./StyledTooltip";
import React from "react";

export const InputError: React.FC<{ error?: string; displayError?: boolean; touched: boolean }> = ({
  error,
  displayError = false,
  touched,
}) => {
  if (!touched || !Boolean(error) || !displayError) {
    return <></>;
  }

  return (
    <>
      <span className="u-mobile-only u-color-alert u-text-small">{error ?? ""}</span>
      <StyledTooltip title={<span className="u-color-alert">{error ?? ""}</span>}>
        <i style={{ zIndex: 10 }} className="fa fa-times u-color-alert u-mobile-hide" />
      </StyledTooltip>
    </>
  );
};
