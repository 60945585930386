import { FormFieldSelect, FormFieldText, IFormStepProps, StepperActions, StyledTooltip, TotalPrice } from "../../Components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import "./PlanStep.scss";
import { IOptionPrice, IPaymentOptions, IPlanProps } from "../../Managers/Types";
import { GetPaymentOptions } from "../../Managers/PaymentOptionsService";
import { useTranslation } from "react-i18next";
import { ValidationMessages } from "../../Enums";

interface IPlanStepProps extends IFormStepProps {
  planDetails?: IPlanProps;
  planOptions?: IPaymentOptions;
  calculateTotal: (values: IPlanProps) => Promise<number | null>;
  userCountryCode: string;
  currencyCode: string;
  currencySign: string;
}

const PricingOption: React.FC<{
  children?: React.ReactNode;
  label: string;
  tooltipText?: string;
  selected: boolean;
}> = ({ children, label, tooltipText }) => {
  return (
    <div className="pricing-plan-option">
      <div className="pricing-plan-label">
        {label}{" "}
        {tooltipText && (
          <StyledTooltip title={tooltipText}>
            <i className="fa fa-info-circle" />
          </StyledTooltip>
        )}
      </div>
      <div className="pricing-plan-content">{children}</div>
    </div>
  );
};

export const PlanStep: React.FC<IPlanStepProps> = ({
  planDetails,
  onClickBack,
  onSubmit,
  calculateTotal,
  userCountryCode,
  currencyCode,
  currencySign,
}) => {
  const [options, setOptions] = useState<IPaymentOptions>();
  const [initialValues, setInitialValues] = useState<IPlanProps>();
  const { t } = useTranslation(["register", "common"]);

  useEffect(() => {
    GetPaymentOptions(userCountryCode, currencyCode).then((res) => {
      setOptions(res.data);
      let smsNotificationValue: number | null = null;
      if (res.data?.smsNotifications && res.data.smsNotifications.options.length > 0) {
        const foundOption = res.data.smsNotifications.options.find((option) => option.id === planDetails?.smsNotifications);
        smsNotificationValue = foundOption ? foundOption.id : res.data.smsNotifications.options[0]?.id;
      }

      setInitialValues({
        basePriceOptionId: planDetails?.basePriceOptionId ?? res.data?.basePrice.options[0]?.id ?? null,
        numberOfDevicesOptionId: planDetails?.numberOfDevicesOptionId ?? res.data?.numberOfDevices.options[0]?.id ?? null,
        numberOfDevices: planDetails?.numberOfDevices ?? 1,
        smsNotifications: smsNotificationValue,
        transmissionRate: planDetails?.transmissionRate ?? res.data?.transmissionRate?.options[0]?.id ?? null,
        numberOfUsers: planDetails?.numberOfUsers ?? res.data?.numberOfUsers?.options[0]?.id ?? null,
      });
    });
  }, [planDetails, userCountryCode]);

  const validationSchema = yup.object({
    basePriceOptionId: yup.number(),
    numberOfDevicesOptionId: yup.number(),
    numberOfDevices: yup
      .number()
      .required(t(ValidationMessages.REQUIRED))
      .integer(t(ValidationMessages.INTEGER))
      .positive(t(ValidationMessages.POSITIVE))
      .min(1, t("common:formik_errors.min", { value: 1 }))
      .max(200, t("common:formik_errors.not_greater_than", { value: 200 })),
    smsNotifications: yup.number().nullable(),
    transmissionRate: yup.number(),
    numberOfUsers: yup.number(),
  });

  const getOptions = (opts: Array<IOptionPrice>) =>
    opts.map((opt) => ({
      value: opt.id,
      key: opt.name,
      label: opt.name,
    }));

  return !!options && !!initialValues ? (
    <Formik validateOnMount={true} validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
      {({ submitForm, values }) => {
        return (
          <>
            <div className="type-large-regular register-form-label">{t("register:steps.plan.title")}</div>
            <div className="pricing-options-wrapper">
              <PricingOption
                selected={true}
                label={t("register:steps.plan.device_number")}
                tooltipText={t("register:steps.plan.device_number_desc")}>
                <FormFieldText type="number" name="numberOfDevices" required={true} displayError={true} />
              </PricingOption>
              <PricingOption
                selected={true}
                label={t("register:steps.plan.users_number")}
                tooltipText={t("register:steps.plan.users_number_desc")}>
                <FormFieldSelect
                  inputClassName="input input-line"
                  options={getOptions(options.numberOfUsers.options)}
                  name="numberOfUsers"
                />
              </PricingOption>
              <PricingOption
                selected={true}
                label={t("register:steps.plan.transmission_rate")}
                tooltipText={t("register:steps.plan.transmission_rate_desc")}>
                <FormFieldSelect
                  inputClassName="input input-line"
                  options={getOptions(options.transmissionRate.options)}
                  name="transmissionRate"
                />
              </PricingOption>
              <PricingOption
                selected={true}
                label={t("register:steps.plan.email_notifications")}
                tooltipText={t("register:steps.plan.email_notifications_desc")}>
                {t("register:steps.plan.unlimited_transmission_rate")}
              </PricingOption>
              {options?.smsNotifications?.options !== undefined ? (
                <PricingOption
                  selected={true}
                  label={t("register:steps.plan.sms_notifications")}
                  tooltipText={t("register:steps.plan.sms_notifications_desc")}>
                  <FormFieldSelect
                    inputClassName="input input-line"
                    options={getOptions(options.smsNotifications.options)}
                    name="smsNotifications"
                  />
                </PricingOption>
              ) : (
                <></>
              )}
            </div>
            <TotalPrice calculateTotal={calculateTotal} values={values} forceCurrencySign={currencySign} />
            <StepperActions onClickBack={() => onClickBack(values)} onClickNext={submitForm} />
          </>
        );
      }}
    </Formik>
  ) : null;
};
