import { observer } from "mobx-react-lite";
import { DatepickerDropdown, SelectInput, TextInput } from "../../../Components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAlertHistoryFilters, IDevice, IGateway, ILocation } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { Autocomplete, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { getNameSlug } from "../../../Managers";
import { useSensorTypes } from "../../../Managers/AlertService";
import { isUserPermissionAllowed, PermissionEnum } from "../../../Enums";
import { getLocations } from "../../../Managers/API";
import { AppState } from "../../../AppState";
import { Collapse } from "react-collapse";
import classNames from "classnames";

export const AlertHistoryMobileFiltersModal: React.FC<{
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setFilters: (filters: IAlertHistoryFilters) => void;
  filters: IAlertHistoryFilters;
  searchString: string;
  setSearchString: (search: string) => void;
  devices: IDevice[];
  setSelectedDevice: (device: IDevice | null) => void;
  changeLocation: (location: ILocation) => void;
  location: ILocation;
  selectedGateways: IGateway[];
  selectGateways: (gateway: IGateway[]) => void;
  close: () => void;
}> = observer(
  ({
    setSelectedDevice,
    devices,
    searchString,
    setSearchString,
    filters,
    setFilters,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    changeLocation,
    location,
    selectedGateways,
    selectGateways,
    close,
  }) => {
    const { t } = useTranslation(["alert_history", "common"]);

    const defaultLocation = { name: t("common.all_locations") } as ILocation;

    const [selectedFilters, setSelectedFilters] = useState(filters);
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [sortOpen, setSortOpen] = useState(false);
    const [sensorTypeOpen, setSensorTypeOpen] = useState(false);

    const sensorTypes = useSensorTypes();

    useEffect(() => {
      if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
        getLocations().then((res) => setLocations(res));
      } else {
        setLocations(AppState.user?.Locations ?? []);
      }
    }, []);

    const changeSelectedLocation = (locationId: number) => {
      if (locationId > 0) {
        changeLocation(locations.find((l) => l._id === locationId)!);
      } else {
        changeLocation(defaultLocation);
      }
    };

    const selectGateway = (selected: Array<string | number>) => {
      // deselect all
      if (selected.includes("all") && selectedGateways.length === location.Gateways?.length) {
        return selectGateways(selectedGateways);
      }

      // select only deselected
      if (selected.includes("all") && location.Gateways) {
        return selectGateways(location.Gateways?.filter((g) => !selectedGateways.some((selectedGateway) => selectedGateway._id === g._id)));
      }

      const gatewaysToChangeSelectionState: IGateway[] = [];

      location.Gateways?.slice().forEach((g) => {
        const gatewayWasSelected = selectedGateways.some((selectedGateway) => selectedGateway._id === g._id);
        const gatewayIsSelected = selected.includes(g._id);
        if ((gatewayWasSelected && !gatewayIsSelected) || (!gatewayWasSelected && gatewayIsSelected)) {
          gatewaysToChangeSelectionState.push(g);
        }
        selectGateways(gatewaysToChangeSelectionState);
      });
    };

    return (
      <Modal
        className="modal-lg alert-history-filters-modal"
        title={
          <>
            <span>{t("common:filters")}</span>
            <button style={{ padding: 0, margin: 0 }} className="btn btn-icon" onClick={() => close()}>
              <i className="fa fa-close u-text-teal pull-right" />
            </button>
          </>
        }
        buttons={
          <button
            onClick={() => {
              setFilters(selectedFilters);
              close();
            }}
            className="btn btn-primary">
            {t("common:apply")}
          </button>
        }>
        <SelectInput
          className="input-holder u-full-width"
          onChange={changeSelectedLocation}
          value={location._id ?? -1}
          menuItemClass="dark"
          inputClassName="dark"
          label={t("common:location")}
          options={[
            {
              value: -1,
              label: t("common:all_locations"),
            },
            ...locations.map((l) => ({ label: l.name, value: l._id })),
          ]}
        />
        {location._id ? (
          <SelectInput
            className="input-holder u-full-width"
            disabled={!location.Gateways?.length}
            multiple
            value={selectedGateways.map((g) => g._id)}
            onChange={(value) => selectGateway(value)}
            menuItemClass="dark"
            inputClassName="dark"
            label={t("common:gateway")}
            renderValue={() => {
              if (location.Gateways?.length && location.Gateways.length === selectedGateways.length) {
                return t("common:all");
              }

              return selectedGateways.map((g) => g.name).join(", ");
            }}
            options={[
              {
                value: "all",
                label: t("common:all"),
                selected: !!location.Gateways?.length && location.Gateways.length === selectedGateways.length,
              },
              ...(location.Gateways?.map((gateway) => ({
                label: gateway?.name + " " + gateway._id,
                value: gateway?._id,
                selected: selectedGateways.some((g) => g._id === gateway._id),
              })) ?? []),
            ]}
          />
        ) : null}
        <div>
          <label className="input-label">{t("common:device")}</label>
          <Autocomplete
            onChange={(_e, device) => setSelectedDevice(device)}
            className="autocomplete-account u-full-width dark"
            options={devices}
            getOptionLabel={(o: IDevice) => o.name}
            getOptionKey={(o: IDevice) => o._id}
            renderInput={(params) => (
              <TextInput
                label={t("alert_history:choose_device")}
                displayLabelInside
                className="account-search-input u-full-width dark"
                inputClass="dark"
                onChange={(e) => setSearchString(e.target.value)}
                value={searchString}
                name={params.id}
                {...params}
              />
            )}
          />
        </div>
        <div style={{ display: "flex", gap: 12 }}>
          <div style={{ flex: 1 }}>
            <label className="input-label">{t("common:from")}</label>

            <DatepickerDropdown selectedDate={startDate} maxDate={endDate} onSelectDate={(date) => setStartDate(date)} />
          </div>

          <div style={{ flex: 1 }}>
            <label className="input-label">{t("common:to")}</label>
            <DatepickerDropdown selectedDate={endDate} minDate={startDate} onSelectDate={(date) => setEndDate(date)} />
          </div>
        </div>
        <SelectInput
          required={false}
          displayError={false}
          menuItemClass="dark"
          inputClassName="dark"
          className="input-holder u-full-width"
          label={t("alert_history:type")}
          onChange={(type) => setSelectedFilters({ ...selectedFilters, type })}
          options={[
            { value: "all", key: "all" },
            { value: "range", key: "range" },
            { value: "max", key: "max" },
            { value: "min", key: "min" },
            { value: "boolean", key: "boolean" },
            { value: "transmitting", key: "not_transmitting" },
          ].map((o) => ({ ...o, label: t("alert_history:sensor_type", { context: o.key }) }))}
          value={selectedFilters.type}
        />

        <label className="input-label" onClick={() => setSensorTypeOpen(!sensorTypeOpen)}>
          {t("alert_history:sensor_type")}
          <i className={classNames("fa fa-chevron-down fa-rotate u-text-small pull-right u-text-teal", { rotated: sensorTypeOpen })} />
        </label>
        <Collapse isOpened={sensorTypeOpen}>
          <RadioGroup
            value={selectedFilters.sensorTypeId}
            onChange={(_e, sensorTypeId) =>
              setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                sensorTypeId: Number(sensorTypeId),
              }))
            }
            name="radio-buttons-group">
            <FormControlLabel value={-1} control={<Radio />} label={t("alert_history:all")} />
            {(sensorTypes.data || []).map((type) => (
              <FormControlLabel
                key={type._id.toString()}
                value={type._id}
                control={<Radio />}
                label={t(`sensor_types:${getNameSlug(type.name)}`)}
              />
            ))}
          </RadioGroup>
        </Collapse>

        <label className="input-label" onClick={() => setSortOpen(!sortOpen)}>
          {t("common:sort_by")}
          <i className={classNames("fa fa-chevron-down fa-rotate u-text-small pull-right u-text-teal", { rotated: sortOpen })} />
        </label>
        <Collapse isOpened={sortOpen}>
          <RadioGroup
            value={selectedFilters.sortBy}
            onChange={(_e, sortBy) =>
              setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                sortBy,
              }))
            }
            name="radio-buttons-group">
            <FormControlLabel value="added" control={<Radio />} label={t("alert_history:last_added")} />
            <FormControlLabel value="updated" control={<Radio />} label={t("alert_history:last_updated")} />
            <FormControlLabel value="desc" control={<Radio />} label={t("alert_history:alert_value", { context: "desc" })} />
            <FormControlLabel value="asc" control={<Radio />} label={t("alert_history:alert_value", { context: "asc" })} />
          </RadioGroup>
        </Collapse>
      </Modal>
    );
  },
);
