import React from "react";
import { FormFieldSelect, IFormFieldSelectProps, SelectInput } from "./SelectInput";
import { IStandardInput } from "./Types";
import { ICurrency } from "../Managers/CurrencyService";

export interface ICurrencySelectProps extends IStandardInput {
  currencyList?: Array<ICurrency>;
  formikControl?: boolean;
  initialValue?: ICurrency;
  selectedCurrencyLabel?: keyof ICurrency;
  value?: ICurrency;
}

export const CurrencySelect: React.FC<ICurrencySelectProps> = ({
  className = "",
  currencyList = [],
  selectedCurrencyLabel,
  formikControl = true,
  initialValue,
  required = false,
  label = "",
  ...rest
}) => {
  const getOptions = (list: Array<ICurrency>) => {
    return list.map((currency) => ({
      value: currency,
      key: currency.iso_code,
      label: (
        <>
          {currency.iso_code} ({currency.sign}) - {currency.name}
        </>
      ),
    }));
  };

  const options = getOptions(currencyList);

  const props: IFormFieldSelectProps = {
    options,
    className: className,
    label: label,
    MenuProps: {
      style: { maxHeight: "30rem", width: "100%" },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    renderValue: (currency: any) => (
      <>
        {currency?.iso_code} ({currency?.sign}) {selectedCurrencyLabel ? currency?.[selectedCurrencyLabel] : ""}
      </>
    ),
    ...rest,
  };

  return formikControl ? <FormFieldSelect {...props} /> : <SelectInput {...props} />;
};
