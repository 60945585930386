import { ICountry } from "../Managers";
import React from "react";
import { FormFieldSelect, IFormFieldSelectProps, SelectInput } from "./SelectInput";
import { useTranslation } from "react-i18next";
import { Flag } from "./Flag";
import { useCountries } from "../Managers/API";

export interface ICountrySelectProps {
  className?: string;
  formikControl?: boolean;
  initialValue?: string;
  hidePhones?: boolean;
  name?: string;
  multiple?: boolean;
  onChange?: (value: ICountry) => void;
  selectedCountryLabel?: keyof ICountry;
  value?: ICountry;
  showOnlyFlagAndDialCode?: boolean;
  showAll?: boolean;
}

export const CountrySelect: React.FC<ICountrySelectProps> = ({
  className = "",
  selectedCountryLabel,
  formikControl = true,
  initialValue,
  hidePhones,
  multiple,
  showOnlyFlagAndDialCode,
  value,
  showAll,
  ...rest
}) => {
  const { t } = useTranslation("countries");
  const countries = useCountries();
  const countryList = countries.data ?? [];
  const getOptions = (list: Array<ICountry>) => {
    return list.map((country) => ({
      value: country,
      key: country.code,
      label: showOnlyFlagAndDialCode ? (
        <>
          <Flag countryCode={country.code} /> {country.dial_code}
        </>
      ) : (
        <>
          <Flag countryCode={country.code} /> {t(`countries:${country.code}`)} {!hidePhones && `(${country.dial_code})`}
        </>
      ),
    }));
  };

  const options = getOptions(countryList);

  const renderValue = (country?: any) => (
    <>
      {country ? <Flag countryCode={country.code} /> : null} {getText(country)}
    </>
  );

  const getText = (country?: ICountry) => {
    if (!country) {
      return showAll ? t("common:all") : "";
    }

    if (selectedCountryLabel === "name") {
      return t(`countries:${country.code}`);
    }
    return selectedCountryLabel ? country[selectedCountryLabel] : "";
  };

  const defaultValue = countryList.find((country) => country.code === initialValue);
  const props: IFormFieldSelectProps = {
    defaultValue: multiple ? [defaultValue] : defaultValue,
    options: showAll ? [{ value: undefined, label: t("common:all") }, ...options] : options,
    className,
    MenuProps: {
      style: { maxHeight: "30rem", width: "100%" },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    },
    multiple: multiple,
    renderValue: multiple ? undefined : renderValue,
    ...rest,
  };

  return formikControl ? <FormFieldSelect {...props} /> : <SelectInput value={value} {...props} />;
};
