import React, { useEffect, useState } from "react";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { getExportFilters } from "../../Managers";
import { IDevice, IDeviceType, IExportPreset, IGateway, ILocation, ISensorType } from "../../Managers/Types";
import { CheckboxInput, RangePicker, SelectInput, useScreenMode } from "../../Components";
import { isUserPermissionAllowed, PermissionEnum, WindowSize } from "../../Enums";
import { getLocations } from "../../Managers/API";
import { AppState, showAppModal } from "../../AppState";
import moment from "moment/moment";
import { ExportFilter, IExportFilter } from "./Export";
import { PresetModal } from "./Modals/PresetModal";

interface IMobileExportFilterProps {
  deviceTypes: IDeviceType[];
  sensorTypes: ISensorType[];
  close: () => void;
  location: ILocation;
  changeSelectedLocation: (loc: ILocation) => void;
  selectedGateways: IGateway[];
  selectGateways: (gateways: IGateway[]) => void;
  selectDevices: (devices: IDevice[]) => void;
  selectedDevices: IDevice[];
  devices: IDevice[];
  setDateRange: (start: Date, end: Date) => void;
  dateRange?: Date[];
  filters: ExportFilter;
  setFilters: (filters: ExportFilter) => void;
  handleSelectPreset: (preset?: IExportPreset) => void;
  savePreset: (preset: IExportPreset) => Promise<void>;
  preset?: IExportPreset;
}

export const MobileExportFilters: React.FC<IMobileExportFilterProps> = ({
  close,
  location,
  changeSelectedLocation,
  deviceTypes,
  sensorTypes,
  selectedGateways,
  selectGateways,
  selectDevices,
  selectedDevices,
  devices,
  setDateRange,
  dateRange,
  filters,
  setFilters,
  handleSelectPreset,
  savePreset,
  preset,
}) => {
  const { t } = useTranslation(["common", "export"]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [startDate, setStartDate] = useState<Date>(dateRange?.[0] ?? moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(dateRange?.[1] ?? new Date());
  const [currentFilters, setCurrentFilters] = useState<ExportFilter>(filters);

  const mode = useScreenMode();

  useEffect(() => {
    if (mode !== WindowSize.MOBILE) {
      close();
    }
  }, [mode]);

  useEffect(() => {
    if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
      getLocations().then((res) => setLocations(res));
    } else {
      setLocations(AppState.user?.Locations ?? []);
    }
  }, []);

  const selectsOptions = getExportFilters(deviceTypes, sensorTypes, t);

  const getGatewayText = () => {
    if (selectedGateways.length === location.Gateways?.length) {
      return t("common:all_gateways", { count: location.Gateways.length });
    }
    return selectedGateways.map((g) => g.name).join(", ");
  };

  const getDevicesText = () => {
    if (selectedDevices.length === devices.length) {
      return t("common:all_devices", { count: devices.length });
    }
    return selectedDevices.map((d) => d.name).join(", ");
  };

  const setGateways = (gateway: IGateway) => {
    const modifiedGateways = selectedGateways.slice();
    const gatewayIndex = selectedGateways.findIndex((g) => g._id === gateway._id);

    if (gatewayIndex > -1) {
      modifiedGateways.splice(gatewayIndex, 1);
    } else {
      modifiedGateways.push(gateway);
    }

    selectGateways(modifiedGateways);
  };

  const handleDeviceSelect = (device: IDevice) => {
    const modifiedSelectedDevices = selectedDevices.slice();
    const deviceIndex = modifiedSelectedDevices.findIndex((d) => d._id === device._id);

    if (deviceIndex > -1) {
      modifiedSelectedDevices.splice(deviceIndex, 1);
    } else {
      modifiedSelectedDevices.push(device);
    }

    selectDevices(modifiedSelectedDevices);
  };

  const processFilter = (key: keyof IExportFilter, value: boolean | string | number) => {
    const newFilters = currentFilters.slice();
    const index = currentFilters.findIndex((item) => item.key === key && item.value === value);

    if (index > -1) {
      newFilters.splice(index, 1);
    } else {
      newFilters.push({ key, value });
    }

    setCurrentFilters(newFilters);
  };

  return (
    <Modal
      className="modal-lg alert-history-filters-modal"
      title={
        <>
          <span>{t("common:filters")}</span>
          <button style={{ padding: 0, margin: 0 }} className="btn btn-icon" onClick={() => close()}>
            <i className="fa fa-close u-text-teal pull-right" />
          </button>
        </>
      }
      buttons={
        <>
          <button
            className="btn btn-plain u-text-teal"
            onClick={() =>
              showAppModal(
                <PresetModal
                  preset={preset}
                  filters={currentFilters}
                  handleSelectPreset={(preset) => {
                    handleSelectPreset(preset);
                    setDateRange(startDate, endDate);
                    close();
                  }}
                  savePreset={(preset) =>
                    savePreset(preset).then(() => {
                      setDateRange(startDate, endDate);
                      close();
                    })
                  }
                />,
              )
            }>
            {t("export:save_or_load_preset")}
          </button>
          <button
            onClick={() => {
              setDateRange(startDate, endDate);
              setFilters(currentFilters);
              close();
            }}
            className="btn btn-primary">
            {t("common:apply")}
          </button>
        </>
      }>
      <SelectInput
        className="input-holder u-full-width"
        onChange={changeSelectedLocation}
        value={location}
        menuItemClass="dark"
        inputClassName="dark"
        label={t("common:location")}
        displayEmpty={true}
        renderValue={(item: any) => (item._id ? item.name : t("common:all_locations"))}
        options={[
          {
            value: { label: t("common:all_locations") },
            label: t("common:all_locations"),
          },
          ...locations.map((l) => ({ label: l.name, value: l })),
        ]}
      />
      {location._id ? (
        <div>
          <label className="input-label">{t("common:gateways")}</label>
          <SelectInput
            renderValue={() => getGatewayText()}
            inputClassName="dark"
            displayEmpty={true}
            multiple
            options={[
              {
                label: t("common:all_gateways", { count: location.Gateways?.length || 0 }),
                onClick: () => selectGateways(selectedGateways.length === location.Gateways?.length ? [] : location.Gateways ?? []),
                selected: selectedGateways.length === location.Gateways?.length,
                value: undefined,
              },
              ...(location.Gateways ?? []).map((gateway) => ({
                label: gateway.name,
                onClick: () => setGateways(gateway),
                selected: selectedGateways.some((g) => g._id === gateway._id),
                value: gateway,
              })),
            ]}
            value={selectedGateways}
          />
        </div>
      ) : null}
      <div>
        <label className="input-label">{t("common:devices")}</label>
        <SelectInput
          renderValue={() => getDevicesText()}
          inputClassName="dark"
          displayEmpty={true}
          multiple
          options={[
            {
              label: t("common:all_devices", { count: devices.length || 0 }),
              onClick: () => selectDevices(selectedDevices.length === devices.length ? [] : devices ?? []),
              selected: selectedDevices.length === devices.length,
              value: undefined,
            },
            ...devices.map((device) => ({
              label: device.name,
              onClick: () => handleDeviceSelect(device),
              selected: selectedDevices.some((d) => d._id === device._id),
              value: device,
            })),
          ]}
          value={selectedDevices}
        />
      </div>
      <RangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} className="vertical" />
      {selectsOptions.map((selectOption) =>
        selectOption.checkboxMobile ? (
          <div>
            <label className="input-label">{t(`export:${selectOption.key}`)}</label>

            {selectOption.options.map((option) => (
              <CheckboxInput
                className="checkbox-teal"
                label={option.label}
                checked={currentFilters.some((filter) => filter.key === selectOption.key && filter.value === option.value)}
                name={option.label}
                onChange={() => processFilter(selectOption.key, option.value)}
              />
            ))}
          </div>
        ) : (
          <SelectInput
            key={selectOption.key}
            displayEmpty={true}
            className="input-holder filter-holder"
            label={t(`export:${selectOption.key}`)}
            onChange={(value) => processFilter(selectOption.key, value)}
            options={[{ value: "", label: t("common:all") }, ...selectOption.options]}
            value={currentFilters.find((filter) => filter.key === selectOption.key)?.value}
          />
        ),
      )}
    </Modal>
  );
};
