import { Table, TableBody, TableCell, TableHead, TableRow } from "../../../Components/Table";
import { CheckboxInput, TextInput } from "../../../Components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ISensorWithExportOptionAndSetPoints } from "./ExportModal";
import { mySearch } from "../../../Managers";

export const ModalExportTable: React.FC<{
  sensorsWithExportOptions: ISensorWithExportOptionAndSetPoints[];
  setSensorsWithExportOptions: (sensors: ISensorWithExportOptionAndSetPoints[]) => void;
}> = ({ setSensorsWithExportOptions, sensorsWithExportOptions }) => {
  const { t } = useTranslation(["common", "export"]);
  const [deviceSearch, setDeviceSearch] = useState("");

  const handleSensorCheckboxUpdate = (
    prop: keyof ISensorWithExportOptionAndSetPoints,
    sensor?: ISensorWithExportOptionAndSetPoints,
    value?: boolean,
  ) => {
    if (!sensor) {
      return;
    }

    if ((prop === "include_alert_notes" || prop === "include_alert_protocol") && !sensor.include_history) {
      sensor.include_history = true;
    } else if (prop === "include_history" && !value) {
      sensor.include_alert_notes = false;
      sensor.include_alert_protocol = false;
    }

    (sensor[prop] as any) = value ?? !sensor[prop];
  };

  const handleAllCheckbox = (prop: keyof ISensorWithExportOptionAndSetPoints) => {
    const value = !sensorsWithExportOptions.every((s) => s[prop]);

    const modifiedSensors = sensorsWithExportOptions.slice();
    modifiedSensors.forEach((s) => handleSensorCheckboxUpdate(prop, s, value));

    setSensorsWithExportOptions(modifiedSensors);
  };

  const handleCheckbox = (prop: keyof ISensorWithExportOptionAndSetPoints, id: number) => {
    const modifiedSensors = sensorsWithExportOptions.slice();
    handleSensorCheckboxUpdate(
      prop,
      modifiedSensors.find((s) => s._id === id),
    );

    setSensorsWithExportOptions(modifiedSensors);
  };

  return (
    <>
      {sensorsWithExportOptions?.length > 10 ? (
        <div style={{ display: "flex" }}>
          <TextInput
            placeholder={t("common:find_device")}
            inputClass="dark"
            value={deviceSearch}
            name="search"
            onChange={(e) => setDeviceSearch(e.target.value)}
            icon={<i className="fa fa-search" />}
          />
        </div>
      ) : null}
      <div className="export-modal-table-wrapper">
        <Table className="export-modal-table">
          <TableHead className="table-header-transparent">
            <TableRow>
              <TableCell>{t("export:device_name")}</TableCell>
              <TableCell>{t("export:sensor_type")}</TableCell>
              <TableCell width={100}>{t("export:include")}:</TableCell>
              <TableCell>
                <div className="export-header-select">
                  <CheckboxInput
                    onChange={() => handleAllCheckbox("include_history")}
                    checked={sensorsWithExportOptions.every((s) => s.include_history)}
                    name="all-history"
                  />
                  {t("export:alert_history")}
                </div>
              </TableCell>
              <TableCell>
                <div className="export-header-select">
                  <CheckboxInput
                    onChange={() => handleAllCheckbox("include_alert_notes")}
                    checked={sensorsWithExportOptions.every((s) => s.include_alert_notes)}
                    name="all-notes"
                  />
                  {t("export:alert_notes")}
                </div>
              </TableCell>
              <TableCell>
                <div className="export-header-select">
                  <CheckboxInput
                    onChange={() => handleAllCheckbox("include_alert_protocol")}
                    checked={sensorsWithExportOptions.every((s) => s.include_alert_protocol)}
                    name="all-protocol"
                  />
                  {t("export:alert_protocol")}
                </div>
              </TableCell>
              <TableCell>
                <div className="export-header-select">
                  <CheckboxInput
                    onChange={() => handleAllCheckbox("include_graph")}
                    checked={sensorsWithExportOptions.every((s) => s.include_graph)}
                    name="all-graph"
                  />
                  {t("export:graph")}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mySearch(sensorsWithExportOptions, deviceSearch, "device_name").map((s) => (
              <TableRow key={s._id}>
                <TableCell>{s.device.name}</TableCell>
                <TableCell>{s.sensor_type}</TableCell>
                <TableCell />
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleCheckbox("include_history", s._id)}
                    checked={s.include_history}
                    name={`${s._id}-history`}
                  />
                </TableCell>
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleCheckbox("include_alert_notes", s._id)}
                    checked={s.include_alert_notes}
                    name={`${s._id}-notes`}
                  />
                </TableCell>
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleCheckbox("include_alert_protocol", s._id)}
                    checked={s.include_alert_protocol}
                    name={`${s._id}-protocol`}
                  />
                </TableCell>
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleCheckbox("include_graph", s._id)}
                    checked={s.include_graph}
                    name={`${s._id}-graph`}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
