import React from "react";

export const TrashIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className ?? ""} width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.90275 0.908868L4.90277 0.908874L4.90474 0.904638C4.95696 0.792652 5.04751 0.75 5.11875 0.75H8.88125C8.95249 0.75 9.04304 0.792651 9.09526 0.904638L9.09525 0.904644L9.09725 0.908868L9.32225 1.38367L9.52546 1.8125H10H13C13.0967 1.8125 13.25 1.90803 13.25 2.125C13.25 2.34197 13.0967 2.4375 13 2.4375H1C0.903275 2.4375 0.75 2.34197 0.75 2.125C0.75 1.90803 0.903275 1.8125 1 1.8125H4H4.47454L4.67775 1.38367L4.90275 0.908868ZM2.41118 15.4613L1.79544 5H12.2046L11.5888 15.4613C11.56 15.9449 11.1957 16.25 10.8406 16.25H3.15937C2.8044 16.25 2.44009 15.945 2.41119 15.4615C2.41118 15.4615 2.41118 15.4614 2.41118 15.4613Z"
        strokeWidth={1.5}
      />
    </svg>
  );
};
