import axios from "axios";
import { QueryClient, useQuery } from "react-query";
import { IDeviceTypeGroup, IFirmware, ILocation, ILocationDetail, IUser } from "./Types";
import { getCurrenciesForDropdown } from "./PaymentOptionsService";
import { getCountries } from "./CountryService";

export const Endpoint = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20000,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
      retry: 0,
      structuralSharing: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export const setAuthToken = (accessToken: string | null) => {
  if (accessToken) {
    Endpoint.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete Endpoint.defaults.headers.common.Authorization;
  }
};

export interface IPaginatedResponse<T> {
  rows: Array<T>;
  count: number;
}

export interface ILoginRequest {
  identifier: string;
  password: string;
}

export interface ILoginResponse {
  user: IUser;
  bapi_refresh_token: string;
}

export interface IGatewayFirmwareUpdateRequest {
  update_all: boolean;
  gateways: Object[];
  is_updating: boolean;
}

export interface IDeviceFirmwareUpdateRequest {
  devices: Object[];
  is_updating: boolean;
}

export const login = (params: ILoginRequest) => Endpoint.post<ILoginResponse>("/auth/login", params);

export interface IResetPasswordResponse {
  user: IUser;
  bapi_refresh_token: string;
}

export interface IResetPasswordRequest {
  email: string;
}

export const resetPassword = (params: IResetPasswordRequest) =>
  Endpoint.post<IResetPasswordResponse>("/auth/forgot", params).then((r) => r.data);

export const getLocationDetail = (locationId: number, page: number = 0, limit: number = 10) =>
  Endpoint.get<ILocationDetail | null>(
    `/api/locations/${locationId}?devices=true&device_groups=true&sensors=true&alerts=true&firmwares=true&page=${page}&limit=${limit}`,
  ).then((r) => r.data);

export const useLocationDetail = (locationId: number) =>
  useQuery(["LOCATIONS", locationId], () => (locationId ? getLocationDetail(locationId) : null), { refetchInterval: 30000 });

export const getDeviceTypeGroups = () => Endpoint.get<IDeviceTypeGroup[]>("/api/device_type_groups").then((r) => r.data);

export const useDeviceTypeGroups = () =>
  useQuery(["DEVICE_TYPE_GROUPS"], getDeviceTypeGroups, { refetchInterval: 5 * 60000, placeholderData: [] });

export const getLocations = () => Endpoint.get<ILocation[]>("/api/locations").then((r) => r.data);

export const useLocations = () =>
  useQuery("LOCATIONS", getLocations, {
    refetchInterval: 5 * 60000,
    placeholderData: [],
  });

export const useCurrencies = (onlyActive = false) =>
  useQuery(["CURRENCIES", onlyActive], () => getCurrenciesForDropdown(onlyActive), {
    refetchInterval: 5 * 60000,
    placeholderData: [],
  });

export const useCountries = (onlyMain = true) =>
  useQuery(["COUNTRIES", onlyMain], () => getCountries(onlyMain), {
    refetchInterval: 5 * 60000,
    placeholderData: [],
  });

export const invalidateQuery = (queryKey: any) => {
  queryClient.invalidateQueries(queryKey).catch(() => console.log("Error invalidating query", queryKey));
};

export const updateQueryEntryById = (queryKey: (string | number)[], id: number, newData: any) => {
  const data = [...((queryClient.getQueryData(queryKey) as any[]) ?? [])];
  console.log("old data", data);
  const oldRecord = data.find((entry) => entry._id === id);
  if (oldRecord) {
    console.log("old record", oldRecord);
    Object.assign(oldRecord, newData);
    console.log("updated record", oldRecord);
    queryClient.setQueryData(queryKey, data);
  }
};

export const postGatewayFirmwareUpdate = (accountId: number | undefined, request: IGatewayFirmwareUpdateRequest) =>
  Endpoint.post(`/api/accounts/${accountId}/firmware/gateway`, request).then((r) => r.data);

export const postDeviceFirmwareUpdate = (request: IDeviceFirmwareUpdateRequest) =>
  Endpoint.post("/api/firmware/device/update", request).then((r) => r.data);

export const getLatestFirmwares = () => Endpoint.get<IFirmware[]>(`/api/firmware/latest`).then((r) => r.data);
export const getGatewaysByLocationId = (locationId: number) => Endpoint.get(`/api/locations/${locationId}/gateways`).then((r) => r.data);
