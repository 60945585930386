import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import Slider from "@mui/material/Slider";
import "./InputRange.scss";
import { useTranslation } from "react-i18next";

export interface IInputRangeSliderProps {
  min: number;
  max: number;
  valueMin?: number | null | undefined;
  valueMax?: number | null | undefined;
  type?: string;
  readonly?: boolean;
  onMinValueChange?: (value: number | undefined) => void;
  onMaxValueChange?: (value: number | undefined) => void;
  className?: string;
}

export const InputRangeSlider: React.FC<IInputRangeSliderProps> = observer(
  ({ className, min, max, valueMin, valueMax, type = "", readonly = true, onMaxValueChange, onMinValueChange }) => {
    const { t } = useTranslation("common");
    const renderSliderType = useCallback(() => {
      if (type === "range") {
        return [valueMin || 0, valueMax || 100];
      }

      if (type === "min") {
        return valueMin || 0;
      }

      if (type === "max") {
        return valueMax || 100;
      }

      return [valueMin || 0, valueMax || 100];
    }, [type, valueMax, valueMin]);

    const [minChanged, setMinChanged] = React.useState<any>(0);
    const [maxChanged, setMaxChanged] = React.useState<any>(0);
    const [value, setValue] = React.useState<number | number[]>(renderSliderType());

    const handleChange = (_event: Event | React.ChangeEvent, newValue?: number | number[]) => {
      if (Array.isArray(newValue) && type === "range") {
        let min = newValue[0];
        let max = newValue[1];
        if (min > max) {
          min = max - 1;
        }
        setValue(newValue);
        if (onMinValueChange) onMinValueChange(min);
        if (onMaxValueChange) onMaxValueChange(max);
      }

      if (type === "min" || type === "max") {
        let newValueSet = newValue as number;
        if (type === "min" && valueMax && newValueSet > valueMax) {
          newValueSet = valueMax - 1;
        }
        if (type === "max" && valueMin && newValueSet < valueMin) {
          newValueSet = valueMin + 1;
        }
        setValue(newValueSet);
        if (onMinValueChange && type === "min") onMinValueChange(newValueSet);
        if (onMaxValueChange && type === "max") onMaxValueChange(newValueSet);
      }
    };

    useEffect(() => setValue(renderSliderType()), [type, min, max, valueMin, valueMax, renderSliderType]);

    return (
      <>
        <div className={classNames("input-range u-mobile-hide u-tablet-hide", { type, readonly }, className)}>
          {type === "min" || type === "range" ? (
            <div className="input-range-field input-holder min">
              {readonly ? <span>{valueMin}</span> : <></>}
              {!readonly ? (
                <input
                  type="text"
                  className="input input-default"
                  name="range-min"
                  value={valueMin ?? undefined}
                  onChange={(event) => {
                    clearTimeout(minChanged);
                    let number = event.target.value !== "" ? Number(event.target.value) : undefined;
                    if (onMinValueChange) {
                      onMinValueChange(number);
                    }
                    let timeout = setTimeout(() => {
                      if (valueMax && number && valueMax < number) {
                        number = valueMax - 1;
                      }
                      if (onMinValueChange) {
                        onMinValueChange(number);
                      }
                    }, 500);
                    setMinChanged(timeout);
                  }}
                />
              ) : null}
              <label htmlFor="range-min" className="input-label u-display-block">
                {t("common:min")}
              </label>
            </div>
          ) : null}

          <Slider value={value} min={min} max={max} disableSwap valueLabelDisplay="auto" onChange={handleChange} disabled={readonly} />

          {type === "max" || type === "range" ? (
            <div className="input-range-field input-holder max">
              {readonly ? <span>{valueMax}</span> : null}
              {!readonly ? (
                <input
                  type="text"
                  className="input input-default"
                  name="range-max"
                  value={valueMax ?? undefined}
                  onChange={(event) => {
                    clearTimeout(maxChanged);
                    let number = event.target.value !== "" ? Number(event.target.value) : undefined;
                    if (onMaxValueChange) {
                      onMaxValueChange(number);
                    }
                    let timeout = setTimeout(() => {
                      if (valueMin && number && number < valueMin) {
                        number = valueMin + 1;
                      }
                      if (onMaxValueChange) {
                        onMaxValueChange(number);
                      }
                    }, 500);
                    setMaxChanged(timeout);
                  }}
                />
              ) : null}
              <label htmlFor="range-max" className="input-label u-display-block">
                {t("common:max")}
              </label>
            </div>
          ) : null}
        </div>
        <div
          className={classNames(
            "input-range u-desktop-hide input-range-mobile-tablet",
            {
              type,
              readonly: readonly,
            },
            className,
          )}>
          <Slider
            value={value}
            min={min}
            max={max}
            disableSwap
            valueLabelDisplay="auto"
            className="slider"
            onChange={handleChange}
            disabled={readonly}
          />

          <div className="min-max-wrapper">
            {type === "min" || type === "range" ? (
              <div className="input-range-field input-holder min input-range-min-max-mobile-tablet">
                <label htmlFor="range-min" className="input-label u-display-block">
                  {t("common:min")}
                </label>
                {readonly ? <span>{valueMin}</span> : <></>}
                {!readonly ? (
                  <input
                    type="text"
                    className="input input-default"
                    name="range-min"
                    value={valueMin ?? undefined}
                    onChange={(event) => {
                      if (onMinValueChange) {
                        onMinValueChange(event.target.value !== "" ? Number(event.target.value) : undefined);
                      }
                    }}
                  />
                ) : null}
              </div>
            ) : null}

            {type === "max" || type === "range" ? (
              <div className="input-range-field input-holder max input-range-min-max-mobile-tablet">
                <label htmlFor="range-max" className="input-label u-display-block">
                  {t("common:max")}
                </label>
                {readonly ? <span>{valueMax}</span> : null}
                {!readonly ? (
                  <input
                    type="text"
                    className="input input-default"
                    name="range-max"
                    value={valueMax ?? undefined}
                    onChange={(event) => {
                      if (onMaxValueChange) {
                        onMaxValueChange(event.target.value !== "" ? Number(event.target.value) : undefined);
                      }
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  },
);
