import React from "react";
import { observer } from "mobx-react-lite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatepickerDropdown.scss";
import { format } from "date-fns";
import { getUserDateFormat } from "../AppState";
import { DateFormatDatepicker } from "../Enums";

// TODO: The old interface had two dropdowns. I went with the one that seemed more common and adjusted the forms to all use it. If we
// want styling adjustments it would be better to have style overrides for certain cases than two separate components.

// Seehttps://date-fns.org/v2.28.0/docs/format and https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
// for details on formatting

interface IDatepickerDropdownProps {
  name?: string;
  minDate?: Date;
  maxDate?: Date;
  isClearable?: boolean;
  selectedDate?: Date;
  dateDisabled?: Date[];
  hasTimeSelector?: boolean;
  onSelectDate: (date: Date) => void;
  onSelectionDone?: () => void;
  disabled?: boolean;
}

const getUserDatepickerDateFormat = () => DateFormatDatepicker[getUserDateFormat()];

const getDatepickerFormat = (selectedDate: Date, hasTimeSelector: boolean) => {
  return hasTimeSelector
    ? format(selectedDate, getUserDatepickerDateFormat() + " hh:mm a")
    : format(selectedDate, getUserDatepickerDateFormat());
};

export const DatepickerDropdown: React.FC<IDatepickerDropdownProps> = observer(
  ({ name, selectedDate, isClearable, hasTimeSelector = false, onSelectDate, disabled = false, minDate, maxDate }) => {
    return (
      <DatePicker
        disabled={disabled}
        name={name}
        isClearable={isClearable}
        selected={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onSelectDate}
        dateFormat={getUserDatepickerDateFormat()}
        showTimeSelect={hasTimeSelector}
        customInput={
          <div className="input-holder datepicker mod-block">
            <input
              id={name}
              type="text"
              className="input input-default"
              placeholder={getUserDatepickerDateFormat()}
              readOnly
              value={selectedDate ? getDatepickerFormat(selectedDate, hasTimeSelector) : ""}
            />
            <div className="input-holder-icon" />
          </div>
        }
      />
    );
  },
);
