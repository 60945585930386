import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CircularProgress, Drawer } from "@mui/material";
import { DashboardLeftRail, LinkDeviceToGateway, WelcomeInstructions } from "./Widgets";
import { useDeviceTypeGroups } from "../../Managers/API";
import {
  AppState,
  getDevices,
  setFetchedDevices,
  setGlobalSelectedGateway,
  setSelectedDeviceGroup,
  setSelectedLocation,
  showAppModal,
  setDashboardFilters,
} from "../../AppState";
import { NoResults, Page, useScreenMode, Wrapper } from "../../Components";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import { checkForWai418Devices, mySearch } from "../../Managers";
import "./Dashboard.scss";
import { DeviceSort, OrderBy, WindowSize } from "../../Enums";
import { useTranslation } from "react-i18next";
import { DashboardHeaderRow } from "./DashboardHeaderRow";
import { MobileDashboardHeader } from "./MobileDashboardHeader";
import { fetchDashboardDevices, IDeviceFilter, useDashboardDevices } from "../../Managers/LocationService";
import { DeviceCard } from "./DeviceCard";
import { DeviceDetailModal } from "./Modals/DeviceDetailModal";

export const Dashboard: React.FC = observer(() => {
  const [selectedGateway, setSelectedGateway] = useState<IGateway | undefined | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<IDeviceGroup | undefined | null>(null);
  const [searchString, setSearchString] = useState("");
  const [deviceList, setDeviceList] = useState<Array<IDevice>>([]);
  const [showLoadButton, setShowLoadButton] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortBy, setSortBy] = useState<DeviceSort>(AppState.dashboardSorting.by);
  const [orderBy, setOrderBy] = useState<OrderBy>(AppState.dashboardSorting.order);
  const [mode, setMode] = useState<"gateways" | "groups">("gateways");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOpen, setFiltersOpen] = useState(false);
  const [chipLength, setChipLength] = useState(0);
  const [deviceFilters, setDeviceFilters] = useState<IDeviceFilter>(AppState.dashboardFiltering);

  const { t } = useTranslation(["dashboard", "common"]);

  const deviceTypeGroups = useDeviceTypeGroups();
  const gateways = AppState.selectedLocation?.Gateways || [];
  const allDevices: IDevice[] = getDevices();
  const windowMode = useScreenMode();

  const dashboardDevicesQuery = useDashboardDevices(
    AppState.selectedLocation?._id || 0,
    selectedGateway?._id,
    selectedGroup?._id,
    {
      prop: sortBy,
      dir: orderBy,
    },
    deviceFilters,
    currentPage,
    AppState.deviceLimit,
  );

  useEffect(() => {
    setSelectedGateway(mode === "gateways" ? AppState.selectedLocation?.Gateways[0] : null);
    setSelectedGroup(mode === "groups" ? AppState.selectedLocation?.Device_groups[0] : null);
  }, [AppState.selectedLocation?._id]);

  // This is done automatically by AppState post-login or session-load. There's nothing for us to do until it completes, so we exit early.
  if (deviceTypeGroups.isLoading) {
    console.log("[DASHBOARD] Awaiting full session load...");
    return (
      <Page>
        <Wrapper>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  useEffect(() => {
    dashboardDevicesQuery.refetch();
  }, [selectedGateway, selectedGroup, AppState.selectedLocation, currentPage]);

  useEffect(() => {
    if (dashboardDevicesQuery.data) {
      setFetchedDeviceList(dashboardDevicesQuery.data.rows);
    }
  }, [dashboardDevicesQuery.dataUpdatedAt]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      forceRefreshDevices(orderBy, sortBy, deviceFilters);
    }, 30000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage, orderBy, sortBy, deviceFilters]);

  useEffect(() => {
    const locationInterval = setInterval(() => {
      setSelectedLocation(AppState.selectedLocation!, false);
    }, 120000);

    return () => clearInterval(locationInterval);
  }, [selectedGateway, selectedGroup, currentPage]);

  useEffect(() => {
    let currentDevices: IDevice[];

    if (selectedGateway) {
      currentDevices = allDevices.filter((device: any) => device.Gateways[0]._id === selectedGateway._id);
    } else if (selectedGroup) {
      currentDevices = [];
    } else {
      currentDevices = AppState.selectedLocation?.Gateways.flatMap((g) => g.Devices.rows).slice(0, AppState.deviceLimit) || [];
    }

    setFetchedDeviceList(currentDevices);
  }, [AppState.selectedLocation]);

  const getSelectedCount = () => {
    if (selectedGroup) {
      return selectedGroup.Devices.length;
    } else if (selectedGateway) {
      return selectedGateway.Devices.count;
    }
    return gateways.reduce((a, b) => a + b.Devices.count, 0);
  };

  useEffect(() => {
    setShowLoadButton((currentPage + 1) * AppState.deviceLimit < getSelectedCount());
  }, [selectedGateway, selectedGroup, currentPage, getSelectedCount]);

  const getSelectedName = useCallback(() => {
    if (selectedGroup && selectedGroup.name) {
      return selectedGroup.name;
    } else if (selectedGateway) {
      return selectedGateway.name || t("dashboard:gateway_name") + " " + selectedGateway._id;
    }
    return t("dashboard:all_devices");
  }, [selectedGroup, selectedGateway, AppState.selectedLocation]);

  const forceRefreshDevices = useCallback((order?: OrderBy, sort?: string, filter?: IDeviceFilter) => {
    console.log(order, sort);
    setSortBy((sort as DeviceSort) ?? sortBy);
    setOrderBy(order ?? orderBy);
    dashboardDevicesQuery.refetch();
  }, []);

  const setFetchedDeviceList = useCallback((devices: Array<IDevice>) => {
    setFetchedDevices(devices);
    setDeviceList(devices);
  }, []);

  const loadMoreDevices = useCallback(() => {
    setCurrentPage(currentPage + 1);
    setOrderBy(orderBy);
    setSortBy(sortBy);
  }, [deviceList, currentPage, orderBy, sortBy]);

  const selectGroup = useCallback(
    (group: IDeviceGroup | null) => {
      setCurrentPage(0);
      setSelectedDeviceGroup(group);
      setFiltersOpen(false);
      setDeviceFilters({
        sensorType: [],
        deviceType: [],
        inAlert: [],
        offline: [],
        approaching: [],
        sampleInterval: [],
        transmitInterval: [],
      } as IDeviceFilter);
      setSelectedGroup(group);
    },
    [getSelectedCount],
  );

  const selectGateway = useCallback((gateway: IGateway | null) => {
    setCurrentPage(0);
    setGlobalSelectedGateway(gateway);
    setFiltersOpen(false);
    setDeviceFilters({
      sensorType: [],
      deviceType: [],
      inAlert: [],
      offline: [],
      approaching: [],
      sampleInterval: [],
      transmitInterval: [],
    } as IDeviceFilter);
    setSelectedGateway(gateway);
  }, []);
  const handleCheckDetail = (device: IDevice) => {
    let isWai = checkForWai418Devices(device.serial_number);

    showAppModal(<DeviceDetailModal deviceId={device._id} isWai418={isWai} refresh={forceRefreshDevices} />);
  };

  return (
    <Page>
      <Wrapper>
        {windowMode === WindowSize.DESKTOP ? (
          <DashboardLeftRail
            gateways={AppState.selectedLocation?.Gateways || []}
            deviceGroups={AppState.selectedLocation?.Device_groups || []}
            onSelectGateway={selectGateway}
            onSelectGroup={selectGroup}
          />
        ) : null}
        {windowMode === WindowSize.TABLET ? (
          <Drawer onBackdropClick={() => setDrawerOpen(false)} open={drawerOpen}>
            <DashboardLeftRail
              gateways={AppState.selectedLocation?.Gateways || []}
              deviceGroups={AppState.selectedLocation?.Device_groups || []}
              onSelectGateway={selectGateway}
              onSelectGroup={selectGroup}
            />
          </Drawer>
        ) : null}

        <main className="bapi-main mod-dashboard page-dashboard-auto">
          {windowMode !== WindowSize.MOBILE ? (
            <DashboardHeaderRow
              setSortBy={setSortBy}
              setOrderBy={setOrderBy}
              name={getSelectedName()}
              deviceList={deviceList}
              allDevices={dashboardDevicesQuery?.data?.rows ?? []}
              selectGateway={selectGateway}
              selectedGateway={selectedGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              count={getSelectedCount()}
              searchString={searchString}
              setSearchString={setSearchString}
              setDrawerOpen={setDrawerOpen}
              setFiltersOpen={setFiltersOpen}
              filtersOpen={filterOpen}
              setFilters={setDeviceFilters}
              setChipLength={setChipLength}
            />
          ) : (
            <MobileDashboardHeader
              setSortBy={setSortBy}
              setOrderBy={setOrderBy}
              allDevices={dashboardDevicesQuery?.data?.rows ?? []}
              tab={mode}
              setTab={setMode}
              gateways={AppState.selectedLocation?.Gateways || []}
              selectedGateway={selectedGateway}
              selectGateway={selectGateway}
              selectGroup={selectGroup}
              selectedGroup={selectedGroup}
              groups={AppState.selectedLocation?.Device_groups || []}
              searchString={searchString}
              setSearchString={setSearchString}
              setFilters={setDeviceFilters}
              setChipLength={setChipLength}
            />
          )}
          {AppState.selectedLocationId === -1 ? (
            <i className="fa fa-spin fa-spinner" />
          ) : (
            <>
              {!gateways.length && mode === "gateways" ? <WelcomeInstructions /> : null}
              {mode === "groups" && !AppState.selectedLocation?.Device_groups.length ? (
                <div className={classNames("dashboard-content setup-container main-panel extended")}>{t("dashboard:no_groups")}</div>
              ) : null}

              {gateways.length && !deviceList.length && !selectedGroup && mode === "gateways" ? <LinkDeviceToGateway /> : <></>}

              <div className={classNames("main-panel", "extended")}>
                {deviceList?.length > 0 && searchString && mySearch<IDevice>(deviceList, searchString)?.length === 0 ? (
                  <NoResults />
                ) : (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 15 }}>
                    {mySearch<IDevice>(deviceList, searchString).map((device, index) => (
                      <DeviceCard
                        key={`${device._id}-${index}`}
                        isWai418={checkForWai418Devices(device.serial_number)}
                        cardDevice={device}
                        onCheckDetail={handleCheckDetail}
                        refresh={forceRefreshDevices}
                      />
                    ))}
                  </div>
                )}

                {showLoadButton && (
                  <div className="load-more-button">
                    <button className="btn btn-primary load-more-button" onClick={() => loadMoreDevices()}>
                      {t("dashboard:load_more")}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </Wrapper>
    </Page>
  );
});
