import { Modal } from "../../Components/Modal";
import { AppState, showAppModal } from "../../AppState";
import React, { useEffect, useState } from "react";
import { LegalType } from "../../Enums";
import { getLegal, useLegal } from "../../Managers";
import { ILegal } from "../../Managers/Types";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { CheckboxInput, StyledTooltip } from "../../Components";

interface ISelf {
  accepted?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  acceptText?: string;
  noScroll?: boolean;
  skipScrollOnCheckboxSelected?: boolean;
  checkboxLabel?: string;
  type: LegalType;
  isUserOutsideUS?: boolean;
}

const ScrollToBottomModal: React.FC<ISelf & { children: React.ReactNode; title: string }> = ({
  accepted = false,
  onAccept,
  onCancel,
  children,
  title,
  cancelText,
  acceptText,
  noScroll,
  skipScrollOnCheckboxSelected,
  checkboxLabel,
  type,
  isUserOutsideUS,
}) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(accepted || noScroll);
  const [isChecked, setIsChecked] = useState(accepted);
  const [isCheckedTransfer, setIsCheckedTransfer] = useState(false);
  const { t } = useTranslation("common");

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const isNearBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setScrolledToBottom(isNearBottom);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handleCheckboxTransferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTransfer(e.target.checked);
  };

  const isButtonDisabled = () => {
    if (!isChecked) {
      return true;
    }

    if (!skipScrollOnCheckboxSelected && !scrolledToBottom) {
      return true;
    }

    if (type === "privacy" && isUserOutsideUS && !isCheckedTransfer) {
      return true;
    }

    return false;
  };

  const buttons = accepted ? (
    <button className="btn btn-primary" onClick={() => showAppModal(null)}>
      {t("common:ok")}
    </button>
  ) : (
    <>
      <div className="legal-checkbox-wrapper">
        <CheckboxInput
          required={true}
          className="checkbox-accept-terms"
          name={"accept_terms"}
          label={checkboxLabel ?? t("legal:consent_to_terms_and_conditions")}
          checked={isChecked}
          fieldOnChange={handleCheckboxChange}
        />
        {type === "privacy" && isUserOutsideUS && (
          <div className="checkbox-data-transfer-wrapper">
            <CheckboxInput
              required={true}
              className="checkbox-accept-terms"
              name={"accept_transfer_data"}
              label={t("legal:consent_to_transfer_data")}
              checked={isCheckedTransfer}
              fieldOnChange={handleCheckboxTransferChange}
            />
            <StyledTooltip title={t("legal:consent_to_transfer_data_desc")}>
              <i className="fa fa-info-circle" />
            </StyledTooltip>
          </div>
        )}
      </div>

      <div className="close-accept-buttons-wrapper">
        <button
          className="btn btn-secondary legal-button"
          onClick={() => {
            onCancel && onCancel();
            showAppModal(null);
          }}>
          {cancelText ?? t("common:close")}
        </button>
        <button
          disabled={isButtonDisabled()}
          className="btn btn-primary legal-button"
          onClick={() => {
            if (onAccept) onAccept();
            showAppModal(null);
          }}>
          {acceptText ?? t("common:accept")}
        </button>
      </div>
    </>
  );

  return (
    <Modal title={title} buttons={buttons} className="legal-modal-dialog">
      {noScroll ? (
        <div>{children}</div>
      ) : (
        <div
          onScroll={(e) => {
            if (!scrolledToBottom) {
              handleScroll(e);
            }
          }}
          className={"legal-modal"}>
          {children}
        </div>
      )}
    </Modal>
  );
};

interface ILegalModal extends ISelf {
  type: LegalType;
  countryCode?: string;
}

export const LegalModal: React.FC<ILegalModal> = (props) => {
  const [defaultLegal, setDefaultLegal] = useState<ILegal>();
  const { t } = useTranslation("legal");
  const legal = useLegal(props.countryCode ?? AppState.user?.country_code ?? "US", props.type);
  const legalCheckboxLabel =
    props.type === "privacy"
      ? t("legal:consent_to_privacy_policy")
      : props.type === "terms"
      ? t("legal:consent_to_terms_and_conditions")
      : undefined;

  useEffect(() => {
    if ((legal.error as AxiosError)?.response?.status === 404) {
      getLegal("US", props.type).then((res) => setDefaultLegal(res));
    }
  }, [legal.status]);

  return (
    <ScrollToBottomModal
      {...props}
      title={t("legal:" + props.type) ?? ""}
      skipScrollOnCheckboxSelected={true}
      checkboxLabel={legalCheckboxLabel}
      isUserOutsideUS={props.isUserOutsideUS}>
      <div
        style={{ height: "unset", marginBottom: 40 }}
        className="ql-content-display"
        dangerouslySetInnerHTML={{ __html: `${legal.data?.content ?? defaultLegal?.content ?? ""}` }}></div>
    </ScrollToBottomModal>
  );
};

export const MarketingModal: React.FC<ISelf> = (props) => {
  const { t } = useTranslation("register");
  return (
    <ScrollToBottomModal {...props} title={t("steps.account.marketing_modal_title") ?? ""}>
      <p style={{ textAlign: "center", fontWeight: "bold" }}>{t("steps.account.marketing_modal")}</p>
    </ScrollToBottomModal>
  );
};
