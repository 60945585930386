import { Endpoint } from "./API";
import { IManageSystemMessage } from "./Types";

const API_PATH = "api/system_messages";
export const QK_SYSTEM_MESSAGES = ["SYSTEM_MESSAGES"];

export const getAllSystemMessages = () => Endpoint.get<IManageSystemMessage[]>(`${API_PATH}/`).then((r) => r.data);

export const getMessagesByType = (type: string) =>
  Endpoint.get<IManageSystemMessage[]>(`${API_PATH}`, { data: { type } }).then((r) => r.data);

export const updateSystemMessage = (type: string, newSystemMessageInfo: IManageSystemMessage) =>
  Endpoint.put<IManageSystemMessage>(`${API_PATH}/`, { type: type, data: newSystemMessageInfo }).then((r) => r.data);

export const sendNotificationEmails = (type: string) => Endpoint.get(`${API_PATH}/sendEmails`, { params: { type } }).then((r) => r.data);
export const resetSystemMessageClosedFlag = (type: string) => Endpoint.put(`${API_PATH}/reset`, { params: { type } }).then((r) => r.data);
export const closeNotification = (type: string, userId: number | string) =>
  Endpoint.put(`${API_PATH}/close`, { params: { type, userId } }).then((r) => r.data);
