import { DatepickerDropdown } from "./DatepickerDropdown";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./RangePicker.scss";

interface IRangePickerProps {
  startDate: Date;
  endDate: Date;
  maxDate?: Date;
  minDate?: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  className?: string;
  style?: React.CSSProperties;
  hasTimeSelector?: boolean;
  disabled?: boolean;
}

export const RangePicker: React.FC<IRangePickerProps> = ({
  hasTimeSelector = false,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  maxDate,
  minDate,
  disabled = false,
  className,
  ...rest
}) => {
  const { t } = useTranslation("common");

  return (
    <div className={classNames("range-picker-wrapper", className)} {...rest}>
      <div className="from">
        <label className="input-label"> {t("common:from")}</label>
        <DatepickerDropdown
          hasTimeSelector={hasTimeSelector}
          selectedDate={startDate}
          minDate={minDate}
          maxDate={endDate}
          onSelectDate={(date) => setStartDate(date)}
          disabled={disabled}
        />
      </div>

      <div className="to">
        <label className="input-label">{t("common:to")}</label>

        <DatepickerDropdown
          hasTimeSelector={hasTimeSelector}
          selectedDate={endDate}
          maxDate={maxDate}
          minDate={startDate}
          onSelectDate={(date) => setEndDate(date)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
