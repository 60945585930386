import { Endpoint, invalidateQuery } from "./API";
import { ICountry } from "./CountryService";
import { ISelectOption } from "../Components";

export const QK_CURRENCIES = ["CURRENCIES"];
const API_PATH = "api/currencies";

export interface ICurrency {
  _id: number;
  name: string;
  iso_code: string;
  sign: string;
  Countries: Array<ICountry>;
  active: boolean | string;
}

export const stripeSupportedCurrencyIsoCodes: Array<string> = [
  "AED",
  "ALL",
  "AMD",
  "ANG",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BIF",
  "BMD",
  "BND",
  "BSD",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHF",
  "CNY",
  "CZK",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ETB",
  "EUR",
  "FJD",
  "GBP",
  "GEL",
  "GIP",
  "GMD",
  "GYD",
  "HKD",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "ISK",
  "JMD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KRW",
  "KYD",
  "KZT",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MVR",
  "MWK",
  "MXN",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NOK",
  "NPR",
  "NZD",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SEK",
  "SGD",
  "SLE",
  "SOS",
  "SZL",
  "THB",
  "TJS",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "UZS",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XCD",
  "XPF",
  "YER",
  "ZAR",
  "ZMW",
];

export function getCurrencyIsoCodesForDropdown(): Array<ISelectOption> {
  return stripeSupportedCurrencyIsoCodes.map((code) => ({
    value: code,
    label: code,
  }));
}

export const addCurrency = (currency: ICurrency) =>
  Endpoint.post(`${API_PATH}`, currency).then((r) => {
    invalidateQuery(QK_CURRENCIES);
    return r.data;
  });

export const deleteCurrency = (currency: ICurrency) =>
  Endpoint.delete(`${API_PATH}/${currency._id}`).then((r) => {
    invalidateQuery(QK_CURRENCIES);
    return r.data;
  });

export const updateCurrency = (currency: ICurrency) =>
  Endpoint.put(`${API_PATH}/${currency._id}`, currency).then((r) => {
    invalidateQuery(QK_CURRENCIES);
    return r.data;
  });
