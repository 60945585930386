import * as yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormFieldCheckbox, FormFieldRadio, FormFieldSelect, FormFieldText, ISelectOption } from "../../../Components";
import { ILocation, IPermission, IUser } from "../../../Managers/Types";
import { createInvitedUser, getNameSlug, ICountry, usePermissions } from "../../../Managers";
import * as UsersService from "../../../Managers/UsersService";
import { QK_USERS } from "../../../Managers/UsersService";
import { useAccounts } from "../../../Managers/AccountsService";
import { AppState, getUserDateFormat, refreshAppState, showAppModal, showSnackbar } from "../../../AppState";
import { isUserRoleAllowed, PermissionEnum, UserDateFormats, UserRoles, ValidationMessages } from "../../../Enums";
import { PhoneInput } from "../../../Components/PhoneInput";
import { Modal } from "../../../Components/Modal";
import { invalidateQuery, useCountries, useLocations } from "../../../Managers/API";
import { getLocationsUnderAccount } from "../../../Managers/LocationService";
import { useTranslation } from "react-i18next";

export const UserPropertiesModal: React.FC<{ user?: IUser }> = observer(({ user = null }) => {
  const countryList = useCountries();
  const countries = countryList.data ?? [];
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [accountId, setAccountId] = useState<number>();
  const [loadingLocations, setLoadingLocations] = useState(false);

  const locationsQuery = useLocations();

  const { t } = useTranslation(["users", "common"]);

  useEffect(() => {
    setAccountId(user?.Account?._id ?? AppState.selectedAccount?._id ?? AppState.user?.AccountId);
  }, [user?.Account?._id]);

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setLoadingLocations(true);

    if (isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      getLocationsUnderAccount(accountId)
        .then((res) => {
          setLocations(res ?? []);
          setLoadingLocations(false);
        })
        .catch(() => setLoadingLocations(false));
    } else {
      setLoadingLocations(false);
      setLocations(locationsQuery.data ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const permissions = usePermissions();

  const validationSchema = yup.object({
    first_name: yup.string().required(t(ValidationMessages.REQUIRED)),
    last_name: yup.string().required(t(ValidationMessages.REQUIRED)),
    email: yup.string().email(t(ValidationMessages.EMAIL)).required(t(ValidationMessages.REQUIRED)),
    permissions: yup.array(),
    Locations: yup.array().test("minLength", (value, obj) => (obj.parent.role !== UserRoles.USER ? true : !!value && value?.length >= 1)),
    AccountId: yup.number(),
    phone: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .matches(/^[0-9]+$/, ValidationMessages.PHONE),
    date_format: yup.string(),
  });

  const groupedPermissions = permissions.data?.reduce((r, a) => {
    r[a.access_group] = r[a.access_group] || [];
    r[a.access_group].push(a);
    return r;
  }, Object.create(null));

  const accounts = useAccounts();

  const isAppAdmin = AppState.user?.role === UserRoles.APP_ADMIN;
  const isMyUser = UsersService.isAccountUser(user);

  const handleSubmit = async (values: any) => {
    const { country, ...rest } = values;

    const params = {
      ...rest,
      phone_code: country.dial_code,
      country_code: country.code,
      AccountId: Number(rest.AccountId),
    };
    if (params.AccountId === 0 && isAppAdmin) {
      params.AccountId = accounts.data?.[0]?._id;
    }

    if (user) {
      const allParams = { ...user, ...params };
      if (allParams.AccountId === 0 && isAppAdmin) {
        allParams.AccountId = accounts.data?.[0]?._id;
      }

      return UsersService.updateUser(allParams)
        .then(() => {
          showSnackbar(
            t("users:properties.user_update_success", {
              first_name: user?.first_name,
              last_name: user?.last_name,
            }),
            "success",
          );
          showAppModal(null);
          invalidateQuery([...QK_USERS, user._id]);
        })
        .catch(() =>
          showSnackbar(
            t("users:properties.user_creation_error", {
              first_name: user?.first_name,
              last_name: user?.last_name,
            }),
            "error",
          ),
        );
    }

    return createInvitedUser({ ...params })
      .then(() => {
        refreshAppState();
        showSnackbar(t("users:properties.user_creation_success"), "success");
        showAppModal(null);
      })
      .catch((error) => {
        console.error(error.response.data);
        showSnackbar(t("users:properties.user_creation_error"), "error");
      });
  };

  const allPermissions = permissions.data ?? [];
  const newUserPermissions = [...allPermissions.filter((p) => p.access_group === "user")];

  const Locations = [...(user?.Locations ?? [])] as ILocation[];

  let country_code = user?.country_code ?? "US";
  const userPermissions = user?.Permissions ?? [];
  const initialValues = {
    country: countries.find((country: ICountry) => country.code === country_code),
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    role: user?.role ?? UserRoles.USER,
    title: user?.title ?? "",
    phone: user?.phone ?? "",
    email: user?.email ?? "",
    Permissions: user ? ([...userPermissions] as IPermission[]) : newUserPermissions,
    Locations,
    AccountId:
      user?.AccountId ??
      AppState.user?.AccountId ??
      (isAppAdmin ? (accounts.data && accounts.data.length > 0 ? accounts.data[0]._id : 0) : 0),
    date_format: getUserDateFormat(),
  };

  const RoleOptions = [
    { value: UserRoles.USER, label: t("common:user") },
    { value: UserRoles.ADMIN, label: t("common:admin") },
  ];

  if (isAppAdmin) {
    RoleOptions.push({ value: UserRoles.APP_ADMIN, label: t("common:app_admin") });
  }

  const disableEditingPermission = (permission: IPermission) => isMyUser && permission.name === PermissionEnum.EDIT_USERS;

  const generateSelectOptions = (): ISelectOption[] => {
    return (accounts.data ?? []).map((a) => ({
      value: a._id,
      key: `${a._id}`,
      label: a.name + (a._id === accountId ? ` (${t("common:current")})` : ""),
    }));
  };

  const renderPermissionsSection = (accessGroup: string, currentSelectedRole: string) =>
    !(accessGroup === "admin" && currentSelectedRole === UserRoles.USER);

  return (
    <Formik
      key={countries.length}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ values, errors, touched, isSubmitting, submitForm, setFieldValue, setFieldTouched }) => {
        return (
          <Modal
            className="user-properties-modal"
            title={user ? t("users:properties.edit_title") : t("users:properties.create_title")}
            buttons={
              <>
                <button type="button" className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button
                  type="button"
                  className={classNames("btn btn-primary", { disabled: isSubmitting || loadingLocations })}
                  onClick={submitForm}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}{" "}
                  {user?._id ? t("common:save") : t("users:properties.save")}
                </button>
              </>
            }>
            <form>
              <div className="row">
                <FormFieldText
                  className="col-sm-6"
                  name="first_name"
                  label={t("users:properties.first_name")}
                  required={true}
                  displayError={true}
                />
                <FormFieldText className="col-sm-6" name="last_name" label={t("users:properties.last_name")} required={true} />
              </div>

              <div className="row">
                <FormFieldText className="col-sm-6" name="title" label={t("users:properties.title")} />
                {isMyUser ? null : (
                  <FormFieldRadio className="col-sm-6" name="role" label={t("users:properties.role")} options={RoleOptions} />
                )}
              </div>

              <div className="row">
                <PhoneInput required={true} className="col-sm-6" countryName="country" name="phone" label={t("users:properties.phone")} />
                <FormFieldText className="col-sm-6" name="email" label={t("users:properties.email")} required={true} displayError={true} />
              </div>

              <div className="row">
                <FormFieldSelect
                  onChange={(format) => setFieldValue("date_format", format)}
                  options={UserDateFormats.map((format) => ({ label: format, value: format }))}
                  name="date_format"
                  className="col-sm-6"
                  label={t("users:properties.date_format")}
                />
              </div>

              {values.role !== UserRoles.APP_ADMIN ? (
                <div className="row">
                  <div className="form-group col-xs-12">
                    <label htmlFor="permissions" className="input-label">
                      {t("users:properties.permissions")}
                    </label>
                    <div className="row">
                      {Object.keys(groupedPermissions).map((key) => {
                        if (renderPermissionsSection(key, values.role)) {
                          return (
                            <>
                              <div className="col modal-row-title col-xs-12">{t("common:" + key)}</div>
                              {groupedPermissions[key].map((permission: IPermission) => (
                                <div className="col col-xs-12 col-md-6 user-permission" key={permission._id}>
                                  <FormFieldCheckbox
                                    style={{ display: "flex" }}
                                    name="permission"
                                    label={t(`users:permission.${getNameSlug(permission.label)}`)}
                                    checked={values.Permissions.findIndex((perm) => perm._id === permission._id) > -1}
                                    disabled={disableEditingPermission(permission)}
                                    readOnly={disableEditingPermission(permission)}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "Permissions",
                                        e.target.checked
                                          ? [...values.Permissions, permission]
                                          : values.Permissions.filter((perm) => perm._id !== permission._id),
                                      )
                                    }
                                  />
                                </div>
                              ))}
                            </>
                          );
                        }
                        return <></>;
                      })}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="form-group col-sm-6">
                  {values.role === UserRoles.USER ? (
                    <>
                      <label htmlFor="locations" className="input-label u-display-block">
                        {t("users:properties.location")}
                      </label>

                      <ul className="list-plain">
                        {locations.map((location) => (
                          <li key={location._id}>
                            <FormFieldCheckbox
                              label={location.name}
                              name="location"
                              checked={values.Locations.findIndex((loc) => loc._id === location._id) > -1}
                              onChange={(e) => {
                                setFieldTouched("Locations", true);
                                setFieldValue(
                                  "Locations",
                                  e.target.checked
                                    ? [...values.Locations, location]
                                    : values.Locations.filter((loc) => loc._id !== location._id),
                                );
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                      {locations?.length === 0 && !loadingLocations ? (
                        <span className="text-muted">
                          <em>{t("users:properties.no_locations")}</em>
                        </span>
                      ) : null}
                      {loadingLocations ? (
                        <span className="text-muted">
                          <em>{t("users:properties.fetching_locations")}</em>
                        </span>
                      ) : null}
                    </>
                  ) : null}

                  {errors.Locations && touched.Locations ? (
                    <div className="u-color-alert">{t("users:properties.locations_error")}</div>
                  ) : null}
                </div>

                <div className="row account-select">
                  {isAppAdmin ? (
                    <FormFieldSelect
                      onChange={(id: string) => {
                        if (id && Number(id) !== accountId) {
                          setFieldValue("Locations", []);
                        }
                        setAccountId(accounts.data?.find((acc) => acc._id.toString() === id)?._id);
                      }}
                      className="col-sm-6"
                      options={generateSelectOptions()}
                      name="AccountId"
                      label={t("users:properties.account")}
                    />
                  ) : null}
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
