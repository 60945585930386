import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableBodyProps,
  TableCell as MuiTableCell,
  TableCellProps,
  TableHead as MuiTableHead,
  TableHeadProps,
  TableProps,
  TableRow as MuiTableRow,
  TableRowProps,
} from "@mui/material";
import React, { useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Collapse } from "react-collapse";
import classNames from "classnames";

export const Table: React.FC<TableProps> = (props) => {
  return <MuiTable {...props} />;
};

export const TableBody: React.FC<TableBodyProps> = (props) => {
  return <MuiTableBody {...props} />;
};

export const TableCell: React.FC<TableCellProps> = (props) => {
  return <MuiTableCell {...props} />;
};

export const TableHead: React.FC<TableHeadProps> = (props) => {
  return <MuiTableHead {...props} />;
};

export const TableRow: React.FC<TableRowProps> = (props) => {
  return <MuiTableRow {...props} />;
};

export const CollapseTableRow: React.FC<
  TableRowProps & {
    collapseData: React.ReactNode;
    disabled?: boolean;
  }
> = (props) => {
  const [open, setOpen] = useState(false);
  const { collapseData, children, ...rest } = props;
  return (
    <>
      <TableRow {...rest}>
        <TableCell>
          <div onClick={() => setOpen(!open)} className="btn btn-plain u-text-teal">
            <i className={classNames("fa fa-chevron-down fa-rotate", { rotated: open })} />
          </div>
        </TableCell>
        {children}
      </TableRow>
      <TableRow {...rest}>
        <TableCell colSpan={Array.isArray(children) ? children.length + 1 : undefined}>
          <Collapse isOpened={open}>{collapseData}</Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const SortableTableRow: React.FC<TableRowProps & { sortable?: boolean }> = ({ children, id = "", sortable = true }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: "inherit",
  };

  return (
    <>
      <tr ref={setNodeRef} {...attributes} style={style}>
        {children}
        {sortable ? (
          <TableCell align="center">
            <i ref={setActivatorNodeRef} style={{ cursor: "move" }} className="fa fa-bars" aria-hidden="true" {...listeners} />
          </TableCell>
        ) : null}
      </tr>
    </>
  );
};
