import { getUsers } from "../../Managers/UsersService";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import React, { useEffect, useState } from "react";
import { IUser } from "../../Managers/Types";
import { exportToXLSX, ICountry } from "../../Managers";
import { CountrySelect, Flag, HeaderRow, InputLabel, LeftRail, Page, SelectInput, useScreenMode, Wrapper } from "../../Components";
import "./ExportUsers.scss";
import { UserNav } from "./UsersNav";
import { useTranslation } from "react-i18next";
import { MobileUserHeader } from "./MobileUserHeader";
import { Drawer } from "@mui/material";
import { DrawerIcon } from "../../icon";
import { useCountries } from "../../Managers/API";
import { WindowSize } from "../../Enums";

export const ExportUsers: React.FC = () => {
  const countryList = useCountries();
  const countries = countryList.data ?? [];
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [selectedMarketingFilter, setSelectedMarketingFilter] = useState<boolean>();
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [companies, setCompanies] = useState<string[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { t } = useTranslation("users");
  const mode = useScreenMode();

  useEffect(() => setSelectedCountry(countries.find((c) => c.code === "US")), [countryList.isFetched]);

  useEffect(() => {
    getUsers(true).then((res) => {
      setUsers(res);
      setSelectedCompany(res[0]?.Account?.name);
    });
  }, []);

  useEffect(() => {
    setFilteredUsers(
      users
        .filter((u) => !selectedCountry || u.country_code === selectedCountry?.code)
        .filter((u) => !selectedCompany || u.Account?.name === selectedCompany)
        .filter((u) => !selectedMarketingFilter || u.is_marketing_accepted === selectedMarketingFilter),
    );

    if (companies.length === 0 && users) {
      const companiesList = users.filter((u) => u.Account).map((u) => u.Account!.name) ?? [];
      const companiesSet = new Set<string>(companiesList);
      setCompanies(Array.from(companiesSet));
    }
  }, [selectedCountry, selectedCompany, users, companies.length, selectedMarketingFilter]);

  const handleMarketingChange = (marketing: boolean) => {
    if (marketing === undefined) {
      setSelectedUsers([]);
    }
    setSelectedMarketingFilter(marketing);
  };

  const selectUsers = (users: IUser[], select = true) => {
    const selected = [...selectedUsers];

    users.forEach((user) => {
      const selectedUserIndex = selected.findIndex((u) => u._id === user._id);
      if (select && selectedUserIndex === -1) {
        selected.push(user);
      } else if (!select) {
        selected.splice(selectedUserIndex, 1);
      }
    });

    setSelectedUsers(selected);
  };

  const exportToCSV = () => {
    const exportData = selectedUsers.map((u) => {
      const {
        first_name,
        last_name,
        title,
        Account,
        email,
        secondary_email,
        phone_code,
        phone,
        secondary_phone_code,
        secondary_phone,
        country_code,
        is_marketing_accepted,
        use_secondary_email_for_marketing,
      } = u;

      return {
        first_name,
        last_name,
        title,
        company: Account?.name,
        email,
        secondary_email: use_secondary_email_for_marketing ? secondary_email : email,
        phone: (phone_code ?? "") + (phone ?? ""),
        secondary_phone: (secondary_phone_code ?? "") + (secondary_phone ?? "-"),
        country_code,
        is_marketing_accepted: is_marketing_accepted ? t("common:yes") : t("common:no"),
      };
    });

    exportToXLSX(`${t("export.export_title")} ${new Date().toDateString()}`, exportData, [
      t("export.first_name"),
      t("export.last_name"),
      t("export.company"),
      t("export.title"),
      t("export.email"),
      t("export.email_for_marketing"),
      t("export.phone"),
      t("export.secondary_phone"),
      t("export.country"),
      t("export.marketing_info"),
    ]);
  };

  const handleCountryChange = (country: ICountry) => {
    if (!country) {
      setSelectedCountry(undefined);
    } else {
      setSelectedUsers([]);
      setSelectedCountry(country);
    }
  };

  const handleCompanyChange = (company: string) => {
    setSelectedUsers([]);
    setSelectedCompany(company);
  };

  const shouldDisplaySecondaryPhone = (user: IUser) => {
    return user.secondary_phone_code && user.secondary_phone_code.length > 0 && user.secondary_phone && user.secondary_phone.length > 0;
  };

  return (
    <Page key={countries.length}>
      <Wrapper>
        <Drawer
          variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
          hideBackdrop={mode === WindowSize.DESKTOP}
          onBackdropClick={() => mode === WindowSize.TABLET && setDrawerOpen(false)}
          open={drawerOpen || mode === WindowSize.DESKTOP}>
          <LeftRail header={<UserNav value="export" />} body={<></>} />
        </Drawer>
        <main className="bapi-main export-users mod-dashboard">
          <MobileUserHeader currentPage={"export"} />
          <HeaderRow
            info={{
              name: (
                <>
                  <button onClick={() => setDrawerOpen(!drawerOpen)} className="btn btn-icon u-tablet-only">
                    <DrawerIcon />
                  </button>
                  {t("users:export.page_title")}
                </>
              ),
            }}
            infoControl={
              <div className="header-row-info-control" style={{ gap: "2em" }}>
                <div className="col-sm-4">
                  <InputLabel name="country" label={t("users:export.country")} required={false} />
                  <CountrySelect
                    showAll
                    onChange={handleCountryChange}
                    selectedCountryLabel="name"
                    formikControl={false}
                    value={selectedCountry}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectInput
                    value={selectedCompany}
                    label={t("users:export.company")}
                    onChange={(c) => handleCompanyChange(c)}
                    options={[
                      { value: undefined, label: t("common:all") },
                      ...companies.map((c) => ({
                        value: c,
                        label: c,
                      })),
                    ]}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectInput
                    value={selectedMarketingFilter}
                    label={t("users:export.marketing_info")}
                    onChange={(c) => handleMarketingChange(c)}
                    options={[
                      { value: undefined, label: t("common:all") },
                      { value: true, label: t("common:yes") },
                      { value: false, label: t("common:no") },
                    ]}
                  />
                </div>
              </div>
            }
            mainRight={
              <button className="btn btn-primary u-desktop-only" disabled={selectedUsers.length === 0} onClick={exportToCSV}>
                <span>{t("export.xlx_export")}</span>
              </button>
            }
          />
          <section className="main-panel export-users-panel">
            <div className="user-table-wrapper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={20}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          id="users"
                          name="users"
                          onChange={(e) => selectUsers(filteredUsers ?? [], e.target.checked)}
                          checked={filteredUsers.length === selectedUsers.length}
                        />
                        <span />
                      </label>
                    </TableCell>
                    <TableCell width="8%">{t("users:export.name")}</TableCell>
                    <TableCell width="12%">{t("users:export.company")}</TableCell>
                    <TableCell width="12%">{t("users:export.title")}</TableCell>
                    <TableCell width="8%">{t("users:export.email")}</TableCell>
                    <TableCell width="8%">{t("users:export.email_for_marketing")}</TableCell>
                    <TableCell width={200}>{t("users:export.phone")}</TableCell>
                    <TableCell width={200}>{t("users:export.secondary_phone")}</TableCell>
                    <TableCell width={50}>{t("users:export.country")}</TableCell>
                    <TableCell width={50}>{t("users:export.marketing_info")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="input-checkbox"
                            id="users"
                            name="users"
                            onChange={(e) => selectUsers([user], e.target.checked)}
                            checked={selectedUsers.some((u) => user._id === u._id)}
                          />
                          <span />
                        </label>
                      </TableCell>
                      <TableCell>
                        {user.first_name} {user.last_name}
                      </TableCell>
                      <TableCell>{user.Account?.name}</TableCell>
                      <TableCell>{user.title}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.use_secondary_email_for_marketing ? user.secondary_email : user.email}</TableCell>
                      <TableCell>
                        {user.phone_code} {user.phone}
                      </TableCell>
                      <TableCell>
                        {shouldDisplaySecondaryPhone(user) ? (
                          <>
                            {user.secondary_phone_code} {user.secondary_phone}
                          </>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell>
                        {user.country_code ? <Flag countryCode={user.country_code} /> : null}
                        {user.country_code}
                      </TableCell>
                      <TableCell>{user.is_marketing_accepted ? t("common:yes") : t("common:no")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={{ textAlign: "center" }}>{filteredUsers.length === 0 ? t("users:export.no_users_found") : null}</div>
            </div>
            <div className="main-panel-footer">
              <button className="btn btn-primary u-desktop-hide" disabled={selectedUsers.length === 0} onClick={exportToCSV}>
                <span>{t("export.xlx_export")}</span>
              </button>
            </div>
          </section>
        </main>
      </Wrapper>
    </Page>
  );
};
