import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { QueryClientProvider } from "react-query";
import { Content, PageWrapper, ViewportProvider } from "./Components";
import { MainRouter } from "./Views/MainRouter";
import { ModalServiceProvider } from "./Modals";
import { queryClient } from "./Managers/API";
import { wamTheme } from "./Theme";
import "./Styles/styles.scss";
import "./Main.scss";
import { checkSession } from "./AppState";

function App() {
  const [checkingSession, setCheckingSession] = useState(true);

  useEffect(() => {
    checkSession().finally(() => setCheckingSession(false));
  }, []);

  if (checkingSession) {
    return (
      <PageWrapper>
        <Content style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Content>
      </PageWrapper>
    );
  }

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={wamTheme}>
        <ViewportProvider>
          <QueryClientProvider client={queryClient}>
            <ModalServiceProvider>
              <MainRouter />
            </ModalServiceProvider>
          </QueryClientProvider>
        </ViewportProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
