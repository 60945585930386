import { IDevice, IDeviceType, ISensor, ISensorType } from "./Types";
import { getNotificationsHistory } from "./NotificationService";
import moment from "moment/moment";
import * as Measurements from "./MeasurementService";
import { measurementTransform } from "./MeasurementService";
import * as Units from "./UnitsService";
import { unitsTransform } from "./UnitsService";
import { alertConditionTransform } from "./AlertConditionService";
import { TFunction } from "i18next";
import { AppState, getUserDateFormat } from "../AppState";
import {
  checkForWai418Devices,
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  formatDateCustom,
  getNameSlug,
} from "./Utils";
import { ILineChartValue } from "../Components";
import { DateFormat } from "../Enums";
import { isGenericAlert } from "./AlertService";
import { IExportFilter } from "../Views/Export/Export";
import { getIntervalOptions, getTransmitIntervalOptions, ISensorDatapoint } from "./DeviceService";

export const prepareHistoricalData = async (
  sensorParams: {
    id: number;
    include_alert_protocol: boolean;
    include_alert_notes: boolean;
  }[],
  startDate: Date,
  endDate: Date,
  t: TFunction,
) => {
  const historicalData = await getNotificationsHistory(
    moment(startDate).startOf("day").toISOString(),
    moment(endDate).endOf("day").toISOString(),
  );

  const includeNotes = sensorParams.some((s) => s.include_alert_notes);
  const includeProtocol = sensorParams.some((s) => s.include_alert_protocol);

  const headers = [
    { header: t("export:value"), dataKey: "value" },
    { header: t("export:name"), dataKey: "name" },
    { header: t("export:condition"), dataKey: "condition" },
    { header: t("export:placement"), dataKey: "placement" },
    { header: t("export:serial"), dataKey: "serial" },
    { header: t("export:last_updated"), dataKey: "lastUpdateAt" },
    { header: t("export:resolved"), dataKey: "resolved" },
  ];

  if (includeNotes) {
    headers.push({ header: t("export:alert_notes"), dataKey: "notes" });
  }

  if (includeNotes) {
    headers.push({ header: t("export:alert_protocol"), dataKey: "protocol" });
  }

  const parsedHistoricalData = historicalData
    .filter((alert) => sensorParams.some((item) => item.id === alert.Sensor?._id))
    .map((alert) => {
      let value =
        String(
          measurementTransform(alert.value.value, {
            unit: alert.Sensor?.default_unit || "degF",
            empirical: alert.Sensor?.is_imperial,
            type: alert.Sensor?.Sensor_type.type,
          }),
        ) +
        unitsTransform(alert.Sensor?.default_unit || "degF", [
          alert.Sensor?.default_unit || "degF",
          alert.Sensor?.is_imperial,
          alert.Sensor?.Sensor_type.type,
        ]);

      let alertName = "";

      if (alert.Alert) {
        alertName = alertConditionTransform(alert.Alert);

        if (!isGenericAlert(alert.Alert)) {
          alertName = alert.Alert.name + " - " + alertName;
        }
      }

      const alertCondition = alert.Alert ? alertConditionTransform(alert.Alert) : "";
      const placement = alert.Sensor?.Device?.location_note || "--";
      const serialNumber = alert.Sensor?.Device?.serial_number || "";
      const lastUpdateAt = moment(alert.updatedAt).format(`${DateFormat.MMDDYYYY} hh:mm A`);
      const isResolved = alert.is_resolved ? t("common:yes") : t("common:no");
      const notes =
        includeNotes && sensorParams.some((p) => p.include_alert_notes && p.id === alert.Sensor?._id) ? alert.resolved_notes : "";
      const protocol =
        includeProtocol && sensorParams.some((p) => p.include_alert_protocol && p.id === alert.Sensor?._id) ? alert.Alert.protocol : "";

      const params = [value, alertName, alertCondition, placement, serialNumber, lastUpdateAt, isResolved];

      if (includeNotes) {
        params.push(notes ?? "");
      }
      if (includeProtocol) {
        params.push(protocol ?? "");
      }
      return params;
    });

  return { historicalHeaders: headers, historicalData: parsedHistoricalData };
};

export const getSensorType = (device: IDevice, t: TFunction) => {
  const { convertToRh, convertToTemp } = getConverts(device);
  if (convertToTemp) {
    return t("export:temperature");
  } else if (convertToRh) {
    return t("export:humidity");
  }
  return null;
};

export const getConverts = (device: IDevice) => {
  const isWai418 = checkForWai418Devices(device.serial_number);

  if (isWai418 && device?.serial_number) {
    if (checkForWai418TemperatureDevice(device.serial_number)) {
      return { convertToTemp: true, convertToRh: false };
    } else if (checkForWai418HumidityDevice(device.serial_number)) {
      return { convertToTemp: false, convertToRh: true };
    } else {
      return { convertToTemp: false, convertToRh: false };
    }
  }
  return { convertToTemp: false, convertToRh: false };
};

export const renderHeaderFooterPDF = (doc: any, device: IDevice, t: TFunction, lotCode?: string, productCode?: string) => {
  doc.setFontSize(14);
  doc.setTextColor(0, 0, 0);
  // TODO: The old system did repeat this field 3x
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.text(AppState.selectedLocation?.name || "", 40, 50);
  doc.setFontSize(8);

  let subtitle = [];
  let address = AppState.selectedLocation?.address !== "please enter an address" ? AppState.selectedLocation?.address : null;
  let deviceLocation =
    address &&
    AppState.selectedLocation?.city &&
    AppState.selectedLocation?.state &&
    AppState.selectedLocation?.country &&
    AppState.selectedLocation?.zip
      ? address +
        ", " +
        AppState.selectedLocation?.city +
        ", " +
        AppState.selectedLocation?.state +
        ", " +
        AppState.selectedLocation?.country +
        " " +
        AppState.selectedLocation?.zip
      : null;
  if (deviceLocation) {
    subtitle.push(deviceLocation);
  }
  let devicePhone = AppState.selectedLocation?.phone !== "please enter a phone number" ? AppState.selectedLocation?.phone : null;
  if (devicePhone) {
    subtitle.push(devicePhone);
  }
  doc.text(subtitle.join(" | "), 40, 63);

  doc.setDrawColor(240, 240, 240);
  doc.setLineWidth(2);
  doc.line(40, 68, 550, 68);
  doc.setLineWidth(0.01);
  doc.line(40, 95, 550, 95);

  //devices
  doc.setFontSize(7);
  doc.setTextColor(180, 180, 180);
  doc.text(t("export:device_name"), 40, 78);
  doc.text(t("export:product_code"), 210, 78);
  doc.text(t("export:serial_number"), 300, 78);
  doc.text(t("export:lot_code"), 400, 78);

  doc.setFontSize(9);
  doc.setTextColor(100, 100, 100);
  doc.text(device.name || "n/a", 40, 90);
  doc.text(productCode ?? "n/a", 210, 90);
  doc.text(device.serial_number || "n/a", 300, 90);
  doc.text(lotCode ?? "n/a", 400, 90);

  doc.text(formatDateCustom(new Date(), getUserDateFormat()), doc.internal.pageSize.width - 100, 40);

  //footer
  doc.setFontSize(12);
  doc.setTextColor(160, 160, 160);
  doc.text(t("export:wireless_monitoring"), 40, doc.internal.pageSize.height - 50);
  doc.setFontSize(9);
  doc.setTextColor(200, 200, 200);
  doc.text(t("export:footer").replace("<--URL-->", window.origin), 40, doc.internal.pageSize.height - 40);
};

export const processIntervalAndAverage = (
  data: ILineChartValue[],
  average: number,
  interval: number,
  defaultIntervalOptionValue: number,
  possibleIntervalStart: Date,
  possibleAverageStart: Date,
) => {
  let dataToFilter = data.slice();

  if (interval !== defaultIntervalOptionValue && dataToFilter.length) {
    let startTime = possibleIntervalStart.valueOf();
    const reducedData: ILineChartValue[] = [];
    let i = 0;

    do {
      const item = dataToFilter.find((d) => d.x.valueOf() === startTime + i * interval * 1000);
      if (item) {
        reducedData.push(item);
      }
      i++;
    } while (startTime + i * interval * 1000 <= dataToFilter[dataToFilter.length - 1].x.valueOf());

    dataToFilter = reducedData;
  }

  if (average !== -1 && dataToFilter.length) {
    const averagedData: ILineChartValue[] = [];
    let dataToAverage: ILineChartValue[] = [];
    let startTime = dataToFilter[0]?.x.valueOf();
    const isBoolean = dataToFilter[0].unit.includes("/");
    const averageInMilliseconds = average * 3_600 * 1_000;

    dataToFilter.forEach((d) => {
      if (d.x.valueOf() - startTime < average * 3_600 * 1_000) {
        dataToAverage.push(d);
      } else if (dataToAverage.length) {
        const value =
          dataToAverage.reduce((a, b) => {
            if (typeof b.y === "number") {
              return a + b.y;
            } else if (!isNaN(Number(b.y))) {
              return a + Number(b.y);
            } else if (isBoolean && b.y) {
              return (
                a +
                b.unit
                  .split("/")
                  .map((u) => u.toUpperCase())
                  .indexOf(b.y.toUpperCase())
              );
            }
            return 0;
          }, 0) / dataToAverage.length;

        averagedData.push({
          x: new Date(startTime + (1 / 2) * averageInMilliseconds),
          y:
            isBoolean && Math.round(value) === 0
              ? dataToAverage[0].unit.split("/")[0].toUpperCase()
              : isBoolean && Math.round(value) === 1
              ? dataToAverage[0].unit.split("/")[1].toUpperCase()
              : value,
          unit: dataToAverage[0].unit,
          triggeredTarget: dataToAverage[0].triggeredTarget,
        });
        dataToAverage = [d];
        startTime = startTime + averageInMilliseconds;
      }
    });

    dataToFilter = averagedData;
  }

  return dataToFilter;
};

export const readingUOMString = (device: IDevice, sensor: ISensor) => {
  const { convertToTemp, convertToRh } = getConverts(device);

  return sensor
    ? " (" +
        unitsTransform(sensor?.default_unit, [
          sensor?.default_unit,
          sensor?.is_imperial,
          sensor?.Sensor_type.type,
          convertToRh,
          convertToTemp,
        ]) +
        ")"
    : "";
};

// Transform a dataset that may be "missing" some data values to be more "chartable". We do the following:
//
//  1. Unit conversion based on device/sensor settings
//
//  2. Simplify the data set to simple x,y values for charting
//
//  3. Sort the set just in case the server doesn't do this for us. It's supposed to, but we would break on the next
//     step if that ever failed so we do it here anyway to be safe.
//
//  4. We're supposed to show "gaps" where there's missing data, instead of connecting lines across missed data. The
//     server doesn't give us nulls for those missed items, which the chart needs. We insert placeholders, BUT...
//
//  5. The server also doesn't tell us a reporting interval for a device. We need to identify and insert nulls for the
//     chart to show gaps but we have to know where. So.. we guess. We find the shortest possible gap between two items
//     and assume that's the reporting interval. We then insert nulls at that interval in between items to create those
//     gaps. We insert as many nulls as we can because the chart is zoomable and it would be weird if you zoomed across
//     a big gap if we didn't.
//
// {_id: '60', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:05:00.000Z'}
// {_id: '61', SensorId: 2873, value: { value: 99.54 }, createdAt: '2022-03-02T08:15:00.000Z'}
// {_id: '62', SensorId: 2873, value: { value: 99.56 }, createdAt: '2022-03-02T08:20:00.000Z'}
// {_id: '63', SensorId: 2873, value: { value: 99.55 }, createdAt: '2022-03-02T08:25:00.000Z'}
//
// TODO: If we wanted to add a test suite this would be a great first thing to add tests for.
//
// NOTE: There are some more efficient approaches e.g. with array.splice() that could eliminate the second sort, that
// sort of thing. But before you waste tie here... I did a timer pass on a month's worth of data thrown in here and
// this took 8.6ms to process 8124 data points. It takes <1ms to process a more reasonable "month's worth" of data. So...
//
export const prepareChartDataSet = (
  data: ISensorDatapoint[],
  sensor: ISensor,
  isImperial: boolean = true,
  convertToHumidityForWai418: boolean = false,
  convertToTempForWai418: boolean = false,
): ILineChartValue[] => {
  const unit = Units.transform(sensor.default_unit, [
    sensor.default_unit,
    isImperial,
    sensor.Sensor_type.type,
    convertToHumidityForWai418,
    convertToTempForWai418,
  ]);
  // console.log('pcd, unit=', unit);
  const disableRoundingRules = false;
  console.log("convertToHumidityForWai418", convertToHumidityForWai418);
  console.log("convertToTempForWai418", convertToTempForWai418);
  let simplifiedSet: ILineChartValue[] = data.map((entry) => ({
    triggeredTarget: null,
    unit,
    x: new Date(entry.createdAt),
    y: !!entry.value
      ? Measurements.transform(entry.value.value, {
          unit: sensor.default_unit,
          empirical: isImperial,
          type: sensor.Sensor_type.type,
          disableRounding: disableRoundingRules,
          convertToHumidity: convertToHumidityForWai418,
          convertToTemperature: convertToTempForWai418,
        })
      : null,
  }));
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());
  // console.log('pcd, set=', simplifiedSet);

  // Time to get all old school. Hey, I had a 5-hr timebox for all this plus the charting...
  // let smallestGap = Infinity;
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   const diff = (next.x as Date).getTime() - (curr.x as Date).getTime();
  //   if (diff < smallestGap) {
  //     // console.log('New smallest', curr.x, next.x);
  //     smallestGap = diff;
  //   }
  // }
  // // console.log('pcd, gap=', smallestGap);

  // // Sanity check to prevent us from trying to ever draw 500,000 (it happened) data points on a chart because we had 1-second reporting
  // // intervals. Gap-filling to 1 minute does enough without getting too crazy.
  // if (smallestGap < 60000) {
  //   smallestGap = 60000;
  // }

  // // I could have gone crazy with splice() but it would have been super hard to debug and array iteration and sorting in
  // // JS is actually really fast. Don't forget a simple for() loop with a splice-in-place would have had to modify its
  // // increment to account for the new items, and it gets weird stepping through that...
  // const entriesToAdd: ILineChartValue[] = [];
  // for (let i = 0; i < simplifiedSet.length - 1; i++) {
  //   const curr = simplifiedSet[i];
  //   const next = simplifiedSet[i + 1];
  //   let diff = (next.x as Date).getTime() - (curr.x as Date).getTime();

  //   let offset = smallestGap;
  //   while (diff > smallestGap) {
  //     entriesToAdd.push({ x: new Date((curr.x as Date).getTime() + offset), y: null, unit });
  //     diff -= smallestGap;
  //     offset += smallestGap;
  //   }
  // }

  // One last hurrah...
  // simplifiedSet = [...simplifiedSet, ...entriesToAdd];
  simplifiedSet.sort((a, b) => (a.x as Date).getTime() - (b.x as Date).getTime());
  return simplifiedSet;
};

export const getExportFilters = (deviceTypes: IDeviceType[], sensorTypes: ISensorType[], t: TFunction) =>
  [
    {
      key: "device_type",
      options: deviceTypes.map((g) => ({
        value: g._id,
        label: g.name,
      })),
    },
    {
      key: "device_status",
      options: [
        { value: true, label: t("common:online") },
        { value: false, label: t("common:offline") },
      ],
      checkboxMobile: true,
    },
    {
      key: "sensor_type",
      options: sensorTypes.map((s) => {
        const name = t(`sensor_types:${getNameSlug(s.name)}`);
        return { value: s.name, label: name };
      }),
    },
    {
      key: "sample_interval",
      options: getIntervalOptions(t),
    },
    {
      key: "transmit_interval",
      options: getTransmitIntervalOptions(t),
    },
    {
      key: "approaching",
      options: [
        { value: true, label: t("common:yes") },
        { value: false, label: t("common:no") },
      ],
      checkboxMobile: true,
    },
    {
      key: "in_alert",
      options: [
        { value: true, label: t("common:yes") },
        { value: false, label: t("common:no") },
      ],
      checkboxMobile: true,
    },
  ].map((item) => ({ ...item, key: item.key as keyof IExportFilter }));
