import React, { useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { XIcon } from "@heroicons/react/solid";
import {
  FormFieldCheckbox,
  FormFieldSelect,
  FormFieldText,
  FormFieldTextArea,
  ICON_TOOLTIP_DEFAULT_DELAY,
  InputLabel,
  InputRangeSlider,
  StyledTooltip,
} from "../../../Components";
import { IDevice, IDeviceBond, IDeviceSettings, ISensorSettingsData, ISettingsData } from "../../../Managers/Types";
import { getGateway, setDevice, showAppModal, showSnackbar } from "../../../AppState";
import * as DeviceService from "../../../Managers/DeviceService";
import { filterIntervalOptions, getTransmitIntervalOptions } from "../../../Managers/DeviceService";
import * as DeviceSettingsService from "../../../Managers/DeviceSettingsService";
import { ConfirmModal } from "../../../Modals";
import classNames from "classnames";
import "./DevicePropertiesModal.scss";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "../../../icon/trash";
import { unitsTransform } from "../../../Managers/UnitsService";
import { convertTemperature } from "../../../Managers/MeasurementService";

interface IDevicePropertiesModal {
  device: IDevice;
  refresh: () => void;
}

interface IDevicePropertiesBond extends IDeviceBond {
  name: string;
}

// NOTE: The styling in here is all inline because we're working off a screenshot of an xD sketch that was just an experiment.

export const DevicePropertiesModal: React.FC<IDevicePropertiesModal> = observer(({ device, refresh }) => {
  const [deviceBonds, setDeviceBonds] = useState<IDevicePropertiesBond[]>([]);
  const [isImperial, setIsImperial] = useState<boolean>(device.is_empirical);
  const [, setHasTempSensor] = useState<Boolean>(false);
  const [, setHasHumiditySensor] = useState<Boolean>(false);
  const [tempMin, setTempMin] = useState<number>(0);
  const [tempMax, setTempMax] = useState<number>(100);
  const [batteryAlarm, setBatteryAlarm] = useState<boolean>(device.battery_alert_enabled);
  const [notTransmitting, setNotTransmitting] = useState<boolean>(device.not_transmitting_alert_enabled);

  const { t } = useTranslation(["dashboard", "common"]);

  const getOptions = useCallback(
    (items: number[], suffix: string) =>
      items.map((value) => ({
        value,
        label: `${value} ${suffix}`,
      })),
    [],
  );

  const offOption = { value: 0, label: t("common:off") };

  const options = {
    transmitInterval: getTransmitIntervalOptions(t),
    sampleInterval: filterIntervalOptions([
      { value: 60, label: t("common:minutes", { count: 1 }) },
      { value: 5 * 60, label: t("common:minutes", { count: 5 }) },
    ]),
    temperature: [offOption, ...getOptions([0.1, 0.2, 0.5, 1, 2, 3, 4, 5], "°F/C")],
    humidity: [offOption, ...getOptions([0.5, 1, 2, 3, 4, 5], "RH")],
    tempOffset: [
      ...getOptions([-5, -4, -3, -2, -1, -0.5, -0.2, -0.1], "°F/C"),
      offOption,
      ...getOptions([0.1, 0.2, 0.5, 1, 2, 3, 4, 5], "°F/C"),
    ],
    humidityOffset: [...getOptions([-3, -2, -1, -0.5], "RH"), offOption, ...getOptions([0.5, 1, 2, 3], "RH")],
  };

  useEffect(() => {
    const from = isImperial ? "degC" : "degF";
    const to = isImperial ? "degF" : "degC";

    setTempMin(Math.round(Math.max(convertTemperature(tempMin, from, to), -40)));
    setTempMax(Math.round(Math.min(convertTemperature(tempMax, from, to), !isImperial ? 85 : 185)));
  }, [isImperial]);

  useEffect(() => {
    DeviceService.getDeviceBonds(device._id).then((data) => {
      const deviceBonds: IDevicePropertiesBond[] = [];
      data.forEach((bond) => {
        const gateway = getGateway(bond.GatewayId);
        if (gateway) {
          deviceBonds.push({ ...bond, name: gateway.name });
        }
      });
      setDeviceBonds(deviceBonds);
    });

    device.Sensors.forEach((s) => {
      if (s.default_unit === "degC" || s.default_unit === "degF") {
        setHasTempSensor(true);
        const tempSensorSettings =
          device.Device_setting != null ? device.Device_setting.settings_data.sensorSettings.find((s) => s.sensorType === 1) : null;

        let data = {
          min: -40,
          max: !isImperial ? 85 : 185,
        };

        setTempMin(
          tempSensorSettings
            ? Math.round(convertTemperature(Number(tempSensorSettings.minimumValue), "degF", isImperial ? "degF" : "degC") / 100)
            : data.min,
        );
        setTempMax(
          tempSensorSettings
            ? Math.round(convertTemperature(Number(tempSensorSettings.maximumValue), "degF", isImperial ? "degF" : "degC") / 100)
            : data.max,
        );
      }

      if (s.default_unit === "RH") {
        setHasHumiditySensor(true);
      }
    });
  }, []);

  const handleTempChange = (min: number | null, max: number | null) => {
    if (min) setTempMin(min);
    if (max) setTempMax(max);
  };

  const showDevicePropertiesModal = () => showAppModal(<DevicePropertiesModal device={device} refresh={refresh} />);

  const deleteDeviceBond = (bond: IDevicePropertiesBond) => {
    const { name: _name, ...rest } = bond;

    DeviceService.deleteDeviceBond(rest)
      .then(() => {
        showAppModal(null);
        refresh();
        showSnackbar(t("dashboard:device_properties.remove_bond_success"), "success");
      })
      .catch((e) => {
        showSnackbar(t("dashboard:device_properties.remove_bond_error"), "error");
        console.error(e);
      });
  };

  const handleSubmit = async (values: any) => {
    let updateDevice = Object.assign({}, device, values);

    let deviceSettingsData: ISettingsData = {
      lowBattery: values["lowBattery"],
      sampleInterval: values["sampleInterval"],
      transmitInterval: values["transmitInterval"],
      temperature: values["temperature"] * 100,
      humidity: values["humidity"] * 100,
      tempOffset: values["tempOffset"] * 100,
      humidityOffset: values["humidityOffset"] * 100,
      sensorSettings: [] as any[],
    };

    device.Sensors.forEach((s) => {
      const sensorType = DeviceSettingsService.transformSensorTypeId(s.SensorTypeId);
      // if the device sensor is not able to be mapped, do not add it to the device settings.
      if (sensorType == -1) {
        return;
      }

      const setting = {
        sensorType,
      } as ISensorSettingsData;

      setting.minimumValue = s.Sensor_type.ranges[s.Sensor_type.units[0]][0] * 100;
      setting.maximumValue = s.Sensor_type.ranges[s.Sensor_type.units[0]][1] * 100;

      if (sensorType === 1) {
        setting.deltaT = deviceSettingsData.temperature;
        setting.calibrationOffset = deviceSettingsData.tempOffset;
        setting.maximumValue = tempMax ? convertTemperature(tempMax * 100, isImperial ? "degF" : "degC", "degF") : 0;
        setting.minimumValue = tempMin ? convertTemperature(tempMin * 100, isImperial ? "degF" : "degC", "degF") : 0;
      } else if (sensorType === 3) {
        setting.deltaT = deviceSettingsData.humidity;
        setting.calibrationOffset = deviceSettingsData.humidityOffset;
      }

      deviceSettingsData.sensorSettings.push(setting);
    });

    let updateDeviceSettings: IDeviceSettings;
    updateDeviceSettings = {
      _id: device._id,
      applied: true,
      applied_at: "",
      settings_crc: 0,
      settings_data: { ...deviceSettingsData },
      batteryAlarm,
      notTransmitting,
    };

    return DeviceSettingsService.updateDeviceSettings(device._id, updateDeviceSettings)
      .then((d) =>
        DeviceService.updateDevice({
          ...device,
          ...updateDevice,
          is_empirical: isImperial,
          DeviceSettingId: d.data.DeviceSettingId,
          battery_alert_enabled: batteryAlarm,
          not_transmitting_alert_enabled: notTransmitting,
        }),
      )
      .then(() => {
        console.log("after updating the device_setting api", updateDevice.Device_setting);
        setDevice({
          ...device,
          ...updateDevice,
          Device_setting: updateDeviceSettings,
          battery_alert_enabled: batteryAlarm,
          not_transmitting_alert_enabled: notTransmitting,
        });
        refresh();
        showAppModal(null);
        showSnackbar(t("dashboard:device_properties.update_success", { serialNumber: device?.serial_number }), "success");
      })
      .catch((e) => {
        console.error(e);
        showSnackbar(t("dashboard:device_properties.update_error", { serialNumber: device?.serial_number }), "error");
      });
  };

  const get_value_from_options = (value: any[]) => {
    // get the first value that is not null or not NaN
    return value.find((x) => x != null && !isNaN(x));
  };

  const initialSampleInterval = parseInt(device.Device_setting?.settings_data?.sampleInterval.toString());
  const initialTransmitInterval = parseInt(device.Device_setting?.settings_data?.transmitInterval.toString());

  let sampleInterval = options.sampleInterval.find((option) => option.value === initialSampleInterval)?.value;
  if (!sampleInterval) {
    sampleInterval = options.sampleInterval[0].value;
  }
  let transmitInterval = options.transmitInterval.find((option) => option.value === initialTransmitInterval)?.value;
  if (!transmitInterval) {
    transmitInterval = options.transmitInterval[0].value;
  }

  const getInitValue = (checkValue: number, options: number) => {
    if (checkValue === 0) {
      return 0;
    }
    return checkValue ? checkValue / 100 : options;
  };

  const initialValues = {
    isImperial,
    name: device?.name || "",
    location_note: device?.location_note || "",
    notes: device?.notes || "",
    serial_number: device?.serial_number || "",
    lowBattery: device.Device_setting?.settings_data?.lowBattery ?? "",
    sampleInterval,
    transmitInterval,
    temperature: getInitValue(
      device.Device_setting?.settings_data?.temperature,
      options.temperature.find((x) => x.value == 0.1)?.value ?? 0.1,
    ),
    humidity: getInitValue(device.Device_setting?.settings_data?.humidity, options.humidity.find((x) => x.value == 0.5)?.value ?? 0.5),
    tempOffset: get_value_from_options([
      device.Device_setting?.settings_data?.tempOffset / 100,
      options.tempOffset.find((x) => x.value == 0)?.value,
      0,
    ]),
    humidityOffset: get_value_from_options([
      device.Device_setting?.settings_data?.humidityOffset / 100,
      options.humidityOffset.find((x) => x.value == 0)?.value,
      0,
    ]),
  };

  const toggleBattery = useCallback(() => setBatteryAlarm(!batteryAlarm), [batteryAlarm]);

  const toggleNotTransmitting = useCallback(() => setNotTransmitting(!notTransmitting), [notTransmitting]);

  const getMeasurementOptions = useCallback(
    () =>
      [true, false].map((isImperial) => {
        const unit = isImperial ? "degF" : "degC";
        return {
          value: isImperial,
          label: unitsTransform(unit, [unit, isImperial]),
        };
      }),
    [isImperial],
  );

  return (
    <>
      <div className="modal" tabIndex={-1} role="dialog" style={{ opacity: 1, top: "10px" }}>
        <div className="modal-dialog device-properties-modal-dialog">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting, submitForm }) => {
              return (
                <div className="modal-content">
                  <div className="modal-header" style={{ display: "flex", flexDirection: "row" }}>
                    <h4 className="modal-title">
                      {!device ? t("dashboard:device_properties.create_title") : t("dashboard:device_properties.edit_title")}
                    </h4>
                    <div style={{ flex: 1 }} />
                    <button
                      onClick={() => showAppModal(null)}
                      className="u-mobile-hide"
                      style={{ background: "none", color: "white", border: "none" }}>
                      <XIcon style={{ width: "18px", marginBottom: "-4px" }} /> {t("common:close")}
                    </button>
                  </div>

                  <div className="modal-body device-properties-modal-body">
                    <div className="row u-full-width device-properties-modal-body-main-content">
                      <div className="col-xs-5 first-col">
                        <FormFieldText
                          maxlength={20}
                          name="name"
                          label={t("dashboard:device_properties.device_name")}
                          placeholder={t("dashboard:device_properties.device_name_placeholder")}
                          required
                          dark
                        />
                        <FormFieldText
                          name="location_note"
                          label={t("dashboard:device_properties.device_location")}
                          placeholder={t("dashboard:device_properties.device_location_placeholder")}
                          dark
                        />
                        <div id="device-properties-image-container">
                          <label className="input-label u-display-block">{t("dashboard:device_properties.image")}</label>
                          <img id="device-properties-image" src={`/assets/img/${device?.Device_type?.image_file}`} alt="" />
                        </div>
                        <FormFieldTextArea
                          className="u-mobile-hide"
                          name="notes"
                          label={t("dashboard:device_properties.notes")}
                          placeholder=""
                        />
                      </div>
                      <div className="col-xs-7">
                        <FormFieldText name="serial_number" label={t("dashboard:device_properties.device_serial_number")} disabled />
                        <div className="device-properties-dropdowns-container">
                          <FormFieldSelect
                            options={options.sampleInterval}
                            name="sampleInterval"
                            label={t("dashboard:device_properties.sample_interval")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />
                          <FormFieldSelect
                            options={options.transmitInterval}
                            name="transmitInterval"
                            label={t("dashboard:device_properties.transmit_interval")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />
                          <FormFieldSelect
                            options={options.temperature}
                            name="temperature"
                            label={t("dashboard:device_properties.temperature")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />
                          <FormFieldSelect
                            options={options.humidity}
                            name="humidity"
                            label={t("dashboard:device_properties.humidity")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />
                          <FormFieldSelect
                            options={options.tempOffset}
                            name="tempOffset"
                            label={t("dashboard:device_properties.temperature_offset")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />

                          <FormFieldSelect
                            options={options.humidityOffset}
                            name="humidityOffset"
                            label={t("dashboard:device_properties.humidity_offset")}
                            layout="horizontal"
                            className="device-properties-dropdown"
                            inputClassName="device-properties-dropdown-input"
                          />
                          <FormFieldSelect
                              options={getMeasurementOptions()}
                              name="isImperial"
                              layout="horizontal"
                              className="device-properties-dropdown"
                              inputClassName="device-properties-dropdown-input"
                              label={t("dashboard:device_properties.temperature_delta_min_max")}
                              onChange={(imperial) => setIsImperial(imperial)}
                          />
                        </div>
                        <div className="row">
                          <InputRangeSlider
                            className="col-xs-12 col-sm-12"
                            type="range"
                            valueMin={tempMin}
                            valueMax={tempMax}
                            min={-40}
                            max={!isImperial ? 85 : 185}
                            readonly={false}
                            onMinValueChange={(value) => handleTempChange(value || null, null)}
                            onMaxValueChange={(value) => handleTempChange(null, value || null)}
                          />
                        </div>
                        <FormFieldTextArea
                          className="u-mobile-only"
                          name="notes"
                          label={t("dashboard:device_properties.notes")}
                          placeholder=""
                        />
                        <div className={"bottom-line"} />
                        <InputLabel name={"alarm"} label={t("common:alerts")} />
                        <FormFieldCheckbox
                          label={t("dashboard:device_properties.low_battery")}
                          checked={batteryAlarm}
                          name={"lowBatteryAlarm"}
                          onChange={toggleBattery}
                        />
                        <FormFieldCheckbox
                          label={t("dashboard:device_properties.not_transmitting")}
                          checked={notTransmitting}
                          name={"notTransmittingAlarm"}
                          onChange={toggleNotTransmitting}
                        />
                      </div>
                    </div>
                    <div className="row u-full-width">
                      <div id="device-properties-bonds-container" className="col-xs-8">
                        {deviceBonds.length > 0 ? (
                          <>
                            <label className="input-label" htmlFor="">
                              {t("dashboard:device_properties.bonds")}
                            </label>
                            {deviceBonds.map((bond, index) => (
                              <div className="row" key={index}>
                                <div className="col-xs-2">
                                  <StyledTooltip
                                    title={t("dashboard:device_properties.remove_bond")}
                                    enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                                    <button
                                      className="btn btn-icon"
                                      onClick={() => {
                                        showAppModal(
                                          <ConfirmModal
                                            header={t("dashboard:device_properties.remove_device_bond")}
                                            children={<p>{t("dashboard:device_properties.remove_bond_confirm", { name: bond.name })}</p>}
                                            confirmText={t("dashboard:device_properties.remove_device_bond")}
                                            onConfirm={() => deleteDeviceBond(bond)}
                                            onCancel={() => showDevicePropertiesModal()}
                                          />,
                                        );
                                      }}>
                                      <i className="fa fa-trash u-mobile-hide" />
                                      <TrashIcon className="u-mobile-only u-text-teal" />
                                      <span className="sr-only">{t("common:delete")}</span>
                                    </button>
                                  </StyledTooltip>
                                </div>
                                <div className="col-xs-6">
                                  <p>{bond.name}</p>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer device-properties-modal-footer">
                    <button
                      onClick={() => showAppModal(null)}
                      className={classNames("btn btn-info u-mobile-only", { disabled: isSubmitting })}>
                      {t("common:cancel")}
                    </button>
                    <button className={classNames("btn btn-primary", { disabled: isSubmitting })} onClick={submitForm}>
                      {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                      {t("common:save")}
                    </button>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
});
