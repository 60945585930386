import { observer } from "mobx-react-lite";

export const DrawerIcon: React.FC = observer(() => (
  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.85" y="0.85" width="17.3" height="15.3" rx="2.15" stroke="#04CCA0" strokeWidth={1.7} />
    <rect x="8" y="1" width="1.7" height="15" fill="#04CCA0" />
    <rect x="3" y="3" width="4" height="1.7" fill="#04CCA0" />
    <rect x="3" y="6" width="4" height="1.7" fill="#04CCA0" />
    <rect x="3" y="9" width="4" height="1.7" fill="#04CCA0" />
  </svg>
));
