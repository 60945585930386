import { CountrySelect, FormFieldCheckbox, FormFieldSelect, FormFieldText, IFormStepProps, StepperActions } from "../../Components";
import { Formik, FormikErrors, FormikTouched } from "formik";
import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import "./AccountStep.scss";
import { PhoneInput } from "../../Components/PhoneInput";
import { ICountry } from "../../Managers";
import { showAppModal } from "../../AppState";
import { LegalModal } from "./Legal";
import { PasswordInput } from "../../Components/PasswordInput";
import { ICurrency } from "../../Managers/CurrencyService";
import { getCurrenciesForDropdown } from "../../Managers/PaymentOptionsService";
import { CurrencySelect } from "../../Components/CurrencySelect";
import { ValidationMessages } from "../../Enums";
import { useTranslation } from "react-i18next";
import { ILanguage } from "../../Managers/Types";
import { useLanguages } from "../../Managers/LanguageService";
import { DEFAULT_LANGUAGE } from "../../i18n";

export interface IAccountProps {
  country?: ICountry;
  firstName?: string;
  lastName?: string;
  address?: string;
  billing_id?: string;
  city?: string;
  zip?: string;
  state?: string;
  title?: string;
  phone?: string;
  emailAddress?: string;
  companyName?: string;
  password?: string;
  confirmPassword?: string;
  marketingChecked?: boolean;
  language: string;
  currency?: ICurrency;
  phoneCountry?: ICountry;
  secondaryEmailAddress?: string;
  secondaryPhone?: string;
  secondaryPhoneCountry?: ICountry;
}

interface IAccountStepProps extends IFormStepProps {
  accountDetails?: IAccountProps;
  countries: ICountry[];
}

export const AccountStep: React.FC<IAccountStepProps> = ({ accountDetails, onClickBack, onSubmit, countries }) => {
  const { t } = useTranslation(["register", "common"]);
  const languageQuery = useLanguages();
  const UnitedStatesId = 230;

  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(countries.find((c: ICountry) => c.code === "US"));
  const [activeCurrencies, setActiveCurrencies] = useState<ICurrency[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | undefined>(undefined);
  const [countryCode, setCountryCode] = useState<string>();
  const [capsLockOn, setCapsLockOn] = useState(false);

  useEffect(() => {
    setLanguages(languageQuery.data?.filter((l) => l.active) ?? []);
  }, [languageQuery.dataUpdatedAt]);

  const fetchCurrencies = useCallback(
    async (
      country?: ICountry,
      setFieldValue?: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => Promise<void | FormikErrors<IAccountProps>> | void,
    ) => {
      try {
        const currencies = await getCurrenciesForDropdown(true, country?._id ?? UnitedStatesId);
        setActiveCurrencies(currencies);
        const defaultCurrency = currencies.find((a) => a._id === country?.DefaultCurrencyId) ?? currencies[0];
        setSelectedCurrency(defaultCurrency);
        if (setFieldValue) {
          setFieldValue("currency", defaultCurrency);
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    },
    [],
  );

  useEffect(() => {
    fetchCurrencies(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCurrency) {
      initialValues.currency = selectedCurrency;
    }
  }, [selectedCurrency]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const caps = event.getModifierState("CapsLock");
    if (caps && !capsLockOn) {
      setCapsLockOn(true);
    } else if (!caps && capsLockOn) {
      setCapsLockOn(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem("termsChecked");
      sessionStorage.removeItem("privacyChecked");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const initialValues: IAccountProps = {
    country: accountDetails?.country ?? countries.find((c) => c.code === "US"),
    confirmPassword: accountDetails?.confirmPassword ?? "",
    emailAddress: accountDetails?.emailAddress ?? "",
    firstName: accountDetails?.firstName ?? "",
    companyName: accountDetails?.companyName ?? "",
    address: accountDetails?.address ?? "",
    billing_id: accountDetails?.billing_id ?? "",
    city: accountDetails?.city ?? "",
    zip: accountDetails?.zip ?? "",
    state: accountDetails?.state ?? "",
    lastName: accountDetails?.lastName ?? "",
    password: accountDetails?.password ?? "",
    phone: accountDetails?.phone ?? "",
    title: accountDetails?.title ?? "",
    language: accountDetails?.language ?? DEFAULT_LANGUAGE,
    currency: accountDetails?.currency ?? activeCurrencies.find((currency) => currency.iso_code === "USD"),
    phoneCountry: accountDetails?.country ?? countries.find((c) => c.code === "US"),
    secondaryEmailAddress: accountDetails?.secondaryEmailAddress ?? "",
    secondaryPhone: accountDetails?.secondaryPhone ?? "",
  };

  const validationSchema = yup.object({
    country: yup.object(),
    confirmPassword: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .test("passwords-match", t("register:steps.account.password_match_error"), function (value) {
        return this.parent.password === value;
      }),
    billing_id: yup.string(),
    // .required(t(ValidationMessages.REQUIRED)), // todo: this is temporary hidden - see task #44751
    address: yup.string().required(t(ValidationMessages.REQUIRED)),
    city: yup.string().required(t(ValidationMessages.REQUIRED)),
    zip: yup.string().required(t(ValidationMessages.REQUIRED)),
    state: yup.string().required(t(ValidationMessages.REQUIRED)),
    emailAddress: yup.string().email(t(ValidationMessages.EMAIL)).required(t(ValidationMessages.REQUIRED)),
    firstName: yup.string().required(t(ValidationMessages.REQUIRED)),
    lastName: yup.string().required(t(ValidationMessages.REQUIRED)),
    companyName: yup.string().required(t(ValidationMessages.REQUIRED)),
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*[a-z])/)
      .matches(/^(?=.*[A-Z])/)
      .matches(/^(?=.*[0-9])/)
      .matches(/^(?=.*[!@#$%^&*=()_+[\]{}|;:',.<>?/`~"\\-])/)
      .required(),
    phone: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
    marketingChecked: yup.boolean().oneOf([true, false]),
    title: yup.string(),
    language: yup.string(),
    currency: yup.object(),
    secondaryEmailAddress: yup.string().email(t(ValidationMessages.EMAIL)),
    secondaryPhone: yup.string().matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
  });

  const handleNextButtonClick = (handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void, country: ICountry | undefined) => {
    const attemptSubmit = async () => {
      const privacyChecked = sessionStorage.getItem("privacyChecked") === "true";
      const termsChecked = sessionStorage.getItem("termsChecked") === "true";
      if (termsChecked && privacyChecked) {
        handleSubmit();
      }
    };

    const showPrivacyModal = () => {
      setTimeout(() => {
        showAppModal(
          <LegalModal
            countryCode={country?.code}
            type="privacy"
            isUserOutsideUS={country?.code !== "US"}
            onAccept={async () => {
              sessionStorage.setItem("privacyChecked", "true");
              attemptSubmit();
            }}
          />,
        );
      }, 100);
    };

    if (!sessionStorage.getItem("termsChecked")) {
      showAppModal(
        <LegalModal
          countryCode={country?.code}
          type="terms"
          onAccept={async () => {
            sessionStorage.setItem("termsChecked", "true");

            if (sessionStorage.getItem("privacyChecked") === "true") {
              attemptSubmit();
            } else {
              showPrivacyModal();
            }
          }}
        />,
      );
    } else if (sessionStorage.getItem("privacyChecked") !== "true") {
      showPrivacyModal();
    } else {
      attemptSubmit();
    }
  };

  const setAllFieldsTouched = (
    setTouched: (touched: FormikTouched<IAccountProps>, shouldValidate?: boolean) => void,
    initialValues: IAccountProps,
  ) => {
    const touchedFields: FormikTouched<IAccountProps> = {};
    for (let key in initialValues) {
      touchedFields[key as keyof IAccountProps] = true;
    }
    setTouched(touchedFields);
  };

  const setCurrentCountry = useCallback(
    async (
      country: ICountry,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => Promise<void | FormikErrors<IAccountProps>> | void,
    ) => {
      setSelectedCountry(country);
      setCountryCode(country.code);
      setFieldValue("country", country);
      setFieldValue("phoneCountry", country);
      await fetchCurrencies(country, setFieldValue);
    },
    [],
  );

  const getHint = useCallback(
    () =>
      capsLockOn
        ? {
            className: "u-color-warning",
            message: t("common:errors.caps_lock"),
          }
        : undefined,
    [capsLockOn],
  );

  return (
    <Formik
      key={countries.length}
      validateOnMount={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ handleSubmit, validateForm, values, touched, setFieldValue, setTouched, setErrors }) => {
        if (!values.currency && selectedCurrency) {
          setFieldValue("currency", selectedCurrency);
        }
        return (
          <>
            <div className="type-large-regular register-form-label">{t("register:steps.account.title")}</div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <FormFieldText
                required={true}
                displayError={true}
                name="firstName"
                className="col-sm-4"
                label={t("register:steps.account.first_name")}
                type="text"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="lastName"
                className="col-sm-4"
                label={t("register:steps.account.last_name")}
                type="text"
              />
              <FormFieldText
                displayError={true}
                name="title"
                className="col-sm-4"
                label={t("register:steps.account.account_title")}
                type="text"
              />
            </div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <PhoneInput
                onChange={(e) => {
                  setFieldValue("phoneCountry", e);
                }}
                required={true}
                className="col-sm-4"
                name="phone"
                countryName="phoneCountry"
                label={t("register:steps.account.phone")}
                dataTestId="phone-input"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="emailAddress"
                className="col-sm-4"
                label={t("register:steps.account.email_address")}
                type="email"
                autoComplete="new-email"
              />
              <FormFieldText
                required={true}
                displayError={true}
                name="companyName"
                className="col-sm-4"
                label={t("register:steps.account.company_name")}
                type="text"
              />
            </div>

            <div className="row" style={{ marginBottom: "16px" }}>
              <PhoneInput
                onChange={(e) => {
                  setFieldValue("phoneCountry", e);
                }}
                className="col-sm-4"
                name="secondaryPhone"
                countryName="phoneCountry"
                label={t("register:steps.account.secondary_phone")}
                dataTestId="secondary-phone-input"
              />
              <FormFieldText
                displayError={true}
                name="secondaryEmailAddress"
                className="col-sm-4"
                label={t("register:steps.account.secondary_email_address")}
                type="email"
                autoComplete="secondary-email"
              />
            </div>

            <FormFieldCheckbox
              style={{ marginBottom: "1.5rem" }}
              onChange={() => setFieldValue("marketingChecked", !values.marketingChecked)}
              required={false}
              name="marketingChecked"
              checked={values.marketingChecked ?? false}
              label={t("register:steps.account.marketing")}
            />

            <div className="type-large-regular register-form-label">{t("register:steps.account.billing_info_title")}</div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <FormFieldText
                required={true}
                displayError={true}
                name="address"
                className="col-sm-4"
                label={t("register:steps.account.address")}
                type="text"
              />

              <FormFieldText
                className="col-sm-4"
                required={true}
                displayError={true}
                name="city"
                label={t("register:steps.account.city")}
                type="text"
              />

              <FormFieldText
                required={true}
                displayError={true}
                name="state"
                className="col-sm-4"
                label={t("register:steps.account.state")}
                type="text"
              />
            </div>
            <div className="row" style={{ marginBottom: "16px" }}>
              <FormFieldText
                required={true}
                displayError={true}
                name="zip"
                className="col-sm-4"
                label={t("register:steps.account.zip")}
                type="text"
              />

              <div className="col-sm-4">
                <label className="input-label u-display-block">{t("register:steps.account.country")}</label>
                <CountrySelect
                  name="country"
                  hidePhones={true}
                  value={selectedCountry}
                  formikControl={true}
                  selectedCountryLabel="name"
                  onChange={(value: ICountry) => {
                    setCurrentCountry(value, setFieldValue);
                  }}
                />
              </div>

              <FormFieldText
                required={true}
                displayError={true}
                name="billing_id"
                className="col-sm-4"
                style={{ display: "none" }} // todo: this is temporary hidden - see task #44751
                label={t("register:steps.account.billing_id")}
                type="text"
                tooltip={t("register:steps.account.billing_id_desc")}
              />
            </div>
            <div className="row">
              {activeCurrencies.length > 0 && selectedCurrency !== undefined && (
                <>
                  <CurrencySelect
                    required={true}
                    name="currency"
                    label={t("register:steps.account.currency_label")}
                    className="col-sm-4"
                    currencyList={activeCurrencies}
                    value={values.currency || selectedCurrency}
                    onChange={(value) => {
                      setSelectedCurrency(value);
                      setFieldValue("currency", value);
                    }}
                  />
                </>
              )}

              <FormFieldSelect
                className="col-sm-4"
                name="language"
                label={t("register:steps.account.language")}
                options={languages.map((l) => ({
                  label: t(`common:language.${l.code}`),
                  value: l.code,
                }))}
              />
            </div>

            <div className="type-large-regular register-form-label">{t("register:steps.account.create_password")}</div>
            <div className="row" style={{ marginBottom: "1rem" }}>
              <PasswordInput
                className="col-sm-4"
                hint={getHint()}
                onKeyDown={handleKeyDown}
                password={values.password}
                touched={!!touched.password}
              />
              <FormFieldText
                onKeyDown={handleKeyDown}
                required={true}
                className="col-sm-4"
                displayError={true}
                name="confirmPassword"
                label={t("register:steps.account.confirm_password")}
                type="password"
                hint={getHint()}
              />
            </div>
            <StepperActions
              onClickNext={async () => {
                const errors = await validateForm();
                setAllFieldsTouched(setTouched, initialValues);

                if (Object.keys(errors).length === 0) {
                  await handleNextButtonClick(handleSubmit, values.country);
                } else {
                  setErrors(errors);
                  console.log("Validation errors:", errors);
                }
              }}
              onClickBack={onClickBack}
            />
          </>
        );
      }}
    </Formik>
  );
};
