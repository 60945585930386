import { observer } from "mobx-react-lite";
import { FiltersIcon } from "../../icon";
import React, { useEffect, useState } from "react";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { AppState, showAppModal, setAppModalConfig, showChipModal, setChipModalConfig } from "../../AppState";
import { IDeviceType, IDeviceTypeGroup } from "../../Managers/Types";
import classNames from "classnames";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { DeviceSort, DeviceSortEnum, OrderBy } from "../../Enums/SortingTypes";
import { useDeviceTypeGroups } from "../../Managers/DeviceService";
import { DashboardFilters, IDashboardFilters } from "./DashboardFilters";
import { ISensorType } from "../../Managers/Alert.model";

const FilterModal: React.FC<{
  setOrderBy: (by: OrderBy) => void;
  setSortBy: (by: DeviceSort) => void;
}> = observer(({ setOrderBy, setSortBy }) => {
  const { t } = useTranslation("common");
  const [enclosureTypes, setEnclosureTypes] = useState<IDeviceTypeGroup[]>([]);
  const [sortBy, setSort] = useState(AppState.dashboardSorting.by);
  const [orderBy, setOrder] = useState(AppState.dashboardSorting.order);

  const deviceTypeGroupsQuery = useDeviceTypeGroups(
    AppState.selectedGateway?._id ? [AppState.selectedGateway?._id] : undefined,
    AppState.selectedLocation?._id,
  );

  useEffect(() => {
    deviceTypeGroupsQuery.refetch();
  }, [AppState.selectedGateway]);

  useEffect(() => {
    if (deviceTypeGroupsQuery.data) {
      setEnclosureTypes(deviceTypeGroupsQuery.data);
    }
  }, [deviceTypeGroupsQuery.dataUpdatedAt]);

  const handleApply = () => {
    AppState.dashboardSorting = {
      by: sortBy,
      order: orderBy,
    };
    setOrderBy(orderBy);
    setSortBy(sortBy);
    showAppModal(null);
  };

  return (
    <Modal
      className="mobile-dashboard-filters"
      title={t("common:filter")}
      buttons={
        <>
          <button className="btn btn-secondary" onClick={() => showAppModal(null)}>
            {t("common:close")}
          </button>
        </>
      }>
      <label className="input-label">{t("common:sort_by")}</label>
      <RadioGroup
        value={sortBy + "," + orderBy}
        onChange={(_e, value) => {
          const [sort, order] = value.split(",");
          setOrder(order as OrderBy);
          setSort(sort as DeviceSort);
        }}
        name="radio-buttons-group">
        {[
          ...[DeviceSortEnum.Updated, DeviceSortEnum.Name].map((prop) =>
            [OrderBy.ASC, OrderBy.DESC]
              .map((dir) => {
                const prefix = prop === DeviceSortEnum.Updated ? "updated" : "name";

                return (
                  <FormControlLabel
                    value={prop + "," + dir}
                    control={<Radio />}
                    label={t(`dashboard:sort_by.${prefix}`, { context: dir.toLowerCase() })}
                  />
                );
              })
              .flat(),
          ),
          <FormControlLabel
            value={DeviceSortEnum.Warning + "," + OrderBy.ASC}
            control={<Radio />}
            label={t("dashboard:sort_by.warning", { context: OrderBy.ASC.toLowerCase() })}
          />,
          <FormControlLabel
            value={DeviceSortEnum.Alert + "," + OrderBy.ASC}
            control={<Radio />}
            label={t("dashboard:sort_by.alert", { context: OrderBy.ASC.toLowerCase() })}
          />,

          ...enclosureTypes.map((g) => (
            <FormControlLabel
              value={g._id + "," + OrderBy.ASC}
              control={<Radio />}
              label={g.name + " - " + t("dashboard:sort_by.on_top")}
            />
          )),
        ]}
      </RadioGroup>
    </Modal>
  );
});

export interface IMobileDashboardFilters {
  deviceTypes: IDeviceType[];
  setOrderBy: (by: OrderBy) => void;
  setSortBy: (by: DeviceSort) => void;
  setChipLength: React.Dispatch<React.SetStateAction<any>>;
  setDeviceFilters: React.Dispatch<React.SetStateAction<any>>;
  sensorTypes: ISensorType[];
}

export const MobileDashboardFilters: React.FC<IMobileDashboardFilters> = observer(({ ...rest }) => {
  const deviceTypeGroupsQuery = useDeviceTypeGroups([AppState.selectedGateway?._id!], AppState.selectedLocation?._id);
  useEffect(() => {
    showChipModal(<DashboardFilters enclosureTypes={deviceTypeGroupsQuery.data ?? []} {...rest} />, { hidden: true });
  }, []);
  return (
    <button
      key={deviceTypeGroupsQuery.data?.length}
      className={classNames("btn btn-icon u-text-teal")}
      onClick={() => setChipModalConfig(null)}>
      <FiltersIcon />
    </button>
  );
});
