export enum DateFormat {
  MMDDYYYY = "MM/DD/YYYY",
  DDMMYYYY = "DD/MM/YYYY",
}

export const DateFormatDatepicker = {
  [DateFormat.MMDDYYYY]: "MM/dd/yyyy",
  [DateFormat.DDMMYYYY]: "dd/MM/yyyy",
};

export const UserDateFormats: DateFormat[] = Object.values(DateFormat);
