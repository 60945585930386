import { Endpoint, invalidateQuery } from "./API";

export interface ICountry {
  _id?: number;
  code: string;
  dial_code: string;
  main?: boolean;
  name: string;
  DefaultCurrencyId: number;
}

const API_PATH = "api/countries";

export const getCountries = (onlyMain = false) =>
  Endpoint.get<ICountry[]>(`${API_PATH}?onlyMain=${onlyMain}`).then((res) => res.data as ICountry[]);

export const saveMainCountries = (countries: ICountry[]) =>
  Endpoint.post(
    API_PATH,
    countries.map((c) => c._id),
  ).then(() => {
    invalidateQuery(["COUNTRIES", true]);
    invalidateQuery(["COUNTRIES", false]);
  });
