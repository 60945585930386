import * as yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { addHelpTab, deleteHelpTab, getAllHelpTabsToManage, updateHelpTab } from "../Managers/HelpTabsService";
import { FormFieldText, StyledTooltip, ToggleInput } from "../Components";
import { IHelpTab } from "../Managers/Types";
import { showAppModal, showSnackbar } from "../AppState";
import { ConfirmModal } from "./index";
import { Modal } from "../Components/Modal";
import "./ManageHelpModal.scss";
import RichTextPreview from "../Components/RichTextPreview";
import { RichEditor } from "../Components/RichEditor";
import { useTranslation } from "react-i18next";
import { FileInput } from "../Components/FileInput";
import { ValidationMessages } from "../Enums";

export const ManageHelpModal: React.FC<{ loc?: IHelpTab | null }> = observer(({ loc }) => {
  const [mode, setMode] = useState<"editHelpTab" | "createHelpTab">(loc ? "editHelpTab" : "createHelpTab");
  const [selectedHelpTab, setSelectedHelpTab] = useState<IHelpTab | null>(loc || null);
  const [helpTabs, setHelpTabs] = useState<IHelpTab[] | []>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation(["manage_help", "common"]);

  const validationSchema = yup.object({
    name: yup.string().required(t(ValidationMessages.REQUIRED)),
    slug: yup.string().required(t(ValidationMessages.REQUIRED)),
    active: yup.boolean(),
    content: yup.string(),
    order: yup.number(),
    file_name: yup.string(),
    only_download: yup.boolean(),
    file: yup.mixed(),
  });

  const quillRef = useRef();
  useEffect(() => {
    getAllHelpTabsToManage().then((result) => setHelpTabs(result));
  }, []);

  const selectHelpTab = (tab: IHelpTab | null) => {
    if (tab == null) {
      setSelectedHelpTab(null);
      return;
    }

    setSelectedHelpTab(tab);

    setMode("editHelpTab");
    setIsSaving(false);
  };

  const showDeleteConfirm = () => {
    const helpTabToDelete = selectedHelpTab;
    showAppModal(
      <ConfirmModal
        header={t("manage_help:delete_header")}
        confirmText={t("manage_help:delete_header")}
        children={<p>{t("manage_help:delete_confirm")}</p>}
        onConfirm={() => {
          if (helpTabToDelete == null) return;
          console.log("Deleting helpTab");
          deleteHelpTab(helpTabToDelete)
            .then((r) => {
              console.log("Delete result", r);
              showAppModal(null);
              showSnackbar(t("manage_help:delete_success"), "success");
            })
            .catch((e) => {
              const errorMessage = t("manage_help:delete_error");
              console.log(errorMessage, e);
              showSnackbar(errorMessage, "error");
              showAppModal(<ManageHelpModal loc={selectedHelpTab} />);
            });
        }}
      />,
    );
  };

  const composeValues = (values: IHelpTab) => {
    if (file !== null) {
      return {
        ...selectedHelpTab,
        ...values,
        file_name: file.name,
        _id: undefined,
      };
    }

    return {
      ...selectedHelpTab,
      ...values,
      _id: undefined,
    };
  };

  const handleAddHelpTab = () => {
    if (mode !== "createHelpTab") {
      setMode("createHelpTab");
    }

    setSelectedHelpTab(null);
  };

  const createHelpTab = (values: IHelpTab) => {
    setIsSaving(true);
    const payload = composeValues(values);
    return addHelpTab(payload)
      .then((r) => {
        console.log("Add result", r);
        setSelectedHelpTab(r);
        showSnackbar(t("manage_help:add_success"), "success");
      })
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("manage_help:add_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const editHelpTab = (values: IHelpTab) => {
    if (file) {
      values.file = file;
    }
    setIsSaving(true);
    if (mode === "editHelpTab" && selectedHelpTab) {
      const payload = composeValues(values);
      _updateHelpTab(payload);
    } else if (mode === "createHelpTab") {
      createHelpTab(values);
    }
    showAppModal(null);
  };

  const _updateHelpTab = (values: IHelpTab) => {
    updateHelpTab({ ...values, _id: selectedHelpTab?._id })
      .then((r) => {
        console.log("Update result", r);
        setIsSaving(false);
        showSnackbar(t("manage_help:update_success", { name: selectedHelpTab?.name }), "success");
      })
      .catch((e) => {
        console.log(e);
        setIsSaving(false);
        showSnackbar(t("manage_help:update_error", { name: selectedHelpTab?.name }), "error");
      });
  };

  const initialValues = {
    name: selectedHelpTab?.name || "",
    slug: selectedHelpTab?.slug || "",
    active: selectedHelpTab?.active || false,
    order: selectedHelpTab?.order || 0,
    content: selectedHelpTab?.content || "",
    file_name: selectedHelpTab?.file_name || "",
    only_download: selectedHelpTab?.only_download || false,
  } as IHelpTab;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={editHelpTab} enableReinitialize={true} file={file}>
      {({ isSubmitting, submitForm, setFieldValue, values }) => {
        return (
          <Modal
            title={t("manage_help:title")}
            className="manage-help-modal modal-lg"
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-default" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting || isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {t("manage_help:button", { context: mode === "editHelpTab" ? "save" : "add" })}
                </button>
              </>
            }>
            <form encType="multipart/form-data" onSubmit={submitForm}>
              <div className="left-rail left-panel">
                <div className="left-rail-body">
                  <nav className="left-rail-nav">
                    <div className="left-rail-nav-header">
                      <h5 className="left-rail-nav-header-title">{t("manage_help:tabs")}</h5>
                      <StyledTooltip title={t("manage_help:add")} enterDelay={500}>
                        <div className="btn btn-circle btn-primary add-button" onClick={handleAddHelpTab}>
                          <i className="fa fa-plus" />
                          <span className="sr-only">{t("manage_help:add")}</span>
                        </div>
                      </StyledTooltip>
                    </div>

                    <ul className="left-rail-nav-group">
                      {helpTabs?.map((store) => (
                        <li
                          key={store._id}
                          className={classNames("left-rail-nav-item", {
                            active: selectedHelpTab !== null && selectedHelpTab?._id === store._id,
                          })}>
                          <div onClick={() => selectHelpTab(store)} style={{ display: "grid" }}>
                            {store.name || t("manage_help:default_title", { id: store._id })}
                            <span className="left-rail-nav-item-sub u-display-block"> {store.active}</span>
                          </div>
                        </li>
                      ))}

                      {mode === "createHelpTab" ? <li className="left-rail-nav-item active">{t("manage_help:new_tab")}</li> : null}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="right-panel main">
                {mode === "editHelpTab" && helpTabs && helpTabs.length > 1 ? (
                  <StyledTooltip title={t("manage_help:delete_tab_title")} enterDelay={500}>
                    <button className="btn btn-icon delete-button" onClick={showDeleteConfirm}>
                      {!isSubmitting ? <i className="fa fa-trash" /> : <></>}
                      <span className="sr-only">{t("manage_help:delete_tab_title")}</span>
                    </button>
                  </StyledTooltip>
                ) : null}

                <div>
                  <div className="row">
                    <FormFieldText
                      name="name"
                      className="col-sm-6"
                      label={t("manage_help:name")}
                      placeholder={t("manage_help:name_hint")}
                    />
                    <FormFieldText
                      displayError={true}
                      className="col-sm-6"
                      name="slug"
                      label={t("manage_help:slug")}
                      placeholder={t("manage_help:slug_hint")}
                    />
                  </div>

                  <div className="row toggle-row">
                    <div className="col-sm-3">
                      <label className="input-label u-display-block">{t("manage_help:show")}</label>
                      <ToggleInput
                        className="active-toggle"
                        onChange={(value) => setFieldValue("active", value)}
                        name="active"
                        activeLabel={t("manage_help:show")}
                        inactiveLabel={t("manage_help:hide")}
                        checked={values.active}
                      />
                    </div>
                    <div className="col-sm-7">
                      <label className="input-label u-display-block">{t("manage_help:type")}</label>
                      <ToggleInput
                        className="download-toggle"
                        onChange={(value) => setFieldValue("only_download", value)}
                        name="only_download"
                        activeLabel={t("manage_help:download_file")}
                        inactiveLabel={t("manage_help:show_as_modal")}
                        checked={!!values.only_download}
                      />
                    </div>

                    <div className="col-sm-2" style={{ display: "flex", flexDirection: "column" }}>
                      <label className="input-label u-display-block">{t("manage_help:order")}</label>
                      <input
                        type="number"
                        min="0"
                        max={helpTabs.length + 1}
                        step="1"
                        name="order"
                        value={values.order}
                        onChange={(e) => setFieldValue("order", parseInt(e.target.value))}
                        className="manage-help-order input input-line"
                      />
                    </div>
                  </div>

                  {values.only_download ? (
                    <div className="row" style={{ marginTop: "1rem" }}>
                      <div className="col-sm-12">
                        <label className="input-label u-display-block">{t("manage_help:file")}</label>
                        <FileInput
                          fileName={values.file_name}
                          accept="image/*,.pdf,.xlsx,.txt,.doc"
                          onChange={(file) => {
                            setFieldValue("file_name", file?.name);
                            setFile(file);
                          }}
                          name="file"
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row" style={{ marginTop: "1rem" }}>
                        <div className="col-sm-12">
                          <label className="input-label u-display-block">{t("manage_help:content_editor")}</label>
                          <RichEditor
                            defaultValue={initialValues.content}
                            ref={quillRef}
                            readonly={false}
                            onTextChange={(value) => setFieldValue("content", value)}
                          />
                        </div>
                      </div>

                      <div className="row" style={{ display: "none" }}>
                        <div className="col-sm-12">
                          <label className="input-label u-display-block">{t("manage_help:preview")}</label>
                          <RichTextPreview content={values.content} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
