import React, { createContext, useContext, useEffect, useState } from "react";
import { WindowSize } from "../Enums";

const viewportContext = createContext({});

export const ViewportProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [screenSize, setScreenSize] = useState(WindowSize.DESKTOP);

  const handleWindowResize = () => {
    let size = WindowSize.DESKTOP;
    const windowWidth = window.innerWidth;

    const tabletMaxWidth = 992;
    const mobileMaxWidth = 767;

    if (windowWidth < mobileMaxWidth) {
      size = WindowSize.MOBILE;
    } else if (windowWidth < tabletMaxWidth && windowWidth >= mobileMaxWidth) {
      size = WindowSize.TABLET;
    } else if (windowWidth >= tabletMaxWidth) {
      size = WindowSize.DESKTOP;
    }

    setScreenSize(size);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <viewportContext.Provider value={screenSize}>{children}</viewportContext.Provider>;
};

export const useScreenMode = () => useContext(viewportContext);
