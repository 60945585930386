import * as yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, fetchSystemMessagesData, getUserDateFormat, refreshAppState, showAppModal, showSnackbar } from "../AppState";
import { FormFieldCheckbox } from "../Components";
import { isUserRoleAllowed, SystemMessageType, UserRoles, ValidationMessages } from "../Enums";
import { Modal } from "../Components/Modal";
import { IManageSystemMessage } from "../Managers/Types";
import { resetSystemMessageClosedFlag, sendNotificationEmails, updateSystemMessage } from "../Managers/ManageSystemMessageService";
import "./ManageSystemMessageModal.scss";
import { ConfirmModal } from "./ConfirmModal";
import { useTranslation } from "react-i18next";
import { RichEditor } from "../Components/RichEditor";
import { Button } from "@mui/material";
import { formatDateCustom } from "../Managers";

const validationSchema = yup.object({
  message: yup.string().required(ValidationMessages.REQUIRED),
  active: yup.boolean(),
});

interface ManageSystemMessageModalProps {
  type: SystemMessageType;
}

export const ManageSystemMessageModal: React.FC<ManageSystemMessageModalProps> = observer(({ type }) => {
  const systemMessages = AppState?.systemMessages;
  const actualSystemMessageValues = systemMessages?.find((m) => m.type === type);
  const [initialValues, setInitialValues] = useState<IManageSystemMessage>({
    _id: actualSystemMessageValues?._id ?? 0,
    message: actualSystemMessageValues?.message ?? "",
    active: actualSystemMessageValues?.active ?? false,
    type,
    last_invalidation_date: actualSystemMessageValues?.last_invalidation_date ?? null,
  });
  const [lastInvalidationTime, setLastInvalidationTime] = useState<Date | null>(null);
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const { t } = useTranslation(["common", "system_message"]);
  const quillRef = useRef();
  if (!AppState.user) {
    return <></>;
  }

  useEffect(() => {
    const actualSystemMessageValues = AppState?.systemMessages?.find((m) => m.type === type);
    setInitialValues({
      _id: actualSystemMessageValues?._id ?? 0,
      message: actualSystemMessageValues?.message ?? "",
      active: actualSystemMessageValues?.active ?? false,
      type,
      last_invalidation_date: actualSystemMessageValues?.last_invalidation_date ?? null,
    });
  }, []);

  const handleManageSystemMessageUpdate = (values: IManageSystemMessage) => {
    if (!AppState.user || !isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      return;
    }

    const updatingValues = { ...actualSystemMessageValues, ...values };
    updateSystemMessage(type, updatingValues)
      .then((r) => {
        fetchSystemMessagesData();
        setInitialValues(updatingValues);
        console.log("updateSystemMessage result", r);
        showAppModal(null);
        showSnackbar(t("system_message:update_success"), "success");
        return refreshAppState();
      })
      .catch((error) => {
        console.log("Update System Message error", error);
        showSnackbar(t("system_message:update_error"), "error");
      });
  };

  const handleSendSystemMessageMails = () => {
    setIsSendingEmails(true);
    sendNotificationEmails(type)
      .then((r) => {
        console.log("System message emails result", r);
        showAppModal(null);
        showSnackbar(t("system_message:emails_sent_success"), "success");
      })
      .catch((e) => {
        console.log("Error sending system message emails", e);
        showSnackbar(t("system_message:emails_sent_error"), "error");
      })
      .finally(() => setIsSendingEmails(false));
  };

  const resetUsersSystemMessageClosed = () => {
    resetSystemMessageClosedFlag(type)
      .then((r) => {
        initialValues.last_invalidation_date = r.last_invalidation_date;
        setLastInvalidationTime(r.last_invalidation_date);
        showSnackbar(t("system_message:invalidation_success"), "success");
      })
      .catch((e) => {
        console.log("Error while resetting UsersSystemMessageClosed", e);
        showSnackbar(t("system_message:emails_sent_error"), "error");
      });
  };

  const prepareLastInvalidationTime = () => {
    const dateTime = lastInvalidationTime || initialValues.last_invalidation_date || null;

    if (dateTime === null) {
      return null;
    }

    return (
      <span style={{ paddingLeft: 8 }} className="last-invalidation-time">
        {t(`system_message:last_invalidation`)} {formatDateCustom(dateTime, `${getUserDateFormat()} hh:mm A`)}
      </span>
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleManageSystemMessageUpdate}>
      {({ isSubmitting, isValid, values, submitForm, initialValues, setFieldValue, setFieldTouched }) => {
        return (
          <Modal
            className="manage-system-messages-modal"
            title={t(`system_message:title_${type}`)}
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save_changes")}
                </button>
              </>
            }>
            <form>
              <div className="row message-edition-row">
                <RichEditor
                  defaultValue={initialValues.message}
                  className="message-notification"
                  ref={quillRef}
                  readonly={false}
                  onTextChange={(value) => setFieldValue("message", value)}
                />
              </div>

              <div className="row">
                <Button variant="contained" onClick={() => resetUsersSystemMessageClosed()}>
                  {t(`system_message:invalidate_display`)}
                </Button>

                {prepareLastInvalidationTime()}

                <div style={{ marginTop: "2rem" }}>
                  <FormFieldCheckbox
                    name="active"
                    checked={values.active}
                    label={t(`system_message:active_label`)}
                    onChange={(e) => {
                      setFieldTouched("active", true);
                      setFieldValue("active", e.target.checked);
                    }}
                  />
                </div>
              </div>

              {type === "maintenance" && (
                <div className="row" style={{ marginTop: 20 }}>
                  <p className="input-label">{t(`system_message:send_notification`)}</p>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      showAppModal(
                        <ConfirmModal
                          header={t(`system_message:send_notification`)}
                          children={<p>{t(`system_message:send_notification_body`)}</p>}
                          confirmText={t(`system_message:send_notification_confirm`)}
                          onConfirm={handleSendSystemMessageMails}
                        />,
                      );
                    }}
                    disabled={isSendingEmails}>
                    {isSendingEmails ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                    {t(`system_message:send_notification_confirm`).toUpperCase()}
                  </button>
                  <br />
                </div>
              )}
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
