import React, { useState } from "react";
import { INotification } from "../../Managers/Types";
import { useTranslation } from "react-i18next";
import { getUserDateFormat, showAppModal, showSnackbar } from "../../AppState";
import AlertsHistoryDetailsModal from "./modals/AlertsHistoryDetailsModal";
import { deleteNotifications } from "../../Managers/NotificationService";
import { Box, Button } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { Colors } from "../../Theme";
import { CheckboxInput, useScreenMode } from "../../Components";
import { formatDateCustom, getNameSlug } from "../../Managers";
import { measurementTransform } from "../../Managers/MeasurementService";
import { unitsTransform } from "../../Managers/UnitsService";
import { ConfirmModal } from "../../Modals";
import { alertConditionTransform } from "../../Managers/AlertConditionService";
import { WindowSize } from "../../Enums";
import { isBatteryLowAlert } from "../../Managers/AlertService";

interface IAlertsHistoryTableWrapperProps {
  sortedNotifications: Array<INotification> | [];
  onDelete: () => void;
}

export const AlertsHistoryTable: React.FC<IAlertsHistoryTableWrapperProps> = ({ sortedNotifications, onDelete }) => {
  const [selectedNotifications, setSelectedNotifications] = useState<INotification[]>([]);

  const { t } = useTranslation(["alert_history", "alerts"]);
  const mode = useScreenMode();

  const showAlertDetailsModal = (selectedAlert: INotification) => showAppModal(<AlertsHistoryDetailsModal selectedAlert={selectedAlert} />);

  const handleConfirmDelete = (notification: INotification | INotification[]) => {
    showAppModal(
      <ConfirmModal
        header={t("alert_history:delete_alert")}
        confirmText={t("common:delete")}
        onConfirm={() => handleDeleteAlert(notification)}
        children={
          <>
            <p>{t("alert_history:confirm_delete_content")}</p> <p>{t("alert_history:confirm_delete_warning")}</p>
          </>
        }
      />,
    );
  };

  const handleDeleteAlert = (notification: INotification | INotification[]) => {
    deleteNotifications(Array.isArray(notification) ? notification : [notification])
      .then((r) => {
        onDelete();
        console.log("Delete result", r);
        showAppModal(null);
        setSelectedNotifications([]);
        showSnackbar(t("alert_history:delete_notification_success"), "success");
      })
      .catch((e) => {
        console.log("Delete error", e);
        showSnackbar(t("alert_history:delete_notification_error"), "error");
      });
  };

  if (sortedNotifications.length === 0) {
    return (
      <section className="main-panel no-results">
        <div className="text-center">{t("alert_history:no_results")}</div>
      </section>
    );
  }

  const selectNotification = (notification: INotification) => {
    const notifications = selectedNotifications.slice();
    const notificationIndex = notifications.findIndex((n) => n._id === notification._id);
    if (notificationIndex > -1) {
      notifications.splice(notificationIndex, 1);
    } else {
      notifications.push(notification);
    }
    setSelectedNotifications(notifications);
  };

  return (
    <>
      <section className="main-panel">
        {mode === WindowSize.DESKTOP ? (
          <Box className="alerts-history-desktop-table u-desktop-only">
            <Table className="full-width">
              <TableHead>
                <TableRow style={{ background: Colors.bodyBlue }}>
                  <TableCell width={50} className="alerts-history-table-head" />
                  <TableCell width={200} className="alerts-history-table-head">
                    {t("alert_history:create_date")}
                  </TableCell>
                  <TableCell width={200} className="alerts-history-table-head">
                    {t("alert_history:updated")}
                  </TableCell>
                  <TableCell width={210} className="alerts-history-table-head">
                    {t("alert_history:type")}
                  </TableCell>
                  <TableCell width={150} className="alerts-history-table-head">
                    {t("alert_history:alert_value")}
                  </TableCell>
                  <TableCell width={260} className="alerts-history-table-head">
                    {t("alert_history:sensor_type")}
                  </TableCell>
                  <TableCell colSpan={2} width={350} className="alerts-history-table-head">
                    {selectedNotifications.length ? (
                      <div className="pull-right" style={{ display: "flex", gap: 12 }}>
                        <div className="alerts-history-selected">
                          {t("alert_history:selected")}: {selectedNotifications.length}{" "}
                        </div>
                        <Button variant="text" className="alerts-history-button-cancel" onClick={() => setSelectedNotifications([])}>
                          {t("common:cancel")}
                        </Button>
                        <div className="btn btn-primary" onClick={() => handleConfirmDelete(selectedNotifications)}>
                          {t("alert_history:delete", { count: selectedNotifications.length })}
                        </div>
                      </div>
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedNotifications.map((notification) => (
                  <TableRow id={notification._id.toString()} key={`notification-${notification._id}-${Math.floor(Math.random() * 10000)}`}>
                    <TableCell width={50}>
                      <CheckboxInput
                        onChange={() => selectNotification(notification)}
                        checked={selectedNotifications.some((n) => n._id === notification._id)}
                        name={"checked-" + notification._id}
                      />
                    </TableCell>
                    <TableCell style={{ padding: "5px 16px" }}>
                      {formatDateCustom(notification.createdAt, `${getUserDateFormat()} hh:mm A`)}
                    </TableCell>
                    <TableCell style={{ padding: "5px 16px" }}>
                      {formatDateCustom(notification.updatedAt, `${getUserDateFormat()} hh:mm A`)}
                    </TableCell>
                    <TableCell>
                      {isBatteryLowAlert(notification.Alert)
                        ? t("alert_history:sensor_type_battery_alert")
                        : alertConditionTransform(notification.Alert)}
                    </TableCell>
                    <TableCell>
                      {notification.Sensor !== null ? (
                        <span>
                          {measurementTransform(notification.value.value, {
                            unit: notification.Sensor?.default_unit,
                            empirical: notification.Sensor?.is_imperial,
                            type: notification.Sensor?.Sensor_type.type,
                          })}
                          {notification.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                            <span>
                              {" "}
                              <span className="u-degree-history">
                                {unitsTransform(notification.Sensor?.default_unit || "degF", [
                                  notification.Sensor?.default_unit || "degF",
                                  notification.Sensor?.is_imperial,
                                  notification.Sensor?.Sensor_type.type || t("common:select_options.range"),
                                ])}
                              </span>{" "}
                            </span>
                          )}
                        </span>
                      ) : notification.Device !== null ? (
                        <>
                          <span>{notification.value.value}</span>{" "}
                          <span>
                            <span className="u-degree-history">{isBatteryLowAlert(notification.Alert) && "V"}</span>{" "}
                          </span>
                        </>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {notification.Sensor?.Sensor_type ? t(`sensor_types:${getNameSlug(notification.Sensor?.Sensor_type?.name)}`) : ""}
                    </TableCell>
                    <TableCell align="center">
                      <Button onClick={() => showAlertDetailsModal(notification)} variant="contained" style={{ padding: "1px 10px" }}>
                        <span>{t("alert_history:see_details")}</span>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleConfirmDelete(notification)} className="delete-icon custom-icon">
                        <i className="fa fa-trash-o" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Box className="mobile-tablet-segments u-desktop-hide">
            {sortedNotifications.map((notification) => (
              <div className="notification-segment" key={notification._id}>
                <div>
                  <CheckboxInput
                    onChange={() => selectNotification(notification)}
                    checked={selectedNotifications.some((n) => n._id === notification._id)}
                    name={"checked-" + notification._id}
                  />
                </div>
                <div className="notification-content">
                  <div className="notification-content-mobile-column">
                    <div className="notification-label">{t("alert_history:create_date")}</div>
                    <div className="notification-date">{formatDateCustom(notification.createdAt, `${getUserDateFormat()} - H:mm a`)}</div>
                  </div>
                  <div className="notification-content-mobile-column">
                    <div className="notification-label">{t("alert_history:updated")}</div>
                    <div className="notification-date">{formatDateCustom(notification.updatedAt, `${getUserDateFormat()} - H:mm a`)}</div>
                  </div>
                  <div className="notification-content-mobile-column">
                    <div className="notification-label">{t("alert_history:type")}</div>
                    <div className="notification-type">
                      {isBatteryLowAlert(notification.Alert)
                        ? t("alert_history:sensor_type_battery_alert")
                        : alertConditionTransform(notification.Alert)}
                    </div>
                  </div>
                  <div className="notification-content-mobile-column">
                    <div className="notification-label">{t("alert_history:alert_value")}</div>
                    <div className="notification-alert-value">
                      {measurementTransform(notification.value.value, {
                        unit: notification.Sensor?.default_unit,
                        empirical: notification.Sensor?.is_imperial,
                        type: notification.Sensor?.Sensor_type.type,
                      })}
                      {notification.Sensor?.Sensor_type.type !== "BOOLEAN" && (
                        <span>
                          {" "}
                          <span className="u-degree-history">
                            {unitsTransform(notification.Sensor?.default_unit || "degF", [
                              notification.Sensor?.default_unit || "degF",
                              notification.Sensor?.is_imperial,
                              notification.Sensor?.Sensor_type.type || t("common:select_options.range"),
                            ])}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="notification-content-mobile-column">
                    <div className="notification-label">{t("alert_history:sensor_type")}</div>
                    <div className="notification-sensor-type">
                      {notification.Sensor?.Sensor_type ? t(`sensor_types:${getNameSlug(notification.Sensor?.Sensor_type?.name)}`) : ""}
                    </div>
                  </div>
                  <div className="notification-content-mobile-column empty-column" />
                  <div className="notification-content-mobile-column" style={{ display: "flex" }}>
                    <Button onClick={() => showAlertDetailsModal(notification)} variant="text" className="see-details-button">
                      <span>{t("alert_history:see_details")}</span>
                    </Button>
                    <Button onClick={() => handleConfirmDelete(notification)} className="delete-icon custom-icon">
                      <i className="fa fa-trash-o" />
                    </Button>
                    {/*<img*/}
                    {/*  src={trashIcon}*/}
                    {/*  alt={t("common:delete")}*/}
                    {/*  className="custom-icon"*/}
                    {/*  style={{ marginRight: 10 }}*/}
                    {/*  onClick={() => handleConfirmDelete(notification)}*/}
                    {/*/>*/}
                  </div>
                </div>
              </div>
            ))}
          </Box>
        )}
      </section>
      {selectedNotifications.length ? (
        <div className="mobile-floating-buttons u-mobile-only floating-buttons-alert-history">
          <div className="pull-right" style={{ display: "flex", gap: 12 }}>
            <div className="alerts-history-selected">
              {t("alert_history:selected")}: {selectedNotifications.length}{" "}
            </div>
            <Button variant="text" className="alerts-history-button-cancel" onClick={() => setSelectedNotifications([])}>
              {t("common:cancel")}
            </Button>
            <div className="btn btn-primary" onClick={() => handleConfirmDelete(selectedNotifications)}>
              {t("alert_history:delete", { count: selectedNotifications.length })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
