import { Page, useScreenMode, Wrapper } from "../../Components";
import { ExportLeftRail } from "./ExportLeftRail";
import { IDevice, IExportPreset, IGateway, ILocation, ISensor, ISensorType } from "../../Managers/Types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDevicesWithProps, useDeviceTypeGroups } from "../../Managers/DeviceService";
import { Drawer } from "@mui/material";
import { ExportHeader } from "./ExportHeader";
import "./Export.scss";
import { MobileExportFilters } from "./MobileExportFilters";
import { ExportItems } from "./ExportItems";
import { isUserPermissionAllowed, PermissionEnum, WindowSize } from "../../Enums";
import { getLocations } from "../../Managers/API";
import { AppState } from "../../AppState";
import { addPreset, updatePreset } from "../../Managers";

export interface IExportFilter {
  device_status: boolean;
  device_type: number;
  sample_interval: string;
  transmit_interval: number;
  approaching: boolean;
  in_alert: boolean;
  sensor_type: string;
  last_report_time: Date | null;
}

export type ExportFilter = { key: keyof IExportFilter; value: any }[];

export interface IReportSensor extends IExportFilter {
  _id: number;
  device: IDevice;
  sensor: ISensor;
  device_name: string;
}

export const Export: React.FC = () => {
  const { t } = useTranslation();
  const ALL_LOCATIONS = { name: t("common:all_locations") } as ILocation;

  const [selectedGateways, setSelectedGateways] = useState<IGateway[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<IDevice[]>([]);
  const [location, setLocation] = useState<ILocation>(ALL_LOCATIONS);
  const [deviceSearch, setDeviceSearch] = useState("");
  const [gatewaySearch, setGatewaySearch] = useState("");
  const [gatewayScopedDevices, setGatewayScopedDevices] = useState<IDevice[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [sensorTypes, setSensorTypes] = useState<ISensorType[]>([]);
  const [sensors, setSensors] = useState<IReportSensor[]>([]);
  const [filters, setFilters] = useState<ExportFilter>([]);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [preset, setPreset] = useState<IExportPreset>();
  const [selectedSensors, setSelectedSensors] = useState<IReportSensor[]>([]);

  const deviceQuery = useDevicesWithProps(["_id", "serial_number", "name", "is_online", "GatewayId", "location_note"], true, true, true);

  const mode = useScreenMode();

  const deviceTypeGroupsQuery = useDeviceTypeGroups(
    selectedGateways.map((g) => g._id),
    location._id,
  );

  useEffect(() => {
    if (deviceQuery.data) {
      filterDevices();
    }
  }, [selectedGateways]);

  useEffect(() => {
    setSelectedDevices(deviceQuery.data ?? []);
    setGatewayScopedDevices(deviceQuery.data ?? []);
  }, [deviceQuery.isFetched]);

  const changeLocation = (loc: ILocation, gatewayIds?: number[], deviceIds?: number[]) => {
    let gateways = loc.Gateways ?? [];

    if (gatewayIds?.length) {
      gateways = gateways.filter((g) => gatewayIds.some((id) => id === g._id));
    }

    setLocation(loc);
    setSelectedGateways(gateways);

    let devices = deviceQuery.data ?? [];

    if (deviceIds) {
      devices = devices.filter((d) => deviceIds.some((id) => id === d._id));
    } else if (loc._id) {
      devices = devices.filter((d) => gateways.some((g) => g._id === d.GatewayId));
    }

    setSelectedDevices(devices);
  };

  useEffect(() => {
    const sensors = selectedDevices.reduce((sensorArr, device) => {
      sensorArr.push(
        ...device.Sensors.map((sensor) => ({
          _id: sensor._id,
          device_name: device.name,
          device_type: device.Device_type?._id,
          sample_interval: device.Device_setting?.settings_data.sampleInterval,
          transmit_interval: device.Device_setting?.settings_data.transmitInterval,
          in_alert: sensor.Alerts?.some(({ Sensor_alerts }) => Sensor_alerts.condition === "ALERT" && Sensor_alerts.is_active),
          approaching: sensor.Alerts?.some(({ Sensor_alerts }) => Sensor_alerts.condition === "WARNING" && Sensor_alerts.is_active),
          device_status: device.is_online,
          sensor_type: sensor.Sensor_type.name,
          last_report_time: sensor.last_report_time ? new Date(sensor.last_report_time) : null,
          device,
          sensor,
        })),
      );
      return sensorArr;
    }, [] as IReportSensor[]);

    setSensors(sensors);

    const sensorTypes = selectedDevices.reduce((types, device) => {
      device.Sensors.forEach((s) => {
        if (!types.some((type) => type._id === s.Sensor_type._id)) {
          types.push(s.Sensor_type);
        }
      });
      return types;
    }, [] as ISensorType[]);

    setSensorTypes(sensorTypes);
  }, [selectedDevices]);

  const filterDevices = () => {
    if (deviceQuery.data) {
      let filteredDevices: IDevice[] = deviceQuery.data;

      if (selectedGateways && location._id) {
        filteredDevices = filteredDevices.filter((d) => selectedGateways.some((g) => g._id === d.GatewayId));
      }

      setGatewayScopedDevices(filteredDevices);
    }
  };

  const handleLoadPreset = async (preset?: IExportPreset) => {
    if (!preset) {
      setTimeout(() => setPreset(preset));

      return;
    }

    const { filters, LocationId: locationId, GatewayIds: gatewayIds, DeviceIds: deviceIds } = preset;

    let locations: ILocation[] = [];
    if (locationId) {
      if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
        locations = await getLocations();
      } else {
        locations = AppState.user?.Locations ?? [];
      }
    }
    const loc = locations.find((l) => l._id === locationId) ?? ALL_LOCATIONS;
    changeLocation(loc, gatewayIds, deviceIds);

    setFilters(Object.entries(filters).map(([key, value]) => ({ key: key as keyof IExportFilter, value })));

    setTimeout(() => setPreset(preset));
  };

  const handleSavePreset = async ({ filters: saveFilters, UserId, ...rest }: IExportPreset) => {
    const promise = preset?._id ? updatePreset : addPreset;
    const res = await promise({
      GatewayIds: selectedGateways.map((g) => g._id),
      DeviceIds: selectedDevices.map((d) => d._id),
      LocationId: location._id,
      _id: preset?._id,
      UserId: UserId ?? AppState.user!._id,
      filters:
        saveFilters ??
        filters.reduce((obj, item) => {
          (obj[item.key] as any) = item.value;
          return obj;
        }, {} as IExportFilter),
      ...rest,
    });
    setPreset(res);
    return res;
  };

  return (
    <Page>
      <Wrapper>
        {mode !== WindowSize.MOBILE ? (
          <Drawer
            variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
            hideBackdrop={mode === WindowSize.DESKTOP}
            onBackdropClick={() => mode === WindowSize.TABLET && setDrawerOpen(false)}
            open={drawerOpen || mode === WindowSize.DESKTOP}>
            <ExportLeftRail
              changeLocation={changeLocation}
              location={location}
              selectGateways={setSelectedGateways}
              selectedGateways={selectedGateways}
              setSelectedDevices={setSelectedDevices}
              devices={gatewayScopedDevices}
              deviceSearchString={deviceSearch}
              gatewaySearchString={gatewaySearch}
              setGatewaySearchString={setGatewaySearch}
              setDeviceSearchString={setDeviceSearch}
              selectedDevices={selectedDevices}
            />
          </Drawer>
        ) : null}
        <main className="bapi-main mod-dashboard">
          <ExportHeader
            preset={preset}
            savePreset={handleSavePreset}
            handleSelectPreset={handleLoadPreset}
            setDrawerOpen={setDrawerOpen}
            setFilters={setFilters}
            filters={filters}
            sensorTypes={sensorTypes}
            deviceTypes={deviceTypeGroupsQuery.data?.map((g) => g.Device_types ?? []).flat() ?? []}
            setDateRange={(start, end) => setDateRange([start, end])}
            setMobileFiltersOpen={setMobileFiltersOpen}
          />
          <ExportItems
            selectedSensors={selectedSensors}
            setSelectedSensors={setSelectedSensors}
            preset={preset}
            sensors={sensors}
            dateRange={dateRange}
            filters={filters}
            savePreset={handleSavePreset}
          />
        </main>
        {mobileFiltersOpen ? (
          <MobileExportFilters
            preset={preset}
            handleSelectPreset={handleLoadPreset}
            savePreset={handleSavePreset}
            dateRange={dateRange}
            filters={filters}
            setFilters={setFilters}
            setDateRange={(start, end) => setDateRange([start, end])}
            devices={gatewayScopedDevices}
            selectedDevices={selectedDevices}
            selectDevices={setSelectedDevices}
            selectedGateways={selectedGateways}
            selectGateways={setSelectedGateways}
            close={() => setMobileFiltersOpen(false)}
            sensorTypes={sensorTypes}
            changeSelectedLocation={changeLocation}
            location={location}
            deviceTypes={deviceTypeGroupsQuery.data?.map((g) => g.Device_types ?? []).flat() ?? []}
          />
        ) : null}
      </Wrapper>
    </Page>
  );
};
