import { IAccountProps } from "./AccountStep";
import { Column, CountrySelect, Flag, FormFieldText, IFormStepProps, List, ListItem, Row, StepperActions } from "../../Components";
import React, { useEffect, useState } from "react";
import { IOptionPrice, IPaymentOptions, ISelectedPlanOption, IUserPaymentData, IPlanProps } from "../../Managers/Types";
import "./ConfirmationStep.scss";
import { GetPaymentOptions } from "../../Managers/PaymentOptionsService";
import { useTranslation } from "react-i18next";
import { ICountry } from "../../Managers";

interface IConfirmationStepProps extends IFormStepProps {
  accountDetails?: IAccountProps;
  planDetails?: IPlanProps;
  price: number;
  isConfirming: boolean;
}

export interface IPaymentData {
  userData: IUserPaymentData;
  selectedPlans: ISelectedPlanOption[];
}

export const ConfirmationStep: React.FC<IConfirmationStepProps> = ({
  accountDetails,
  planDetails,
  price,
  onClickBack,
  onSubmit,
  isConfirming,
}) => {
  const { numberOfDevices, numberOfUsers, smsNotifications, transmissionRate } = planDetails ?? {};

  const [usersOption, setUsersOption] = useState<IOptionPrice>();
  const [transmissionOption, setTransmissionOption] = useState<IOptionPrice>();
  const [smsOption, setSmsOption] = useState<IOptionPrice>();
  const [planOptions, setPlanOptions] = useState<IPaymentOptions>();
  const { t } = useTranslation(["register", "common"]);

  useEffect(() => {
    const userCountryCode = accountDetails?.country?.code ?? "US";
    const currencyCode = accountDetails?.currency?.iso_code ?? "USD";
    GetPaymentOptions(userCountryCode, currencyCode).then((res) => setPlanOptions(res.data));
  }, [accountDetails, planDetails]);

  useEffect(() => {
    setUsersOption(planOptions?.numberOfUsers.options.find((o) => o.id === numberOfUsers));
    setTransmissionOption(planOptions?.transmissionRate.options.find((o) => o.id === transmissionRate));
    setSmsOption(planOptions?.smsNotifications?.options.find((o) => o.id === smsNotifications));
  }, [numberOfUsers, planOptions, smsNotifications, transmissionRate]);

  const renderCountry = (country: ICountry) => (
    <>
      <Flag countryCode={country.code} /> {t(`countries:${country.code}`)}
    </>
  );

  const formatPrice = (value: number | string) => {
    if (typeof value === "string") {
      value = Number.parseFloat(value);
    }

    const currencySign = accountDetails?.currency?.sign;
    return `${currencySign}${value.toFixed(2)}`;
  };
  return (
    <>
      <div className="confirm-step-container">
        <div className="type-large-regular register-form-label">{t("register:steps.confirmation.title")}</div>
        <Column>
          <div className="type-large-regular register-form-label">{t("register:steps.confirmation.account_info")}</div>
          <div className="row-to-mobile-column">
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.first_name")}</p>
              <p>{accountDetails?.firstName}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.last_name")}</p>
              <p>{accountDetails?.lastName}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.account_title")}</p>
              <p>{accountDetails?.title || "-"}</p>
            </Column>
          </div>
          <div className="row-to-mobile-column">
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.phone_number")}</p>
              <p>{`${accountDetails?.country?.dial_code} ${accountDetails?.phone}`}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.email")}</p>
              <p>{accountDetails?.emailAddress}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.confirmation.account_details.company")}</p>
              <p>{accountDetails?.companyName}</p>
            </Column>
          </div>
        </Column>
        <Column>
          <div className="type-large-regular register-form-label">{t("register:steps.confirmation.billing_info")}</div>
          <div className="row-to-mobile-column">
            <Column>
              <p className="input-label">{t("register:steps.account.country")}</p>
              <p>{accountDetails?.country ? renderCountry(accountDetails?.country) : "--"}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.account.city")}</p>
              <p>{accountDetails?.city}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.account.address")}</p>
              <p>{accountDetails?.address}</p>
            </Column>
          </div>
          <div className="row-to-mobile-column">
            <Column>
              <p className="input-label">{t("register:steps.account.zip")}</p>
              <p>{accountDetails?.zip}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.account.state")}</p>
              <p>{accountDetails?.state}</p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.account.billing_id")}</p>
              <p>{accountDetails?.billing_id}</p>
            </Column>
          </div>
        </Column>
        <Column>
          <div className="type-large-regular register-form-label" style={{ paddingTop: "16px" }}>
            {t("register:steps.confirmation.plan_details_title")}
          </div>
          <Row className="confirmation-step-second-row-double-width">
            <Column>
              <p className="input-label">{t("register:steps.confirmation.plan_details.subscription_price")}</p>
              <p>
                {formatPrice(price)}/{t("common:monthly")}
              </p>
            </Column>
            <Column>
              <p className="input-label">{t("register:steps.confirmation.plan_details.includes")}</p>
              <List listStyle="disc" className="container-full confirmation-step-list">
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>{t("register:steps.confirmation.plan_details.base_price")}</span>
                    <span>{formatPrice(planOptions?.basePrice.options[0].price ?? "0")}</span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>
                      {numberOfDevices} {t("register:steps.confirmation.plan_details.devices", { count: numberOfDevices || undefined })}
                    </span>
                    <span>
                      {numberOfDevices && planOptions ? formatPrice(planOptions?.numberOfDevices.options[0].price * numberOfDevices) : "-"}
                    </span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>
                      {usersOption?.name} {t("register:steps.confirmation.plan_details.users", { count: numberOfUsers || undefined })}
                    </span>
                    <span>
                      {usersOption?.price ? formatPrice(usersOption.price) : t("register:steps.confirmation.plan_details.in_plan")}
                    </span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>
                      {t("register:steps.confirmation.plan_details.transmission_rate")} {transmissionOption?.name}
                    </span>
                    <span>
                      {numberOfDevices && transmissionOption?.price ? formatPrice(transmissionOption.price * numberOfDevices) : "-"}
                    </span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>{t("register:steps.confirmation.plan_details.email_notifications")}</span>
                    <span>{t("register:steps.confirmation.plan_details.free")}</span>
                  </div>
                </ListItem>
                {smsOption?.price ? (
                  <ListItem>
                    <div className="confirmation-step-list-item-content">
                      <span>
                        {smsOption?.name} {t("register:steps.confirmation.plan_details.sms_notifications")}
                      </span>
                      <span>{smsOption?.price ? formatPrice(smsOption?.price) : ""}</span>
                    </div>
                  </ListItem>
                ) : null}
              </List>
            </Column>
          </Row>
        </Column>
      </div>
      <StepperActions
        onClickBack={onClickBack}
        onClickNext={onSubmit}
        lastStep={true}
        buttonDisabled={isConfirming}
        nextButtonMessage={t("register:steps.confirmation.plan_details.create_and_proceed")}
      />
    </>
  );
};
