import { observer } from "mobx-react-lite";
import styled from "@emotion/styled/macro";
import { AppState, refreshLocationData, setSelectedAllDevices, showAppModal } from "../../AppState";
import { Colors, Vars } from "../../Theme";
import { CheckboxInput, PermissionBlocker, SelectInput, Tab, TabContext, TabList, TabPanel } from "../../Components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import { PickLocationModal } from "./Modals/PickLocationModal";
import { EditGatewayModal } from "./Modals/EditGatewayModal";
import { PermissionEnum, WindowSize } from "../../Enums";
import { ManageFirmware } from "./Modals/ManageFirmware";
import { getLatestFirmwares } from "../../Managers/API";
import classNames from "classnames";
import { AddDeviceGroupModal } from "./Modals/AddDeviceGroup";
import ClickAwayListener from "react-click-away-listener";
import { EditDeviceGroupModal } from "./Modals/EditDeviceGroupModal";
import { RemoveGroupModal } from "./Modals/RemoveGroupModal";
import { SearchRow } from "../../Components/SearchRow";
import { MobileDashboardFilters } from "./MobileDashboardFilters";
import { DeviceSort, OrderBy } from "../../Enums/SortingTypes";
import { useGatewaySensorTypes } from "../../Managers/DeviceService";
import { IDeviceFilter } from "../../Managers/LocationService";

type DashboardMode = "groups" | "gateways";

interface IMobileDashboardHeaderProps {
  tab: DashboardMode;
  setTab: (tab: DashboardMode) => void;
  selectGateway: (gateway: IGateway | null) => void;
  selectGroup: (group: IDeviceGroup | null) => void;
  selectedGateway?: IGateway | null;
  selectedGroup?: IDeviceGroup | null;
  gateways: IGateway[];
  groups: IDeviceGroup[];
  searchString: string;
  setSearchString: (search: string) => void;
  allDevices: IDevice[];
  setOrderBy: (by: OrderBy) => void;
  setSortBy: (by: DeviceSort) => void;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  setChipLength: React.Dispatch<React.SetStateAction<any>>;
}

const LocationRow = styled.div`
  text-align: start;
  overflow: hidden;
  padding: 12px 16px;

  > button {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    width: 100%;
  }

  .fa-map-marker {
    color: ${Colors.teal};
    margin-right: 10px;
  }
`;

const MobileSelectEntityRow = styled.div({
  alignItems: "center",
  backgroundColor: Colors.marineBlue,
  display: "flex",
  flexDirection: "row",
  padding: 16,

  "> :first-child": {
    margin: "0 24px 0 0",
  },
});

const MobileActionRow = styled.div({
  padding: 16,
  display: "flex",
  justifyContent: "space-between",
});

const MobileButtons = styled.div({
  display: "flex",
  gap: 16,
});

export const MobileDashboardHeader: React.FC<IMobileDashboardHeaderProps> = observer(
  ({
    searchString,
    setSearchString,
    selectedGroup,
    selectGroup,
    groups,
    gateways,
    selectGateway,
    selectedGateway,
    tab,
    setTab,
    allDevices,
    setSortBy,
    setOrderBy,
    setFilters,
    setChipLength,
  }) => {
    const { t } = useTranslation(["dashboard", "common"]);
    const [showAllGateways, setShowAllGateways] = useState(false);
    const [showingAllGatewaysMenu, setShowingAllGatewaysMenu] = useState(false);
    const [gatewayDropdownOpen, setGatewayDropdownOpen] = useState(false);
    const [groupDropdownOpen, setGroupDropdownOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [showLowBatteryInfo, setShowLowBatteryInfo] = useState(false);

    const sensorTypes = useGatewaySensorTypes(selectedGateway?._id, selectedGroup?._id);

    const changeTab = (tab: DashboardMode) => {
      setTab(tab);
      if (tab === "gateways") {
        selectGateway(gateways[0]);
        selectGroup(null);
      } else {
        selectGroup(groups[0]);
        selectGateway(null);
      }
    };

    const getAllDevicesCount = useCallback(() => gateways.reduce((acc, gateway) => acc + gateway.Devices.rows?.length, 0), [gateways]);

    const gatewayMenuItems = [
      {
        action: () =>
          !!selectedGateway &&
          showAppModal(
            <EditGatewayModal
              setGateway={selectGateway}
              gateway={selectedGateway}
              mode={selectedGateway?.is_online ? "offline" : "online"}
            />,
          ),
        icon: "fa-power-off",
        title: t("dashboard:gateway.turn", { context: selectedGateway?.is_online ? "offline" : "online" }),
      },
      {
        action: () =>
          !!selectedGateway && showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="edit" />),
        icon: "fa-pencil-square-o",
        title: t("dashboard:gateway.edit"),
      },
      {
        action: () =>
          !!selectedGateway && showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="delete" />),
        icon: "fa-trash-o",
        title: t("dashboard:gateway.delete"),
      },
    ];

    const groupMenuItems = [
      {
        action: () => !!selectedGroup && showAppModal(<EditDeviceGroupModal setGroup={selectGroup} group={selectedGroup} />),
        icon: "fa-pencil-square-o",
        title: t("dashboard:device_group.edit"),
      },
      {
        action: () => !!selectedGroup && showAppModal(<RemoveGroupModal selectedGroup={selectedGroup} />),
        icon: "fa-trash-o",
        title: t("dashboard:device_group.delete"),
      },
    ];

    const [showFullFilters, setShowFullFilters] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);

    const elementRef = useCallback(
      (node) => {
        console.warn(node);

        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
          setShowMoreButton(node.clientHeight >= 55);
        });
        resizeObserver.observe(node);
      },
      [AppState.chipCount],
    );
    return (
      <header className="mobile-header">
        <LocationRow>
          <button className="btn btn-plain" onClick={() => showAppModal(<PickLocationModal />)}>
            <i className="fa fa-map-marker" />
            {AppState.selectedLocation ? (
              <span>
                {AppState.selectedLocation.name}, {AppState.selectedLocation.address}
              </span>
            ) : null}
          </button>
        </LocationRow>
        <TabContext value={tab}>
          <TabList sx={{ backgroundColor: Colors.marineBlue }} onChange={(_e, val) => changeTab(val)}>
            {["gateways", "groups"].map((tab) => (
              <Tab label={t(`dashboard:${tab}`)} className="col-xs-6" value={tab} />
            ))}
          </TabList>
          <TabPanel sx={{ padding: 0 }} value="gateways">
            <MobileSelectEntityRow>
              <SelectInput
                className="input-filter-holder"
                style={{ flex: 1 }}
                displayEmpty={true}
                menuItemClass="dark"
                inputClassName="dark"
                value={selectedGateway}
                onChange={(gateway) => {
                  if (!gateway) {
                    setSelectedAllDevices(true);
                  }
                  selectGroup(null);
                  selectGateway(gateway);
                }}
                renderValue={(value) =>
                  value
                    ? `${(value as IGateway).name} (${(value as IGateway).Devices.rows?.length || 0})`
                    : `${t("dashboard:all_devices")} (${getAllDevicesCount()})`
                }
                options={[
                  ...gateways
                    .filter((gateway) => (gateway.Devices?.rows && gateway.Devices.rows.length > 0) || showAllGateways)
                    .map((gateway) => ({
                      value: gateway,
                      label: `${gateway.name} (${gateway.Devices.rows?.length || 0})`,
                    })),
                  {
                    value: undefined,
                    label: `${t("dashboard:all_devices")} (${getAllDevicesCount()})`,
                  },
                ]}
              />
              <MobileButtons>
                <button
                  className={classNames("btn btn-circle btn-primary bapi-header-nav-item dropdown", { open: showingAllGatewaysMenu })}
                  onClick={() => setShowingAllGatewaysMenu(!showingAllGatewaysMenu)}>
                  <i className={"fa " + (!showAllGateways ? "fa-eye-slash" : "fa-eye")} />
                  <section role="menu" className="dropdown-menu dropdown-menu-right">
                    <div style={{ padding: "8px", color: Colors.white, maxWidth: "50vw", whiteSpace: "break-spaces" }}>
                      <CheckboxInput
                        onChange={() => {
                          setShowAllGateways(!showAllGateways);
                          setShowingAllGatewaysMenu(false);
                        }}
                        checked={showAllGateways}
                        name="showAllGateways"
                        label={t("dashboard:gateways_show")}
                      />
                      <div
                        style={{
                          color: Colors.lightGray,
                          fontWeight: Vars.regularFontWeight,
                        }}>
                        {t("dashboard:gateways_show_explanation")}
                      </div>
                    </div>
                  </section>
                </button>
                <button onClick={() => showAppModal(<EditGatewayModal gateway={null} mode="new" />)} className="btn btn-circle btn-primary">
                  <i className="fa fa-plus" />
                </button>
                <PermissionBlocker permission={PermissionEnum.UPDATE_FIRMWARE}>
                  <button
                    className="btn btn-circle btn-primary"
                    onClick={async () => {
                      refreshLocationData();
                      showAppModal(<ManageFirmware latestFirmwares={await getLatestFirmwares()} selectedDevice={null} />);
                    }}>
                    <i id="gateway-settings" className="fa fa-cloud-upload" />
                  </button>
                </PermissionBlocker>
              </MobileButtons>
            </MobileSelectEntityRow>
            <MobileActionRow>
              <span>
                {selectedGateway?.name ?? t("dashboard:all_devices")} ({selectedGateway?.Devices.rows.length || getAllDevicesCount()})
                {!selectedGateway && AppState.selectedLocation && AppState.selectedLocation.lowBatteryCount > 0 ? (
                  <ClickAwayListener onClickAway={() => setShowLowBatteryInfo(false)}>
                    <button
                      className={classNames("btn btn-plain", { open: showLowBatteryInfo })}
                      onClick={() => setShowLowBatteryInfo(!showLowBatteryInfo)}>
                      <span className="icon-sprite icon-sprite-battery_0 u-text-teal" />
                      <section role="menu" className="dropdown-menu dropdown-menu-left-side">
                        <span style={{ padding: "0 8px", fontWeight: "normal" }}>
                          {t("dashboard:left_rail.gateways.battery_tooltip", { count: AppState.selectedLocation.lowBatteryCount })}
                        </span>
                      </section>
                    </button>
                  </ClickAwayListener>
                ) : null}
              </span>

              <MobileButtons style={{ gap: 4 }}>
                {selectedGateway ? (
                  <ClickAwayListener onClickAway={() => setGatewayDropdownOpen(false)}>
                    <button
                      className={classNames("btn btn-icon bapi-header-nav-item dropdown", { open: gatewayDropdownOpen })}
                      onClick={() => setGatewayDropdownOpen(!gatewayDropdownOpen)}>
                      <i className="fa fa-ellipsis-h u-text-teal" />
                      <section role="menu" className="dropdown-menu dropdown-menu-right">
                        <ul className="dropdown-list">
                          {gatewayMenuItems.map((item) => (
                            <li key={item.title} role="menuitem" className="dropdown-menu-item" onClick={item.action}>
                              <i className={`fa ${item.icon}`} />
                              <span>{item.title}</span>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </button>
                  </ClickAwayListener>
                ) : null}
                <button className="btn btn-icon" style={{ margin: 0 }} onClick={() => setSearchOpen(!searchOpen)}>
                  <i className="fa fa-search u-text-teal" />
                </button>
                <MobileDashboardFilters
                  key={sensorTypes.data?.length}
                  setSortBy={setSortBy}
                  setOrderBy={setOrderBy}
                  deviceTypes={AppState.selectedLocation?.filterOptions?.deviceTypes ?? []}
                  setDeviceFilters={setFilters}
                  sensorTypes={sensorTypes.data ?? []}
                  setChipLength={setChipLength}
                />
              </MobileButtons>
            </MobileActionRow>
            <SearchRow onChange={setSearchString} value={searchString} onClose={() => setSearchOpen(false)} open={searchOpen} />

            <div
              className={showFullFilters ? "chip-data-css-container full" : "chip-data-css-container"}
              ref={elementRef}
              style={{ width: "100%", paddingBottom: "10px", position: "relative" }}>
              {AppState.chipTemplate}
              {showMoreButton && (
                <p className="u-text-teal show-more-filters-button" onClick={() => setShowFullFilters(!showFullFilters)}>
                  {`+ ${!showFullFilters ? t("common:more") : t("common:less")}`}
                </p>
              )}
            </div>
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="groups">
            <MobileSelectEntityRow>
              <div style={{ flex: 1 }}>
                {groups?.length > 0 ? (
                  <SelectInput
                    className="input-filter-holder"
                    menuItemClass="dark"
                    inputClassName="dark"
                    style={{ margin: 0 }}
                    value={selectedGroup ?? groups[0]}
                    onChange={(group) => {
                      selectGateway(null);
                      selectGroup(group);
                    }}
                    options={groups.map((group) => ({
                      value: group,
                      label: `${group.name} (${group.Devices?.length || 0})`,
                    }))}
                  />
                ) : null}
              </div>
              <PermissionBlocker permission={PermissionEnum.DEVICE_GROUPS_MANAGEMENT}>
                <button onClick={() => showAppModal(<AddDeviceGroupModal />)} className="btn btn-circle btn-primary">
                  <i className="fa fa-plus" />
                </button>
              </PermissionBlocker>
            </MobileSelectEntityRow>
            <MobileActionRow>
              <span>
                {selectedGroup?.name ?? t("dashboard:all_devices")} ({selectedGroup?.Devices.length || 0})
              </span>
              {selectedGroup ? (
                <MobileButtons style={{ gap: 4 }}>
                  <button className="btn btn-plain btn-circle" onClick={() => setSearchOpen(!searchOpen)}>
                    <i className="fa fa-search" />
                  </button>
                  <ClickAwayListener onClickAway={() => setGroupDropdownOpen(false)}>
                    <button
                      className={classNames("btn btn-plain btn-circle bapi-header-nav-item dropdown", { open: groupDropdownOpen })}
                      onClick={() => setGroupDropdownOpen(!groupDropdownOpen)}>
                      <i className="fa fa-ellipsis-h" />

                      <section role="menu" className="dropdown-menu dropdown-menu-right">
                        <ul className="dropdown-list">
                          {groupMenuItems.map((item) => (
                            <li key={item.title} role="menuitem" className="dropdown-menu-item" onClick={item.action}>
                              <i className={`fa ${item.icon}`} />
                              <span>{item.title}</span>
                            </li>
                          ))}
                        </ul>
                      </section>
                    </button>
                  </ClickAwayListener>
                </MobileButtons>
              ) : null}
            </MobileActionRow>
            <SearchRow onChange={setSearchString} value={searchString} onClose={() => setSearchOpen(false)} open={searchOpen} />
          </TabPanel>
        </TabContext>
      </header>
    );
  },
);
