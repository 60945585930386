import React from "react";
import { useField } from "formik";
import classNames from "classnames";
import { IStandardInput } from "./Types";
import { InputLabel } from "./InputLabel";
import { InputError } from "./InputError";
import { observer } from "mobx-react-lite";
import TextField from "@mui/material/TextField";
import { InputBaseComponentProps } from "@mui/material";
import { StyledTooltip } from "./StyledTooltip";
import { useScreenMode } from "../Components";
import { WindowSize } from "../Enums";

interface IHint {
  className?: string;
  message: string;
}

interface IFormFieldTextProps extends IStandardInput {
  displayLabelInside?: boolean;
  hint?: string | IHint;
  icon?: React.ReactNode;
  inputClass?: string;
  inputProps?: InputBaseComponentProps;
  maxlength?: number;
  stepInput?: number;
}

interface ITextInputProps extends IFormFieldTextProps {
  hasError?: string;
  labelClassName?: string;
  touched?: boolean;
  value: string;
}

export const TextInput: React.FC<ITextInputProps> = observer(
  ({
    labelClassName,
    hasError,
    touched,
    name,
    style,
    stepInput,
    dark,
    displayError,
    className,
    label,
    required,
    inputProps = {},
    inputClass = "",
    displayLabelInside = false,
    maxlength,
    hint,
    tooltip,
    icon,
    ...props
  }) => {
    const mode = useScreenMode();

    return (
      <div style={style} className={classNames("form-group", className)}>
        {label && !displayLabelInside ? <InputLabel name={name} label={label} required={required} tooltip={tooltip} /> : null}
        <div className={classNames("mod-block input-holder", labelClassName)}>
          <TextField
            {...props}
            label={displayLabelInside ? label : ""}
            id={name}
            required={required}
            style={{ marginTop: displayLabelInside ? 8 : 0, backgroundColor: dark ? "#042b4a" : "transparent" }}
            className={classNames("input input-line", { labeled: displayLabelInside }, inputClass)}
            data-lpignore="true"
            inputProps={
              stepInput
                ? { step: stepInput, className: inputClass, maxLength: maxlength ?? undefined, ...inputProps }
                : {
                    ...inputProps,
                    className: inputClass,
                    maxLength: maxlength ?? undefined,
                  }
            }
          />
          {icon ? <div className="input-holder-icon">{icon}</div> : null}
          <div className={mode === WindowSize.DESKTOP ? "input-holder-icon" : ""}>
            {hint ? (
              <>
                <p style={{ margin: 0 }} className={classNames("u-mobile-only u-text-small", (hint as IHint)?.className ?? "")}>
                  {(hint as IHint)?.message ?? hint}
                </p>
                <StyledTooltip title={(hint as IHint)?.message ?? hint}>
                  <i className={classNames("fa  fa-question-circle u-mobile-hide", (hint as IHint)?.className ?? "")} />
                </StyledTooltip>
              </>
            ) : null}
            <InputError error={hasError} displayError={displayError} touched={touched ?? false} />
          </div>
        </div>
      </div>
    );
  },
);

export const FormFieldText: React.FC<IFormFieldTextProps> = ({ onChange, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <TextInput
      onChange={(e) => {
        field.onChange(e);
        onChange && onChange(e);
      }}
      labelClassName={meta.touched && Boolean(meta.error) ? "error" : ""}
      name={name}
      value={field.value}
      hasError={meta.error}
      touched={meta.touched}
      {...props}
    />
  );
};
