import { IExportPreset } from "./Types";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";

const API_PATH = "api/export_preset";
const QK_EXPORT_PRESETS = "export-presets";

export const addPreset = (preset: IExportPreset) =>
  Endpoint.post(API_PATH, preset).then((r) => {
    invalidateQuery(QK_EXPORT_PRESETS);
    return r.data;
  });

export const updatePreset = (preset: IExportPreset) =>
  Endpoint.put(`${API_PATH}/${preset._id}`, preset).then((r) => {
    invalidateQuery(QK_EXPORT_PRESETS);
    return r.data;
  });

export const getPresets = () => Endpoint.get<IExportPreset[]>(API_PATH).then((response) => response.data);

export const usePresets = () => useQuery(QK_EXPORT_PRESETS, getPresets);

export const deletePreset = (presetId: number) =>
  Endpoint.delete(`${API_PATH}/${presetId}`).then((r) => {
    invalidateQuery(QK_EXPORT_PRESETS);
    return r.data;
  });
