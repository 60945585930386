import { HeaderRow, IHeaderRowProps, SwitchInput, useScreenMode } from "../../Components";
import { isUserRoleAllowed, UserRoles, WindowSize } from "../../Enums";
import { AppState, showSnackbar } from "../../AppState";
import { formatPhone, getNameSlug, usePermissions } from "../../Managers";
import classNames from "classnames";
import { Button, Typography } from "@mui/material";
import { ILocation, IPermission, IUser } from "../../Managers/Types";
import React, { useEffect, useState } from "react";
import { resetPassword } from "../../Managers/UsersService";
import { useLocations } from "../../Managers/API";
import { getLocationsUnderAccount } from "../../Managers/LocationService";
import { useTranslation } from "react-i18next";
import "./Users.scss";

export const UserDetails: React.FC<{
  user: IUser;
  headerOptions: IHeaderRowProps;
  mobileHeader: JSX.Element;
  editUserButton: React.ReactNode;
  deleteUserButton: React.ReactNode;
  toggleNotification: (checked: boolean) => void;
}> = ({ user, headerOptions, mobileHeader, toggleNotification, deleteUserButton, editUserButton }) => {
  const [isSendingPassword, setIsSendingPassword] = useState(false);
  const [accountLocations, setAccountLocations] = useState<ILocation[]>([]);

  const { t } = useTranslation(["users", "common"]);

  const permissions = usePermissions();
  const locations = useLocations();
  const mode = useScreenMode();

  useEffect(() => {
    if (user?.AccountId === AppState.user?.AccountId && locations.data) {
      setAccountLocations(locations.data);
    } else if (user?.AccountId) {
      getLocationsUnderAccount(user.AccountId).then((res) => {
        setAccountLocations(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, locations.status]);

  if (!user) {
    return <></>;
  }

  let groupedPermissions = permissions.data?.reduce((r, a) => {
    r[a.access_group] = r[a.access_group] || [];
    r[a.access_group].push(a);
    return r;
  }, Object.create(null));

  const handleResetPassword = () => {
    if (!user) {
      return;
    }

    setIsSendingPassword(true);
    resetPassword(user)
      .then((r) => {
        const resetPasswordSuccessMessage = t("users:details.reset_password_success");
        showSnackbar(resetPasswordSuccessMessage, "success");
        console.log(resetPasswordSuccessMessage, r);
      })
      .catch((e) => {
        const resetPasswordErrorMessage = t("users:details.reset_password_error");
        console.log(resetPasswordErrorMessage, e);
        showSnackbar(resetPasswordErrorMessage, "error");
      })
      .finally(() => setIsSendingPassword(false));
  };

  const resetPasswordButton = () =>
    user.Permissions ? (
      <Button
        variant="text"
        className="reset-password-tablet"
        onClick={handleResetPassword}
        disabled={isSendingPassword}
        sx={{ paddingTop: 0 }}>
        <i className="fa fa-repeat" /> {t("users:details.send_password_reset")}
      </Button>
    ) : null;

  const dndButton = () => (
    <div className="u-mobile-only dnd-mobile-button">
      <div title={t("users:details.no_disturb_tooltip")}>
        <div className={classNames("input-label dnd-tooltip")}>{t("users:details.no_disturb")}</div>
      </div>
      <SwitchInput
        className="toggle"
        onChange={(e) => toggleNotification(e.target.checked)}
        checked={!user.has_notification}
        name="no_disturb"
      />
    </div>
  );

  return (
    <>
      {mode !== WindowSize.MOBILE ? <HeaderRow {...headerOptions} /> : mobileHeader}

      <section className={"main-panel users-main"}>
        {user.Permissions ? <div className="mobile-edit-user u-mobile-only"> {editUserButton}</div> : null}
        <div className="mobile-delete-user u-mobile-only"> {deleteUserButton}</div>
        {isUserRoleAllowed(UserRoles.APP_ADMIN) ? (
          <div className="row main-panel-row">
            <div className="col-sm-2">
              <Typography variant="h3">
                <p className="input-label">{t("users:details.account")}</p>
              </Typography>
              <p className={classNames("type-large-regular", { "u-color-alert u-text-bold": !user.Account })}>
                {user.Account?.name ?? "-"}
              </p>
            </div>
          </div>
        ) : null}
        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label"> {t("users:details.first_name")} </p>
            <p className="type-large-regular">{user.first_name}</p>
          </div>
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.last_name")}</p>
            <p className="type-large-regular">{user.last_name}</p>
          </div>
        </div>
        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.title")}</p>
            <p className="type-large-regular">{user.title}</p>
          </div>
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.role")}</p>
            <p className="type-large-regular">{t(`common:${user.role.toLowerCase()}`)}</p>
          </div>
        </div>

        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.phone_number")}</p>
            <p className="type-large-regular">
              {user.phone_code} {formatPhone(user.phone)}
            </p>
          </div>
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.email")}</p>
            <p className="type-large-regular">{user.email}</p>
          </div>
        </div>
        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("manage_user_profile:secondary_phone_number")}</p>
            <p className="type-large-regular">
              {user.secondary_phone ? `${user.secondary_phone_code} ${formatPhone(user.secondary_phone)}` : '-'}
            </p>
          </div>
          <div className="col-sm-5">
            <p className="input-label">{t("manage_user_profile:secondary_email_address")}</p>
            <p className="type-large-regular">{user.secondary_email && user.secondary_email.length ? user.secondary_email :  '-'}</p>
          </div>
        </div>
        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("manage_user_profile:notification_email")}</p>
            <p className="type-large-regular">
              {user.use_secondary_email ? user.secondary_email : user.email}
            </p>
          </div>
          <div className="col-sm-5">
            <p className="input-label">{t("manage_user_profile:marketing_email")}</p>
            <p className="type-large-regular">{user.use_secondary_email_for_marketing ? user.secondary_email : user.email}</p>
          </div>
        </div>
        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("manage_user_profile:notification_phone")}</p>
            <p className="type-large-regular">
              {user.use_secondary_phone ? `${user.secondary_phone_code} ${formatPhone(user.secondary_phone)}` : `${user.phone_code} ${formatPhone(user.phone)}`}
            </p>
          </div>
        </div>

        <div className="row main-panel-row">
          <div className="col-sm-12">
            <p className="input-label">{t("users:details.permissions")}</p>
            {Object.keys(groupedPermissions).map((key) => {
              return (
                <ul key={`${key}-${Math.floor(Math.random() * 10000)}`} className="row list-plain">
                  <li key={`${key}-${Math.floor(Math.random() * 10000)}`} className={`modal-row-title col-xs-12`}>
                    {key}
                  </li>
                  {groupedPermissions[key].map((permission: IPermission) => (
                    <li className="col-sm-5" key={permission._id}>
                      <input
                        type="checkbox"
                        className="input-checkbox"
                        disabled
                        readOnly
                        id={permission.name}
                        checked={
                          (user as IUser).Permissions?.some((p) => p._id === permission._id) ||
                          (user as any).permissionIds?.some((id: number) => id === permission._id)
                        }
                      />
                      <label htmlFor="{{permission.name}}" className="input-label-checkbox readonly">
                        {t(`users:permission.${getNameSlug(permission.label)}`)}
                      </label>
                    </li>
                  ))}
                </ul>
              );
            })}
          </div>
        </div>

        <div className="row main-panel-row">
          <div className="col-sm-5">
            <p className="input-label">{t("users:details.locations")}</p>
            <ul className="list-plain">
              {accountLocations.map((location) => (
                <li key={location._id}>
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    disabled
                    readOnly
                    id={`location-${location._id}`}
                    checked={
                      user.role !== UserRoles.USER ||
                      (user as IUser).Locations?.some((l) => l._id === location._id) ||
                      (user as any).locationIds?.some((id: number) => id === location._id)
                    }
                  />
                  <label htmlFor={`location-${location._id}`} className="input-label-checkbox readonly">
                    {location.name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-sm-5 mobile-bottom-buttons u-desktop-hide">
            {dndButton()}
            {resetPasswordButton()}
          </div>
        </div>

        {user.Permissions ? (
          <div className="main-panel-row u-desktop-only">
            <p className="input-label">{t("users:details.password_reset")}</p>
            <button type="button" className="btn btn-primary" onClick={handleResetPassword} disabled={isSendingPassword}>
              {isSendingPassword ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
              {t("users:details.send_password_reset")}
            </button>
            <br />
          </div>
        ) : null}
      </section>
    </>
  );
};
