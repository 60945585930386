import React, { useEffect, useState } from "react";
import { Page, RangePicker, SelectInput, useScreenMode, Wrapper } from "../../Components";
import { IAlertHistoryFilters, IDevice, IGateway, ILocation, INotification } from "../../Managers/Types";
import { CircularProgress, Drawer } from "@mui/material";
import "./AlertsHistory.scss";
import { getNameSlug, myOrder } from "../../Managers";
import { useDevicesWithProps } from "../../Managers/DeviceService";
import moment from "moment/moment";
import { isBatteryLowAlert, useSensorTypes } from "../../Managers/AlertService";
import { useNotificationHistory } from "../../Managers/NotificationService";
import { useTranslation } from "react-i18next";
import { PaginationButtons } from "../../Components/PaginationButtons";
import { FiltersIcon, LeftRailToggleIcon } from "../../icon";
import { AlertHistoryLeftRail } from "./AlertHistoryLeftRail";
import { OrderBy, WindowSize } from "../../Enums";
import { AlertsHistoryTable } from "./AlertHistoryTable";
import { AlertHistoryMobileFiltersModal } from "./modals/AlertHistoryMobileFiltersModal";

export const AlertsHistory: React.FC = () => {
  const { t } = useTranslation(["alert_history", "sensor_types", "common"]);

  const [searchString, setSearchString] = useState("");
  const [deviceOrder, setDeviceOrder] = useState<OrderBy>(OrderBy.ASC);
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [tabletAlertsHistoryListOpen, setTabletAlertsHistoryListOpen] = useState<boolean>(true);
  const initialFilters: IAlertHistoryFilters = {
    type: "all",
    sensorTypeId: undefined,
    show: 20,
    sortBy: "added",
    startDate: null,
    endDate: null,
  };

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<IAlertHistoryFilters>({ ...initialFilters });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);
  const [initialNotifications, setInitialNotifications] = useState<Array<INotification>>([]);
  const [sortedNotifications, setSortedNotifications] = useState<Array<INotification>>([]);
  const [paginatedNotifications, setPaginatedNotifications] = useState<Array<INotification>>([]);
  const [selectedGateways, setSelectedGateways] = useState<IGateway[]>([]);
  const [location, setLocation] = useState<ILocation>({ name: t("common:all_locations") } as ILocation);
  const [sortedDevices, setSortedDevices] = useState<IDevice[]>([]);
  const [showMobileFiltersModal, setShowMobileFiltersModal] = useState(false);

  const devices = useDevicesWithProps(["_id", "name", "serial_number", "is_active", "GatewayId"], true);

  const sensorTypes = useSensorTypes();
  const mode = useScreenMode();

  const notificationQuery = useNotificationHistory(
    moment(startDate).startOf("day").toISOString(true),
    moment(endDate).endOf("day").toISOString(true),
    true,
  );

  useEffect(() => {
    if (notificationQuery.data) {
      setInitialNotifications(notificationQuery.data);
      updateSortedNotifications(notificationQuery.data.slice());
    }
  }, [notificationQuery.dataUpdatedAt]);

  useEffect(() => {
    notificationQuery.refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    updatePaginatedNotifications();
  }, [currentPage, sortedNotifications, selectedFilters.show]);

  const updatePaginatedNotifications = () => {
    const startIndex = (currentPage - 1) * selectedFilters.show;
    const endIndex = Math.min(startIndex + selectedFilters.show, sortedNotifications.length);
    const newPaginatedNotifications = sortedNotifications.slice(startIndex, endIndex);
    setPaginatedNotifications(newPaginatedNotifications);
    const pageCount = Math.ceil(sortedNotifications.length / selectedFilters.show);
    setTotalPages(pageCount);
    const filteredNotifications = newPaginatedNotifications.slice(0, selectedFilters.show);
    setPaginatedNotifications(filteredNotifications);
  };

  useEffect(() => {
    setCurrentPage(1);
    setSortedNotifications(initialNotifications);
    setTotalPages(Math.ceil(initialNotifications.length / selectedFilters.show));
  }, [initialNotifications]);

  useEffect(() => {
    const filtersChanged = Object.keys(selectedFilters).some(
      (key) => selectedFilters[key as keyof IAlertHistoryFilters] !== initialFilters[key as keyof IAlertHistoryFilters],
    );

    if (filtersChanged) {
      console.log("updated selected filters", selectedFilters);
      updateSortedNotifications();
      setCurrentPage(1);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setCurrentPage(1);
    updateSortedNotifications();
  }, [selectedFilters, startDate, endDate, selectedDevice]);

  useEffect(() => {
    filterDevicesAndNotifications();
  }, [deviceOrder, devices.dataUpdatedAt]);

  const changeLocation = (loc: ILocation) => {
    setSelectedGateways(loc.Gateways ?? []);
    setSelectedDevice(null);
    setSearchString("");
    setLocation(loc);
    filterDevicesAndNotifications(loc.Gateways);
  };

  const selectGateway = (gatewaysToSelect: IGateway[]) => {
    setSelectedGateways(gatewaysToSelect);
    filterDevicesAndNotifications(gatewaysToSelect);
  };

  const filterDevicesAndNotifications = (gateways?: IGateway[]) => {
    if (devices) {
      let filteredDevices = myOrder(devices.data ?? [], "name", deviceOrder);

      if (gateways) {
        filteredDevices = filteredDevices.filter((d) => gateways.some((g) => g._id === d.GatewayId));
      }

      setSortedDevices(filteredDevices);

      const ids = filteredDevices.map((d) => d._id);

      setSortedNotifications(initialNotifications.filter((n) => ids.some((id) => id === n.Sensor?.DeviceId)));
    }
  };
  const updateSortedNotifications = (notifications = initialNotifications) => {
    const isTypeValid = selectedFilters.type !== "";
    const isSensorTypeValid = selectedFilters.sensorTypeId !== undefined && selectedFilters.sensorTypeId > -1;
    const isShowValid = selectedFilters.show !== undefined && selectedFilters.show > 0;
    const isSortByValid = selectedFilters.sortBy !== "";
    const isStartDateValid = selectedFilters.startDate !== null;
    const isEndDateValid = selectedFilters.endDate !== null;

    setActiveFiltersCount(0);

    console.warn(selectedFilters);
    if (isTypeValid || isSensorTypeValid || isShowValid || isSortByValid || isStartDateValid || isEndDateValid) {
      let filteredNotifications = [...notifications];
      if (isSortByValid) {
        setActiveFiltersCount((prev) => prev + 1);
        switch (selectedFilters.sortBy) {
          case "added":
            filteredNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            break;
          case "asc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueA - valueB;
            });
            break;
          case "desc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueB - valueA;
            });
            break;
          default:
            break;
        }
      }

      if (isSensorTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);

        filteredNotifications = filteredNotifications.filter((notification) => {
          return notification.Sensor?.Sensor_type._id === selectedFilters.sensorTypeId;
        });
      }

      if (isTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);
        const showAllTypes = selectedFilters.type === "all";
        if (!showAllTypes) {
          if (selectedFilters.type === "transmitting") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return notification.Alert?.is_notify_on_non_transmitting;
            });
          } else if (selectedFilters.type === "battery_alert") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return isBatteryLowAlert(notification.Alert);
            });
          } else if (selectedFilters.type === "range") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return notification.Alert?.max !== null && notification.Alert?.min !== null;
            });
          } else if (selectedFilters.type === "max") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return notification.Alert?.max !== null && notification.Alert?.min === null;
            });
          } else if (selectedFilters.type === "min") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return notification.Alert?.min !== null && notification.Alert?.max === null;
            });
          } else if (selectedFilters.type === "boolean") {
            filteredNotifications = filteredNotifications.filter((notification) => {
              return (
                notification.Sensor?.Sensor_type?.type.toLowerCase() === "boolean" &&
                notification.Alert?.min !== null &&
                notification.Alert?.max !== null
              );
            });
          }
        }
      }

      if (selectedDevice) {
        filteredNotifications = filteredNotifications.filter((notification) => {
          return notification.Sensor?.DeviceId === selectedDevice._id;
        });
      }

      setSortedNotifications(filteredNotifications);
      updatePaginatedNotifications();
    }
  };

  if (notificationQuery.isLoading) {
    return (
      <Page>
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page>
      {
        <Wrapper>
          {mode !== WindowSize.MOBILE ? (
            <Drawer
              variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
              hideBackdrop={mode === WindowSize.DESKTOP}
              onBackdropClick={() => mode === WindowSize.TABLET && setTabletAlertsHistoryListOpen(false)}
              open={tabletAlertsHistoryListOpen || mode === WindowSize.DESKTOP}>
              <AlertHistoryLeftRail
                setSelectedDevice={setSelectedDevice}
                devices={sortedDevices}
                searchString={searchString}
                setSearchString={setSearchString}
                setDeviceOrder={setDeviceOrder}
                deviceOrder={deviceOrder}
                selectedDevice={selectedDevice}
                changeLocation={changeLocation}
                location={location}
                selectedGateways={selectedGateways}
                selectGateways={selectGateway}
              />
            </Drawer>
          ) : null}

          <main className="bapi-main mod-dashboard alerts-history-main">
            <div className="filters-wrapper u-tablet-hide">
              <span style={{ margin: "auto 24px auto 50px" }}>{selectedDevice?.name || t("alert_history:no_selected_device")}</span>
              <RangePicker
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "40px",
                }}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                endDate={endDate}
                startDate={startDate}
              />

              <div className="select-filters-wrapper">
                {mode === WindowSize.DESKTOP ? (
                  <SelectInput
                    required={false}
                    displayError={false}
                    className="input-holder input-filter-holder"
                    label={t("alert_history:type")}
                    onChange={(type) => setSelectedFilters({ ...selectedFilters, type })}
                    options={[
                      { value: "all", key: "all" },
                      { value: "range", key: "range" },
                      { value: "max", key: "max" },
                      { value: "min", key: "min" },
                      { value: "boolean", key: "boolean" },
                      { value: "battery_alert", key: "battery_alert" },
                      { value: "transmitting", key: "not_transmitting" },
                    ].map((o) => ({ ...o, label: t("alert_history:sensor_type", { context: o.key }) }))}
                    value={selectedFilters.type}
                  />
                ) : null}

                {sensorTypes.data ? (
                  <>
                    <SelectInput
                      required={false}
                      displayError={false}
                      className="input-holder input-filter-holder"
                      label={t("alert_history:sensor_type")}
                      onChange={(selectedOption) => {
                        setSelectedFilters((prevFilters) => ({
                          ...prevFilters,
                          sensorTypeId: selectedOption,
                        }));
                      }}
                      options={[
                        { value: -1, label: t("alert_history:all"), key: undefined },
                        ...(sensorTypes.data || []).map((type) => ({
                          value: type._id,
                          label: t(`sensor_types:${getNameSlug(type.name)}`),
                          key: type._id.toString(),
                        })),
                      ]}
                      value={selectedFilters.sensorTypeId ?? -1}
                    />
                  </>
                ) : (
                  <div>{t("alert_history:loading_sensor_types")}</div>
                )}

                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder narrow-filter"
                  label={t("alert_history:show")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      show: selectedOption,
                    }));
                  }}
                  options={[
                    { value: 10, label: "10", key: "show-10" },
                    { value: 20, label: "20", key: "show-20" },
                    { value: 30, label: "50", key: "show-50" },
                    { value: 100, label: "100", key: "show-100" },
                  ]}
                  value={selectedFilters.show}
                />

                <SelectInput
                  required={false}
                  displayError={false}
                  className="input-holder input-filter-holder"
                  label={t("common:sort_by")}
                  onChange={(selectedOption) => {
                    setSelectedFilters((prevFilters) => ({
                      ...prevFilters,
                      sortBy: selectedOption,
                    }));
                  }}
                  options={[
                    { value: "added", label: t("alert_history:last_added"), key: "last-added" },
                    {
                      value: "desc",
                      label: t("alert_history:alert_value", { context: "desc" }),
                      key: "alert-value-desc",
                    },
                    { value: "asc", label: t("alert_history:alert_value", { context: "asc" }), key: "alert-value-asc" },
                  ]}
                  value={selectedFilters.sortBy}
                />
              </div>
            </div>
            <div className="filters-wrapper-mobile-tablet u-desktop-hide">
              <div className="device-name">
                <div className="left-rail-toggle-wrapper alerts-left-rail-icon-wrapper u-tablet-only">
                  <button className="btn btn-icon" onClick={() => setTabletAlertsHistoryListOpen(!tabletAlertsHistoryListOpen)}>
                    <LeftRailToggleIcon />
                  </button>
                </div>
                {selectedDevice?.name || t("alert_history:no_selected_device")}
              </div>

              <div className="filters-mobile-icon" onClick={() => setShowMobileFiltersModal(true)}>
                <button className="btn btn-icon">
                  <FiltersIcon />
                </button>
                <div className="mobile-active-filters-count">({activeFiltersCount})</div>
              </div>
            </div>
            <AlertsHistoryTable onDelete={() => notificationQuery.refetch()} sortedNotifications={paginatedNotifications} />
            {paginatedNotifications.length > 0 && (
              <div className="text-right pagination-text-showing">
                {t("alert_history:shown_alerts", {
                  from: (currentPage - 1) * selectedFilters.show + 1,
                  to: Math.min(currentPage * selectedFilters.show, sortedNotifications.length),
                })}
              </div>
            )}

            {paginatedNotifications?.length > 0 ? <PaginationButtons onPageChange={setCurrentPage} pageCount={totalPages} /> : null}
          </main>

          {showMobileFiltersModal && mode === WindowSize.MOBILE ? (
            <AlertHistoryMobileFiltersModal
              selectedGateways={selectedGateways}
              selectGateways={selectGateway}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setFilters={setSelectedFilters}
              filters={selectedFilters}
              setSearchString={setSearchString}
              searchString={searchString}
              devices={sortedDevices}
              setSelectedDevice={setSelectedDevice}
              changeLocation={changeLocation}
              location={location}
              close={() => setShowMobileFiltersModal(false)}
            />
          ) : null}
        </Wrapper>
      }
    </Page>
  );
};
