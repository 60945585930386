import React, { useState } from "react";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import { deletePreset, usePresets } from "../../../Managers";
import { showAppModal, showSnackbar } from "../../../AppState";
import { List, ListItem, TextInput } from "../../../Components";
import { TrashIcon } from "../../../icon/trash";
import { ExportFilter } from "../Export";
import classNames from "classnames";
import { IExportPreset } from "../../../Managers/Types";
import { ConfirmModal } from "../../../Modals";

export const PresetModal: React.FC<{
  filters: ExportFilter;
  handleSelectPreset: (preset?: IExportPreset) => void;
  savePreset: (preset: IExportPreset) => Promise<void>;
  preset?: IExportPreset;
}> = ({ savePreset, preset, handleSelectPreset, filters }) => {
  const [presetName, setPresetName] = useState(preset?.name || "");
  const [selectedPreset, setSelectedPreset] = useState<IExportPreset | undefined>(preset);

  const { t } = useTranslation(["common", "export"]);
  const presets = usePresets();

  const handleSave = () => {
    savePreset({ name: presetName } as IExportPreset)
      .then(() => {
        showSnackbar(t("export:save_preset_success"), "success");
        showAppModal(null);
      })
      .catch(() => showSnackbar(t("export:save_preset_error"), "error"));
  };

  const handleDelete = (presetToDelete: IExportPreset) => {
    if (presetToDelete._id) {
      deletePreset(presetToDelete._id)
        .then(() => {
          let presetToShow = preset;
          if (presetToDelete._id === preset?._id) {
            handleSelectPreset(undefined);
            presetToShow = undefined;
          }
          showSnackbar(t("export:delete_preset_success"), "success");
          showAppModal(
            <PresetModal filters={filters} savePreset={savePreset} preset={presetToShow} handleSelectPreset={handleSelectPreset} />,
          );
        })
        .catch(() => showSnackbar(t("export:delete_preset_error"), "error"));
    }
  };

  const handleDeleteClick = (preset: IExportPreset) => {
    showAppModal(
      <ConfirmModal header={t("export:delete_preset")} onConfirm={() => handleDelete(preset)}>
        {t("export:delete_preset_content", { presetName: preset.name })}
      </ConfirmModal>,
    );
  };

  const selectPreset = (preset: IExportPreset) => {
    const isSelect = preset._id !== selectedPreset?._id;
    setSelectedPreset(isSelect ? preset : undefined);
    setPresetName(isSelect ? preset.name : "");
  };

  return (
    <Modal
      className="modal-sm"
      title={t("export:save_or_load_preset")}
      buttons={
        <>
          <button className="btn btn-info" onClick={() => showAppModal(null)}>
            {t("common:close")}
          </button>
          <button
            onClick={() => {
              if (selectedPreset) {
                handleSelectPreset(selectedPreset);
                showAppModal(null);
              }
            }}
            disabled={!selectedPreset}
            className="btn btn-primary">
            {t("common:load")}
          </button>
        </>
      }>
      <div className="preset-name-wrapper">
        <TextInput
          onChange={(event) => setPresetName(event.target.value)}
          value={presetName}
          name="preset_name"
          label={t("export:save_preset")}
        />
        <div>
          <button disabled={!presetName} className="btn btn-primary" onClick={handleSave}>
            {selectedPreset ? t("common:update") : t("common:save")}
          </button>
        </div>
      </div>
      <div>
        <span>{t("export:load_preset")}</span>
        <List className="select-group">
          {(presets.data ?? []).map((preset) => (
            <ListItem
              key={preset._id}
              className={classNames("select-group-item", { selected: preset._id === selectedPreset?._id })}
              onClick={() => selectPreset(preset)}>
              <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>{preset.name}</div>
                <button
                  onClick={() => handleDeleteClick(preset)}
                  className={classNames("btn btn-plain", { "u-text-teal": preset._id !== selectedPreset?._id })}>
                  <TrashIcon />
                </button>
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Modal>
  );
};
