import { Card, FormFieldSelect, FormFieldText, InlineButton, Stepper, StepperActions, StyledTooltip, TotalPrice } from "../Components";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import "./UpdatePlanModal.scss";
import { IOptionPrice, IPaymentOptions } from "../Managers/Types";
import { GetSelectedAndAvailablePaymentOptions } from "../Managers/PaymentOptionsService";
import { GetActiveStepsUpdatePlan, UpdatePlanSteps, ValidationMessages } from "../Enums";
import { Chip } from "@mui/material";
import { UpdatePlanConfirmationStep } from "./BillingInfo/UpdatePlanConfirmationStep";
import { AppState, showAppModal } from "../AppState";
import { DeleteEntriesModal } from "./BillingInfo/DeleteEntriesModal";
import { BillingInfo } from "./BillingInfo/BillingInfo";
import { useTranslation } from "react-i18next";

interface IPlanStepProps {
  planDetails?: IPlanProps;
  planOptions?: IPaymentOptions;
  calculateTotal: (values: IPlanProps) => Promise<number | null>;
  currentDevicesCount?: number;
  currentUsersCount?: number;
  storedProps?: IPlanProps;
}

export interface IPlanProps {
  basePriceOptionId?: number | null;
  numberOfDevicesOptionId?: number | null;
  numberOfDevices?: number | null;
  smsNotifications?: number | null;
  transmissionRate?: number;
  numberOfUsers?: number;
}

const PricingOption: React.FC<{
  children?: React.ReactNode;
  label: string | React.ReactNode;
  selected: boolean;
  tooltipText?: string;
}> = ({ children, label, tooltipText }) => {
  return (
    <div className="pricing-plan-option">
      <div className="pricing-plan-label">
        {label}{" "}
        {tooltipText && (
          <StyledTooltip title={tooltipText}>
            <i className="fa fa-info-circle" />
          </StyledTooltip>
        )}
      </div>
      <div className="pricing-plan-content">{children}</div>
    </div>
  );
};

export const UpdatePlanModal: React.FC<IPlanStepProps> = ({ calculateTotal, currentUsersCount, currentDevicesCount, storedProps }) => {
  const [options, setOptions] = useState<IPaymentOptions>();
  const [initialValues, setInitialValues] = useState<IPlanProps | undefined>(storedProps);
  const [updatedValues, setUpdatedValues] = useState<IPlanProps>();
  const [totalPrice, setTotalPrice] = useState(100);
  const [step, setStep] = useState(UpdatePlanSteps.UpdatePlan);
  const [firstCheckout, setFirstCheckout] = useState(false);
  const [minInterval, setMinInterval] = useState<number>();

  const { t } = useTranslation(["update_plan_modal", "common"]);

  useEffect(() => {
    GetSelectedAndAvailablePaymentOptions().then((res) => {
      setFirstCheckout(!res.selectedOptions.numberOfDevices);
      setOptions(res.availableOptions);
      setInitialValues({
        basePriceOptionId: updatedValues?.basePriceOptionId ?? res.selectedOptions.basePrice ?? res.availableOptions.basePrice.id,
        numberOfDevicesOptionId:
          updatedValues?.numberOfDevicesOptionId ?? res.selectedOptions.numberOfDevices?.id ?? res.availableOptions.numberOfDevices.id,
        numberOfDevices: updatedValues?.numberOfDevices ?? res.selectedOptions.numberOfDevices?.amount ?? 0,
        smsNotifications:
          updatedValues?.smsNotifications ?? res.selectedOptions.smsNotifications ?? res.availableOptions?.smsNotifications.options[0].id,
        transmissionRate:
          updatedValues?.transmissionRate ??
          res.selectedOptions.transmissionRate?.id ??
          res.availableOptions?.transmissionRate.options[0].id,
        numberOfUsers:
          updatedValues?.numberOfUsers ?? res.selectedOptions.numberOfUsers ?? res.availableOptions?.numberOfUsers.options[0].id,
      });

      if (res.selectedOptions.transmissionRate.minInterval) setMinInterval(res.selectedOptions.transmissionRate.minInterval);
    });
  }, [updatedValues]);

  const validationSchema = yup.object({
    basePriceOptionId: yup.number(),
    numberOfDevicesOptionId: yup.number(),
    numberOfDevices: yup
      .number()
      .required(t(ValidationMessages.REQUIRED))
      .integer(t(ValidationMessages.INTEGER))
      .positive(t(ValidationMessages.POSITIVE))
      .min(
        currentDevicesCount || 1,
        t("update_plan_modal:devices_validation_message", {
          count: currentDevicesCount,
          context: currentUsersCount ? "exceed" : "initial",
        }),
      )
      .max(200, t("common:formik_errors.not_greater_than", { value: 200 })),
    smsNotifications: yup.number().nullable(),
    transmissionRate: yup.number(),
    numberOfUsers: yup
      .number()
      .test("min", currentUsersCount ? t("update_plan_modal:users_validation_message", { count: currentUsersCount }) : "", (value) => {
        return currentUsersCount && value
          ? (options?.numberOfUsers.options.find((o) => o.id === value)?.value ?? 0) >= currentUsersCount
          : true;
      }),
  });

  const getOptions = (opts: Array<IOptionPrice>) =>
    opts.map((opt) => ({
      value: opt.id,
      key: opt.name,
      label: opt.name,
    }));

  const getStep = () => {
    switch (step) {
      case UpdatePlanSteps.UpdatePlan:
        return getUpdatePlanStep();
      case UpdatePlanSteps.UpdatePlanConfirmationStep:
        return (
          <UpdatePlanConfirmationStep
            price={totalPrice}
            onClickBack={() => setStep(step - 1)}
            options={options}
            firstCheckout={firstCheckout}
            planDetails={updatedValues}
            isConfirming={false}
          />
        );
      default:
        return <StepperActions onClickBack={() => setStep(step - 1)} onClickNext={() => setStep(step + 1)} />;
    }
  };

  const hasUnsavedChanges = (dirty: boolean): boolean => {
    return dirty || (updatedValues !== undefined ? JSON.stringify(initialValues) !== JSON.stringify(updatedValues) : false);
  };

  const openDeleteEntriesModal = (values: IPlanProps) => {
    showAppModal(
      <DeleteEntriesModal
        props={values}
        devicesToDelete={
          currentDevicesCount && values.numberOfDevices && currentDevicesCount > values.numberOfDevices
            ? currentDevicesCount - values.numberOfDevices
            : 0
        }
        usersToDelete={
          currentUsersCount && values.numberOfUsers && currentUsersCount > values.numberOfUsers
            ? currentUsersCount - values.numberOfUsers
            : 0
        }
      />,
    );
  };

  const onSubmit = async (values: IPlanProps) => {
    setUpdatedValues(values);
    const price = await calculateTotal(values);
    setTotalPrice(price ?? 0);
    setStep(step + 1);
  };

  const getDevicesWithSmallerTransmissionRate = (values: IPlanProps) => {
    const selectedTransmissionRate = options?.transmissionRate.options.find((o) => o.id === values.transmissionRate);

    return selectedTransmissionRate && minInterval && selectedTransmissionRate.value > minInterval / 60;
  };

  const getUpdatePlanStep = () => {
    return !!options && !!initialValues ? (
      <Formik validateOnMount={true} validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
        {({ values, dirty, submitForm }) => {
          const unsavedChanges = hasUnsavedChanges(dirty) || !!storedProps;
          const isAccountBlocked = AppState.user?.Account?.blocked;

          return (
            <>
              <div className="type-large-regular header-update-plan">
                {t("update_plan_modal:update_plan")}
                {unsavedChanges ? (
                  <Chip className="u-text-uppercase chip-update-plan" color="warning" label={t("common:unsaved_changes")} size="small" />
                ) : null}
              </div>
              <div className="pricing-options-wrapper">
                <PricingOption
                  selected={true}
                  label={t("update_plan_modal:number_of_devices")}
                  tooltipText={t("update_plan_modal:steps.plan.device_number_desc")}>
                  <FormFieldText stepInput={1} type="number" name="numberOfDevices" required={true} displayError={true} />
                </PricingOption>
                <PricingOption
                  selected={true}
                  label={t("update_plan_modal:number_of_users")}
                  tooltipText={t("update_plan_modal:steps.plan.users_number_desc")}>
                  <FormFieldSelect
                    inputClassName="input input-line"
                    displayError={true}
                    options={getOptions(options.numberOfUsers.options)}
                    name="numberOfUsers"
                  />
                </PricingOption>
                <PricingOption
                  selected={true}
                  label={t("update_plan_modal:transmission_rate")}
                  tooltipText={t("update_plan_modal:steps.plan.transmission_rate_desc")}>
                  <FormFieldSelect
                    inputClassName="input input-line"
                    options={getOptions(options.transmissionRate.options)}
                    name="transmissionRate"
                  />
                </PricingOption>
                <PricingOption
                  selected={true}
                  label={t("update_plan_modal:email_notifications")}
                  tooltipText={t("update_plan_modal:steps.plan.email_notifications_desc")}>
                  {t("update_plan_modal:unlimited")}
                </PricingOption>
                {options?.smsNotifications?.options !== undefined ? (
                  <PricingOption
                    selected={true}
                    label={t("update_plan_modal:sms_notifications")}
                    tooltipText={t("update_plan_modal:steps.plan.sms_notifications_desc")}>
                    <FormFieldSelect
                      inputClassName="input input-line"
                      options={getOptions(options.smsNotifications.options)}
                      name="smsNotifications"
                    />
                  </PricingOption>
                ) : null}
              </div>
              <div
                style={{
                  display:
                    (currentUsersCount && values.numberOfUsers && currentUsersCount > values.numberOfUsers) ||
                    (currentDevicesCount && values.numberOfDevices && currentDevicesCount > values.numberOfDevices)
                      ? "inherit"
                      : "none",
                }}
                className="update-plan-form-note bordered-info-text bordered-info-text--warning">
                {t("update_plan_modal:exceeded_limit_info")}
                <InlineButton style={{ marginLeft: "1ch", marginRight: "1ch" }} onClick={() => openDeleteEntriesModal(values)}>
                  {t("update_plan_modal:remove_entries")}
                </InlineButton>{" "}
                {t("update_plan_modal:exceeded_limit_info_2")}
              </div>
              <div
                style={{
                  display: getDevicesWithSmallerTransmissionRate(values) ? "inherit" : "none",
                }}
                className="update-plan-form-note bordered-info-text bordered-info-text--warning">
                {t("update_plan_modal:devices_smaller_transmission_rate")}
              </div>
              <TotalPrice calculateTotal={calculateTotal} values={values} />
              <StepperActions
                onClickBack={() => showAppModal(<BillingInfo />)}
                onClickNext={submitForm}
                previousButtonMessage={"Go back"}
                buttonDisabled={!unsavedChanges && !isAccountBlocked}
              />
            </>
          );
        }}
      </Formik>
    ) : null;
  };

  return (
    <Card className="update-plan-card" label={t("update_plan_modal:update_plan_button")}>
      <Stepper activeStep={step} className="update-plan-stepper" steps={GetActiveStepsUpdatePlan()}>
        {getStep()}
      </Stepper>
    </Card>
  );
};
