import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { SelectInput, useScreenMode } from "../../Components";
import { DeviceSort, DeviceSortEnum, OrderBy } from "../../Enums";
import { AppState, setChipCount, setChipModalConfig, setChipTemplate, setDashboardFilters, setDashboardSorting } from "../../AppState";
import { IDeviceType, IDeviceTypeGroup } from "../../Managers/Types";
import { Checkbox, Chip, MenuItem } from "@mui/material";
import { CloseIcon } from "../../icon";
import { filterIntervalOptions, getTransmitIntervalOptions } from "../../Managers/DeviceService";
import { ISensorType } from "../../Managers/Alert.model";
import { Modal } from "../../Components/Modal";
import { AdditionalChipModal } from "../../Components/AdditionalChipModal";
import { WindowSize } from "../../Enums";
import classNames from "classnames";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export interface IDashboardFilters {
  deviceTypes: IDeviceType[];
  setOrderBy: (by: OrderBy) => void;
  setSortBy: (by: DeviceSort) => void;
  setDeviceFilters: React.Dispatch<React.SetStateAction<any>>;
  sensorTypes: ISensorType[];
  enclosureTypes: IDeviceTypeGroup[];
  filtersOpen?: boolean;
  setFiltersOpen?: React.Dispatch<React.SetStateAction<any>>;
  setChipLength: React.Dispatch<React.SetStateAction<any>>;
}

export interface IChipData {
  key: number | string;
  label: string;
  fullLabel: string;
  type: FilterChipEnum;
  setStateFn?: React.Dispatch<React.SetStateAction<any>>;
}

enum FilterChipEnum {
  DeviceType = "device_type",
  DeviceStatus = "device_status",
  SensorType = "sensor_type",
  SampleInterval = "sample_interval",
  TransmitInterval = "transmit_interval",
  Approaching = "approaching",
  InAlert = "in_alert",
}

export const DashboardFilters: React.FC<IDashboardFilters> = observer(
  ({ setChipLength, deviceTypes, enclosureTypes, setOrderBy, setSortBy, setDeviceFilters, sensorTypes, filtersOpen, setFiltersOpen }) => {
    const { t } = useTranslation(["dashboard", "common"]);

    const windowMode = useScreenMode();
    const maxChipWidth = windowMode === WindowSize.MOBILE ? 70 : 200; //feelscraft

    const [deviceType, setDeviceType] = useState<number[]>([]);
    const [deviceStatus, setDeviceStatus] = useState<(boolean | undefined)[]>([]);
    const [sensorType, setSensorType] = useState<number[]>([]);
    const [sampleInterval, setSampleInterval] = useState<number[]>([]);
    const [transmitInterval, setTransmitInterval] = useState<number[]>([]);
    const [approaching, setApproaching] = useState<(boolean | undefined)[]>([]);
    const [inAlert, setInAlert] = useState<(boolean | undefined)[]>([]);
    const [overflow, setOverflow] = useState<boolean>(false);
    const [visibleChipCount, setVisibleChipCount] = useState<number>(99999);
    const [chipData, setChipData] = useState<IChipData[]>([]);
    const chipRef = useRef<any>();

    const sampleIntervalOptions = filterIntervalOptions([
      { value: 60, label: t("common:minutes", { count: 1 }) },
      { value: 5 * 60, label: t("common:minutes", { count: 5 }) },
    ]).map((el) => {
      return { ...el, selected: sampleInterval.some((t) => t === el.value) };
    });
    const transmitIntervalOptions = getTransmitIntervalOptions(t).map((el) => {
      return { ...el, selected: transmitInterval.some((t) => t === el.value) };
    });
    const deviceTypeOptions = [
      ...deviceTypes.map((type) => {
        return { value: type._id, label: type.name, selected: deviceType.some((t) => t === type._id) };
      }),
    ];

    const deviceStatusOptions = [true, false].map((value) => {
      return {
        value: value,
        label: t("dashboard:filter.device_status_" + value),
        selected: deviceStatus.some((s) => value === s),
      };
    });

    const sensorTypeOptions = sensorTypes?.map((type) => {
      return { value: type._id, label: type.name, selected: sensorType.some((t) => t === type._id) };
    });

    const approachingOptions = [true, false].map((value) => {
      return {
        value: value,
        label: t("dashboard:filter.approaching_" + value),
        selected: approaching.some((s) => value === s),
      };
    });

    const inAlertOptions = [true, false].map((value) => {
      return {
        value: value,
        label: t("dashboard:filter.in_alert_" + value),
        selected: inAlert.some((s) => value === s),
      };
    });

    const sortingOptions = [
      ...[DeviceSortEnum.Updated, DeviceSortEnum.Name]
        .map((prop) =>
          [OrderBy.ASC, OrderBy.DESC].map((dir) => {
            const prefix = prop === DeviceSortEnum.Updated ? "updated" : "name";

            return {
              value: prop + "," + dir,
              label: t(`dashboard:sort_by.${prefix}`, { context: dir.toLowerCase() }),
            };
          }),
        )
        .flat(),
      {
        value: DeviceSortEnum.Warning + "," + OrderBy.ASC,
        label: t("dashboard:sort_by.warning", { context: OrderBy.ASC.toLowerCase() }),
      },
      {
        value: DeviceSortEnum.Alert + "," + OrderBy.ASC,
        label: t("dashboard:sort_by.alert", { context: OrderBy.ASC.toLowerCase() }),
      },
      ...enclosureTypes.map((g) => ({
        value: g._id + "," + OrderBy.ASC,
        label: g.name + " - " + t("dashboard:sort_by.on_top"),
      })),
    ];

    const getLabel = (label: string) => {
      return label;
      // if (label.length < 12) {
      //   return label;
      // }
      // const arr = label.split(" ");
      // return arr.length > 1 ? arr.reduce((acc, val) => acc + val.slice(0, 3) + ". ", "") : arr[0].slice(0, 12);
    };

    const castOptionToChip = (value: any, options: any, type: FilterChipEnum, setStateFn: any, label: string) => {
      if (Array.isArray(value))
        return value.map((r: any) => {
          return {
            label: getLabel(label) + ": " + options.find((ch: any) => ch.value.toString() === r.toString())?.label ?? "",
            fullLabel: `${label}: ${options.find((ch: any) => ch.value.toString() === r.toString())?.label ?? ""}`,
            key: r.toString(),
            type: type,
            setStateFn,
          };
        });
      return [
        {
          label: getLabel(label) + ": " + options.find((ch: any) => ch.value?.toString() === value?.toString())?.label ?? "",
          fullLabel: `${label}: ${options.find((ch: any) => ch.value?.toString() === value?.toString())?.label ?? ""}`,
          key: value ?? value?.toString() ?? t("common:all"),
          type: type,
          setStateFn,
        },
      ];
    };

    const changeSort = useCallback((sortString: string) => {
      const [sortBy, orderBy] = sortString.split(",");
      setOrderBy(orderBy as OrderBy);
      setSortBy(sortBy as DeviceSort);
      setDashboardSorting(sortBy as DeviceSort, orderBy as OrderBy);
    }, []);

    const setFilters = useCallback(() => {
      const filter = {
        transmitInterval: transmitInterval,
        sampleInterval: sampleInterval,
        offline: deviceStatus,
        inAlert: inAlert,
        deviceType: deviceType,
        sensorType: sensorType,
        approaching: approaching,
      };
      setDeviceFilters(filter);
      setDashboardFilters(filter);
    }, [transmitInterval, sampleInterval, deviceStatus, inAlert, deviceType, sensorType, approaching]);

    const handleDeleteChip = (chipToDelete: IChipData) => () => {
      if (chipToDelete.setStateFn)
        chipToDelete.setStateFn((values: any) => values.filter((el: any) => el.toString() !== chipToDelete.key.toString()));
      setChipData((chips) => chips.filter((chip) => chip.label !== chipToDelete.label));
    };

    useEffect(() => {
      setFilters();
    }, [setFilters, transmitInterval, sampleInterval, deviceStatus, inAlert, deviceType, sensorType]);

    const toggleChipValue = useCallback(
      (values, options, stateFn, type, label) => {
        stateFn(values);
        const data = [...chipData.filter((c) => c.type !== type), ...castOptionToChip(values, options, type, stateFn, label)];
        setChipData(data);
        setChipLength(data.length);
      },
      [chipData],
    );

    const showDelete = useCallback((data: IChipData) => {
      if (!data.type) return true;
      return [FilterChipEnum.TransmitInterval, FilterChipEnum.SampleInterval].includes(data.type);
    }, []);

    const isOverflowActive = (event: any) => {
      const viewportWidth = event.offsetWidth;
      const predictedWidth = chipData.length * maxChipWidth;
      return viewportWidth < predictedWidth;
    };

    const getVisibleChipCount = (event: any) => {
      return Math.floor(event.offsetWidth / maxChipWidth);
    };

    useEffect(() => {
      if (windowMode === WindowSize.MOBILE) {
        setVisibleChipCount(2);
        setOverflow(true);
        return;
      }
      if (chipRef.current && isOverflowActive(chipRef.current)) {
        setOverflow(true);
        setVisibleChipCount(getVisibleChipCount(chipRef.current));
        return;
      }
      setVisibleChipCount(9999);
      setOverflow(false);
    }, [isOverflowActive]);

    const [showMoreButton, setShowMoreButton] = useState(false);
    const [showFullFilters, setShowFullFilters] = useState(false);
    const elementRef = useCallback((node) => {
      if (!node) return;
      const resizeObserver = new ResizeObserver(() => {
          setShowMoreButton(node.clientHeight >= 55);
      });
      resizeObserver.observe(node);
    }, []);

    const renderOptions = (options: Array<any>, currentState: any, stateFn: any, type: FilterChipEnum, label: string) =>
      options.map((option, i) => (
        <MenuItem
          onClick={() =>
            toggleChipValue(
              option.selected ? currentState.filter((el: any) => el != option.value) : [...currentState, option.value],
              options,
              stateFn,
              type,
              label,
            )
          }
          className={"filter-option"}
          key={i + option.value + option.label + type}
          value={option.value}>
          <Checkbox
            checked={option.selected}
            onChange={() =>
              toggleChipValue(
                option.selected ? currentState.filter((el: any) => el != option.value) : [...currentState, option.value],
                options,
                stateFn,
                type,
                label,
              )
            }
          />
          {option.label}
        </MenuItem>
      ));

    const filters: Array<{ state: any; stateFn: any; options: Array<any>; type: FilterChipEnum; label: string }> = [
      {
        state: deviceType,
        stateFn: setDeviceType,
        options: deviceTypeOptions,
        type: FilterChipEnum.DeviceType,
        label: t("dashboard:filter.device_types"),
      },
      {
        state: deviceStatus,
        stateFn: setDeviceStatus,
        options: deviceStatusOptions,
        type: FilterChipEnum.DeviceStatus,
        label: t("dashboard:filter.device_status"),
      },
      {
        state: sensorType,
        stateFn: setSensorType,
        options: sensorTypeOptions,
        type: FilterChipEnum.SensorType,
        label: t("dashboard:filter.sensor_type"),
      },
      {
        state: approaching,
        stateFn: setApproaching,
        options: approachingOptions,
        type: FilterChipEnum.Approaching,
        label: t("dashboard:filter.approaching"),
      },
      {
        state: inAlert,
        stateFn: setInAlert,
        options: inAlertOptions,
        type: FilterChipEnum.InAlert,
        label: t("dashboard:filter.in_alert"),
      },
      {
        state: sampleInterval,
        stateFn: setSampleInterval,
        options: sampleIntervalOptions,
        type: FilterChipEnum.SampleInterval,
        label: t("dashboard:filter.sample_interval"),
      },
      {
        state: transmitInterval,
        stateFn: setTransmitInterval,
        options: transmitIntervalOptions,
        type: FilterChipEnum.TransmitInterval,
        label: t("dashboard:filter.transmit_interval"),
      },
    ];

    const filtersTemplate = (
      <section className={classNames("header-row-section filter-container", filtersOpen ? "extended" : "")}>
        <div className="filter-header u-mobile-hide">
          <p>{t("dashboard:filters")}</p>
          <CloseIcon className="close-icon" onClick={() => (setFiltersOpen ? setFiltersOpen(false) : "")} />
        </div>
        {filters.map((filter) => (
          <Accordion key={filter.label} className="filter-accordion">
            <AccordionSummary
              id={filter.type}
              className="filter-accordion-header"
              aria-controls={filter.type}
              expandIcon={<i className="fa fa-chevron-down expand-icon" />}>
              {filter.label}
            </AccordionSummary>
            <AccordionDetails key={filter.label}>
              {renderOptions(filter.options, filter.state, filter.stateFn, filter.type, filter.label)}
            </AccordionDetails>
          </Accordion>
        ))}
        <div className="dropdown-filters">
          <SelectInput
            required={false}
            displayError={false}
            className="form-group input-select vertical"
            label={t("common:sort_by")}
            onChange={changeSort}
            options={sortingOptions}
            value={AppState.dashboardSorting.by + "," + AppState.dashboardSorting.order}
          />
        </div>
      </section>
    );

    const chipTemplate = (
      <section ref={chipRef} className="header-row-section chip-container full-width">
        {chipData.map((data) => (
          <Chip
            key={data.key + data.type}
            label={data.label}
            title={data.fullLabel}
            className="filter-chip"
            variant="filled"
            deleteIcon={<CloseIcon className="close-icon" />}
            onDelete={handleDeleteChip(data)}
          />
        ))}
        {showMoreButton && (
          <p className="u-text-teal show-more-filters-button" onClick={() => setShowFullFilters(!showFullFilters)}>
            {`+ ${!showFullFilters ? t("common:more") : t("common:less")}`}
          </p>
        )}
      </section>
    );
    useEffect(() => {
      setChipTemplate(chipTemplate);
      setChipCount(chipData.length);
    }, [chipData]);
    return windowMode !== WindowSize.MOBILE ? (
      <>
        {filtersTemplate}
        {chipData.length > 0 && (
          <div
            className={showFullFilters ? "chip-data-css-container full" : "chip-data-css-container"}
            ref={elementRef}
            style={{ width: "100%", paddingBottom: "10px" }}>
            {chipTemplate}
          </div>
        )}
      </>
    ) : (
      <Modal
        className="mobile-dashboard-filters u-mobile-only modal-lg"
        title={t("dashboard:filters")}
        buttons={
          <>
            <button className="btn btn-secondary" onClick={() => setChipModalConfig({ hidden: true })}>
              {t("common:close")}
            </button>
          </>
        }>
        {filtersTemplate}
      </Modal>
    );
  },
);
