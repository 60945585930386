import { Card, Page, Stepper, StepperActions } from "../../Components";
import React, { useState } from "react";
import "./Register.scss";
import { useNavigate } from "react-router-dom";
import { URLS } from "../URLS";
import { AccountStep, IAccountProps } from "./AccountStep";
import { EmailVerificationStep } from "./EmailVerificationStep";
import { PlanStep } from "./PlanStep";
import { GetActiveSteps, RegistrationSteps, SubscriptionOptionType } from "../../Enums";
import { CreateTemporaryUser, CreateUserFromTemporary } from "../../Managers/TemporaryUserService";
import { CalculatePrice } from "../../Managers/PaymentOptionsService";
import { ConfirmationStep, IPaymentData } from "./ConfirmationStep";
import { IPlanProps, ISelectedPlanOption, IUserCreatingFromTemporary, IUserPaymentData } from "../../Managers/Types";
import { showSnackbar } from "../../AppState";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../Managers/API";

export const Register = () => {
  const navigate = useNavigate();
  const countryList = useCountries();
  const countries = countryList.data ?? [];
  const [step, setStep] = useState(RegistrationSteps.AccountInfo);
  const [accountDetails, setAccountDetails] = useState<IAccountProps>();
  const [emailVerified, setEmailVerified] = useState<string>();
  const [planDetails, setPlanDetails] = useState<IPlanProps | undefined>();
  const [totalPrice, setTotalPrice] = useState(100);
  const [creatingInProgress, setCreatingInProgress] = useState(false);
  const { t } = useTranslation("register");

  const stepChange = (step: RegistrationSteps) => {
    if (step >= RegistrationSteps.AccountInfo && step <= RegistrationSteps.Confirmation) {
      setStep(step);
    }
  };

  const saveAccountDetails = (values: IAccountProps) => {
    if (!values.currency) {
      showSnackbar("Currency is required", "error");
      return;
    }

    CreateTemporaryUser({
      first_name: values.firstName ?? "",
      last_name: values.lastName ?? "",
      phone: values.phone ?? "",
      email: values.emailAddress ?? "",
      phone_code: values.phoneCountry?.dial_code ?? "",
      country_code: values.country?.code ?? "",
      title: values.title ?? "",
      company_name: values.companyName ?? "",
      email_verified: emailVerified !== undefined && emailVerified.length > 0,
      is_marketing_accepted: values.marketingChecked ?? false,
      language: values.language,
      CurrencyId: values.currency._id,
      country: values.country?.code ?? "",
      address: values.address,
      billing_id: values.billing_id,
      city: values.city,
      zip: values.zip,
      state: values.state,
      secondary_email: values.secondaryEmailAddress ?? "",
      secondary_phone: values.secondaryPhone ?? "",
      secondary_phone_code: values.phoneCountry?.dial_code ?? "",
    })
      .then(() => {
        if (values.emailAddress !== emailVerified) {
          setEmailVerified(undefined);
        }
        setAccountDetails(values);
        stepChange(step + 1);
      })
      .catch((err) => {
        showSnackbar(t("register:temporary_user_error", { context: err.response?.status }), "error");
      });
  };

  const clickBack = () => {
    if (step === RegistrationSteps.AccountInfo) {
      navigate(URLS.Login);
    } else {
      stepChange(step - 1);
    }
  };

  const calculatePrice = (values: IPlanProps) =>
    CalculatePrice(values, accountDetails?.country?.code ?? "US", accountDetails?.currency?.iso_code ?? "USD").then((value) => {
      setTotalPrice(value);
      return value;
    });

  const clearAcceptedTermsAndPrivacyFromSessionStorage = () => {
    sessionStorage.removeItem("termsChecked");
    sessionStorage.removeItem("privacyChecked");
  };

  const confirmUserAndGoToPayment = () => {
    setCreatingInProgress(true);
    const proceedCheckoutPageError = t("register:error");
    try {
      const user: IUserCreatingFromTemporary | undefined = getUserData();
      const paymentData: IPaymentData | undefined = getPaymentData();

      if (user && paymentData) {
        CreateUserFromTemporary(user, paymentData.selectedPlans)
          .then(async (data) => {
            clearAcceptedTermsAndPrivacyFromSessionStorage();
            window.location.href = data.data.message;
          })
          .catch((error) => {
            setCreatingInProgress(false);
            console.error("Error creating user from temporary:", error);
            showSnackbar(proceedCheckoutPageError, "error");
          });
      } else {
        setCreatingInProgress(false);
        showSnackbar(proceedCheckoutPageError, "error");
      }
    } catch (error) {
      setCreatingInProgress(false);
      console.error("Error confirmUserAndGoToPayment:", error);
      showSnackbar(proceedCheckoutPageError, "error");
    }
  };

  const getUserData = (): IUserCreatingFromTemporary | undefined => {
    return accountDetails?.emailAddress && accountDetails?.password
      ? {
          email: accountDetails.emailAddress,
          password: accountDetails.password,
        }
      : undefined;
  };

  const getPaymentData = (): IPaymentData | undefined => {
    if (
      accountDetails?.emailAddress &&
      accountDetails?.firstName &&
      accountDetails?.lastName &&
      planDetails?.numberOfDevices !== undefined &&
      planDetails?.transmissionRate !== undefined &&
      planDetails?.smsNotifications !== undefined &&
      planDetails?.basePriceOptionId !== undefined &&
      planDetails?.numberOfDevicesOptionId !== undefined &&
      planDetails?.numberOfUsers !== undefined
    ) {
      const userData: IUserPaymentData = {
        email: accountDetails.emailAddress,
        first_name: accountDetails.firstName,
        last_name: accountDetails.lastName,
      };

      const selectedPlans: ISelectedPlanOption[] = [
        { id: planDetails.basePriceOptionId || 0, quantity: 1, type: SubscriptionOptionType.base },
        { id: planDetails.numberOfDevicesOptionId || 0, quantity: planDetails.numberOfDevices ?? 1, type: SubscriptionOptionType.devices },
        { id: planDetails.transmissionRate || 0, quantity: planDetails.numberOfDevices ?? 1, type: SubscriptionOptionType.transmission },
        { id: planDetails.smsNotifications || 0, quantity: 1, type: SubscriptionOptionType.sms },
        { id: planDetails.numberOfUsers || 0, quantity: 1, type: SubscriptionOptionType.users },
      ];

      return {
        userData,
        selectedPlans,
      };
    } else {
      return undefined;
    }
  };

  const getStep = () => {
    switch (step) {
      case RegistrationSteps.AccountInfo:
        return <AccountStep onClickBack={clickBack} accountDetails={accountDetails} countries={countries} onSubmit={saveAccountDetails} />;
      case RegistrationSteps.EmailVerification:
        return (
          <EmailVerificationStep
            verified={!!emailVerified}
            onClickBack={clickBack}
            onSubmit={() => {
              setEmailVerified(accountDetails?.emailAddress);
              setStep(step + 1);
            }}
            emailAddress={accountDetails?.emailAddress!}
            codeLength={6}
          />
        );
      case RegistrationSteps.SelectPlan:
        return (
          <PlanStep
            onClickBack={(values) => {
              setPlanDetails(values);
              setStep(step - 1);
            }}
            calculateTotal={calculatePrice}
            userCountryCode={accountDetails?.country?.code ?? "US"}
            currencyCode={accountDetails?.currency?.iso_code ?? "USD"}
            currencySign={accountDetails?.currency?.sign ?? "$"}
            planDetails={planDetails}
            onSubmit={(values) => {
              setPlanDetails(values);
              setStep(step + 1);
            }}
          />
        );
      case RegistrationSteps.Confirmation:
        return (
          <ConfirmationStep
            price={totalPrice}
            onClickBack={clickBack}
            onSubmit={() => confirmUserAndGoToPayment()}
            accountDetails={accountDetails}
            planDetails={planDetails}
            isConfirming={creatingInProgress}
          />
        );
      default:
        return <StepperActions onClickBack={() => setStep(step - 1)} onClickNext={() => setStep(step + 1)} />;
    }
  };

  return (
    <Page key={countries.length}>
      <Card className="register-card" label={t("register:title")}>
        <div className="header-absolute-title">{t("register:title")}</div>
        <Stepper activeStep={step} className="register-stepper" orientation={"horizontal"} steps={GetActiveSteps()}>
          {getStep()}
        </Stepper>
      </Card>
    </Page>
  );
};
