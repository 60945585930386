import { observer } from "mobx-react-lite";
import { LocationDropDown } from "../Dashboard/LocationDropdown";
import { CheckboxInput, LeftRail, LeftRailGatewayPicker, SortByNameLeftRailHeader } from "../../Components";
import { myOrder, mySearch } from "../../Managers";
import { IDevice, IGateway, ILocation } from "../../Managers/Types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderBy } from "../../Enums/SortingTypes";

interface IExportLeftRailProps {
  changeLocation: (location: ILocation) => void;
  location: ILocation;
  selectGateways: (gateway: IGateway[]) => void;
  selectedGateways: IGateway[];
  setSelectedDevices: (devices: IDevice[]) => void;
  devices: IDevice[];
  deviceSearchString: string;
  gatewaySearchString: string;
  setGatewaySearchString: (search: string) => void;
  setDeviceSearchString: (search: string) => void;
  selectedDevices: IDevice[];
}

export const ExportLeftRail: React.FC<IExportLeftRailProps> = observer(
  ({
    selectedGateways,
    selectGateways,
    location,
    changeLocation,
    selectedDevices,
    setSelectedDevices,
    deviceSearchString,
    setDeviceSearchString,
    setGatewaySearchString,
    gatewaySearchString,
    devices,
  }) => {
    const { t } = useTranslation(["alert_history", "sensor_types", "common"]);

    const [gatewayOrder, setGatewayOrder] = useState<OrderBy>(OrderBy.ASC);
    const [deviceOrder, setDeviceOrder] = useState<OrderBy>(OrderBy.ASC);

    const handleDeviceSelect = (device: IDevice) => {
      const modifiedSelectedDevices = selectedDevices.slice();
      const deviceIndex = modifiedSelectedDevices.findIndex((d) => d._id === device._id);

      if (deviceIndex > -1) {
        modifiedSelectedDevices.splice(deviceIndex, 1);
      } else {
        modifiedSelectedDevices.push(device);
      }

      setSelectedDevices(modifiedSelectedDevices);
    };

    return (
      <LeftRail
        className="u-mobile-hide u-mobile-hide"
        header={
          <div style={{ width: "100%" }}>
            <LocationDropDown displayAllOption onChange={(loc) => changeLocation(loc)} selected={location} />
          </div>
        }
        body={
          <>
            {location._id ? (
              <LeftRailGatewayPicker
                disabled={!location.Gateways?.length}
                selectedGateways={selectedGateways}
                setGatewayOrder={setGatewayOrder}
                gatewayOrder={gatewayOrder}
                gateways={location.Gateways ?? []}
                selectGateways={selectGateways}
                searchString={gatewaySearchString}
                setSearchString={setGatewaySearchString}
              />
            ) : null}
            {devices.length > 10 ? (
              <div className="input-holder u-full-width">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("common:find_device")}
                  value={deviceSearchString}
                  onChange={(e) => setDeviceSearchString(e.target.value)}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            ) : null}
            <nav className="left-rail-nav export-nav">
              <div className="left-rail-nav-header">
                <SortByNameLeftRailHeader order={deviceOrder} setOrder={setDeviceOrder}>
                  {t("common:device_list")}
                </SortByNameLeftRailHeader>
              </div>

              <ul className="left-rail-nav-group">
                <li className="left-rail-nav-item" key="all-devices">
                  <CheckboxInput
                    onChange={() => setSelectedDevices(selectedDevices.length === devices.length ? [] : devices)}
                    checked={selectedDevices.length === devices.length}
                    name="all-devices"
                    className="checkbox-teal"
                  />
                  {t("common:all_devices", { count: devices.length })}
                </li>

                {myOrder(mySearch<IDevice>(devices, deviceSearchString), "name", deviceOrder).map((device) => (
                  <li className="left-rail-nav-item" key={device._id}>
                    <CheckboxInput
                      onChange={() => handleDeviceSelect(device)}
                      checked={selectedDevices.some((d) => d._id === device._id)}
                      name={`device-${device._id}`}
                      className="checkbox-teal"
                    />
                    {device.name}
                  </li>
                ))}
              </ul>
            </nav>
          </>
        }
      />
    );
  },
);
