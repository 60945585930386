import { observer } from "mobx-react-lite";

export const OfflineIcon: React.FC<{ color?: string }> = observer(({ color }) => {
  return (
    <svg style={{ fill: color }} width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98895 9.33174C8.41265 9.56044 7.00105 10.3161 5.92717 11.4199L3.79133 9.22347C5.63567 7.3257 8.18492 6.15236 11 6.15236C11.6181 6.15236 12.2235 6.20893 12.8113 6.31732L9.98895 9.33174ZM11.5047 11.6152L8.86135 14.4384L11 16.6377L14.4714 13.0679C13.694 12.269 12.6588 11.7364 11.5047 11.6152ZM13.3362 9.65905L15.5771 7.26564C16.5549 7.77164 17.4426 8.4347 18.2087 9.22258L16.0728 11.4199C15.3107 10.636 14.3777 10.0277 13.3362 9.65905ZM17.2082 5.52357C18.1627 6.0886 19.0375 6.77995 19.811 7.57547L22 5.32392C21.2148 4.51636 20.346 3.7947 19.4082 3.17386L17.2082 5.52357ZM16.9816 1.86322C15.1403 1.07371 13.1198 0.637695 11 0.637695C6.70358 0.637695 2.81508 2.42881 0 5.32392L2.189 7.57547C4.444 5.25636 7.55975 3.82258 11 3.82258C12.2626 3.82258 13.4814 4.01569 14.63 4.3748L16.9816 1.86322Z"
      />
      <rect x="18.0898" width="1.67784" height="19.1047" transform="rotate(42.877 18.0898 0)" />
    </svg>
  );
});
