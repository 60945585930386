import React from "react";
import classNames from "classnames";

import { showAppModal } from "../../../AppState";
import { EditGatewayModal } from "../Modals/EditGatewayModal";
import { useTranslation } from "react-i18next";

export const WelcomeInstructions: React.FC = () => {
  const { t } = useTranslation("dashboard");
  return (
    <div className={classNames("dashboard-content setup-container main-panel extended")}>
      <h1 className="setup-title">{t("dashboard:welcome.welcome_message")}</h1>
      <div className="setup-instruction left">
        <i className="fa fa-info-circle" />
        <p>{t("dashboard:welcome.hardware_setup")}</p>
      </div>
      <div className="setup-instruction right">
        <i className="fa fa-lightbulb-o" />
        <p>{t("dashboard:welcome.post_power_up")}</p>
      </div>
      <button className="btn btn-primary" onClick={() => showAppModal(<EditGatewayModal gateway={null} mode="new" />)}>
        {t("dashboard:welcome.enter_serial_number_button")}
      </button>
    </div>
  );
};
