import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { ILanguage } from "./Types";
import { QK_TRANSLATIONS } from "./TranslationsService";
import { AppState } from "../AppState";
import { DEFAULT_LANGUAGE } from "../i18n";

const API_PATH = "api/languages";
const API_PATH_MANAGE = "api/manage-languages";
export const QK_LANGUAGES = "LANGUAGES";

export const getLanguages = () => Endpoint.get<ILanguage[]>(API_PATH).then((response) => response.data);

export const useLanguages = () => useQuery(QK_LANGUAGES, () => getLanguages());

export const saveLanguage = (code: string, name: string) =>
  Endpoint.post(API_PATH_MANAGE, { code, name }).then((response) => {
    invalidateQuery(QK_LANGUAGES);
    invalidateQuery(`${QK_TRANSLATIONS}-${AppState.user?.language ?? DEFAULT_LANGUAGE}`);
    return response.data;
  });

export const toggleLanguage = (code: string, active: boolean) => {
  Endpoint.put(API_PATH_MANAGE + "/" + code, { active }).then((response) => {
    invalidateQuery(QK_LANGUAGES);
    invalidateQuery(`${QK_TRANSLATIONS}-${AppState.user?.language ?? DEFAULT_LANGUAGE}`);
    return response.data;
  });
};
