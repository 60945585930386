import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./CookiePopup.scss";
import { useTranslation } from "react-i18next";

export const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation(["cookies"]);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("hasAcceptedCookies");
    const acceptedTimestamp = localStorage.getItem("acceptedTimestamp");
    const hasDeclinedCookies = localStorage.getItem("hasDeclinedCookies");

    const sixMonthsInMilliseconds = 6 * 30 * 24 * 60 * 60 * 1000;
    const currentTime = Date.now();

    if (hasAcceptedCookies && acceptedTimestamp) {
      const acceptedTime = parseInt(acceptedTimestamp, 10);
      if (currentTime - acceptedTime < sixMonthsInMilliseconds) {
        return;
      } else {
        localStorage.removeItem("hasAcceptedCookies");
        localStorage.removeItem("acceptedTimestamp");
      }
    }

    if (!hasAcceptedCookies && !hasDeclinedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("hasAcceptedCookies", "true");
    localStorage.setItem("acceptedTimestamp", Date.now().toString());
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("hasDeclinedCookies", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookies-popup-wrapper">
      <div className="cookies-popup-text">{t("cookies:cookies_popup_content")}</div>
      <div className="cookies-popup-buttons-wrapper">
        <Button onClick={handleAccept} className="btn btn-info" variant="contained">
          {t("cookies:accept")}
        </Button>
        <Button onClick={handleDecline} className="btn btn-secondary" variant="contained">
          {t("cookies:decline")}
        </Button>
      </div>
    </div>
  );
};
