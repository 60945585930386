import { ExportModal, ISensorWithExportOptionAndSetPoints } from "./ExportModal";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../../Components/Table";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";
import { getUserDateFormat, showAppModal } from "../../../AppState";
import { formatDateCustom } from "../../../Managers";
import React from "react";
import { IExportPreset } from "../../../Managers/Types";

export const PointOfInterestTableModal: React.FC<{
  sensors: ISensorWithExportOptionAndSetPoints[];
  minDate: Date;
  maxDate: Date;
  savePreset: (preset: IExportPreset) => Promise<void>;
}> = ({ sensors, minDate, maxDate, savePreset }) => {
  const { t } = useTranslation(["common", "export"]);

  return (
    <Modal
      title={t("export:all_points")}
      buttons={
        <button
          className="btn btn-primary"
          onClick={() =>
            showAppModal(
              <ExportModal savePreset={savePreset} startingStep={2} minDate={minDate} maxDate={maxDate} selectedSensors={sensors} />,
            )
          }>
          {t("common:back")}
        </button>
      }>
      <div className="export-modal-table-wrapper">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("export:sensor_type")}</TableCell>
              <TableCell>{t("export:device_name")}</TableCell>
              <TableCell>{t("export:points_number")}</TableCell>
              <TableCell>{t("export:points_range")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensors
              .filter((sensor) => sensor.setPoints?.length)
              .map((sensor) => (
                <TableRow>
                  <TableCell>{sensor.sensor_type}</TableCell>
                  <TableCell>{sensor.device.name}</TableCell>
                  <TableCell>{sensor.setPoints?.length}</TableCell>
                  <TableCell>
                    {sensor.setPoints!.length > 1 ? (
                      <>
                        <span>{t("common:from")}</span> {formatDateCustom(sensor.setPoints![0].x, getUserDateFormat())}
                        <br />
                        <span>{t("common:to")}</span>{" "}
                        {formatDateCustom(sensor.setPoints![sensor.setPoints!.length - 1].x, getUserDateFormat())}
                      </>
                    ) : (
                      formatDateCustom(sensor.setPoints![0].x, getUserDateFormat())
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
};
