import { IInvoice } from "../../Managers/Types";
import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import "./InvoicesTable.scss";
import { Column, List, ListItem, Row, useScreenMode } from "../../Components";
import { useTranslation } from "react-i18next";
import { PaginationButtons } from "../../Components/PaginationButtons";
import { getUserDateFormat } from "../../AppState";
import { formatDateCustom } from "../../Managers";
import { WindowSize } from "../../Enums";

export const InvoicesTable: React.FC<{ invoices: IInvoice[] }> = ({ invoices }) => {
  const mode = useScreenMode();
  const perPage = mode === WindowSize.MOBILE ? 4 : 10;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { t } = useTranslation("billing_info");

  const getPageInvoices = () => {
    return invoices.slice(currentPage * perPage, currentPage * perPage + perPage);
  };

  /**
   * Can be 1,-1,10,-10
   * @param pageToOpen
   */
  const setPage = (pageToOpen: number) => {
    let tryOpenThisPage = pageToOpen - 1;

    setCurrentPage(tryOpenThisPage);
  };

  const isLastPage = () => currentPage === Math.ceil(invoices.length / perPage) - 1;

  return (
    <Column style={{ width: "100%" }}>
      <div className="input-label" style={{ marginBottom: "0.5em" }}>
        {t("billing_info:invoices.title")}
      </div>
      {invoices.length === 0 ? (
        <div>{t("billing_info:invoices.no_invoices")}</div>
      ) : (
        <>
          <Table className="full-width invoice-table u-mobile-hide">
            <TableHead>
              <TableRow>
                <TableCell>{t("billing_info:invoices.date")}</TableCell>
                <TableCell>{t("billing_info:invoices.description")}</TableCell>
                <TableCell>{t("billing_info:invoices.total")}</TableCell>
                <TableCell>{t("billing_info:invoices.status")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getPageInvoices().map((invoice) => (
                <TableRow key={invoice.date}>
                  <TableCell>{formatDateCustom(invoice.date * 1000, getUserDateFormat())}</TableCell>
                  <TableCell>
                    <span title={invoice.description}>{invoice.description}</span>
                  </TableCell>
                  <TableCell>{invoice.total}</TableCell>
                  <TableCell>{invoice.paid ? t("billing_info:invoices.paid") : t("billing_info:invoices.unpaid")}</TableCell>
                  <TableCell>
                    <a className="u-color-teal" target="_blank" href={invoice.pdf} rel="noreferrer">
                      {t("billing_info:invoices.view_invoice")}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="u-mobile-hide" style={{ width: "100%", textAlign: "end" }}>
            {currentPage * perPage + 1}-{isLastPage() ? invoices.length : currentPage * perPage + perPage} {t("common:of")}{" "}
            {invoices.length}
          </div>
        </>
      )}

      <List className="invoice-list u-mobile-only">
        {invoices.length === 0 ? (
          <ListItem>{t("billing_info:invoices.no_invoices")}</ListItem>
        ) : (
          getPageInvoices().map((invoice) => (
            <ListItem>
              <Row>
                <div className="invoice-item">
                  <span className="input-label">{t("billing_info:invoices.date")}:</span>
                  <span>{formatDateCustom(invoice.date * 1000, getUserDateFormat())}</span>
                </div>
                <div className="invoice-item">
                  <span className="input-label">{t("billing_info:invoices.total")}:</span>
                  <span>{invoice.total}</span>
                </div>
              </Row>
              <Row>
                <div className="invoice-item">
                  <span className="input-label">{t("billing_info:invoices.description")}:</span>
                  <span>{invoice.description}</span>
                </div>
              </Row>
              <Row>
                <div className="invoice-item">
                  <span className="input-label">{t("billing_info:invoices.status")}:</span>
                  <span>{invoice.paid ? t("billing_info:invoices.paid") : t("billing_info:invoices.unpaid")}</span>
                </div>
                <div>
                  <a className="pull-right u-color-teal" target="_blank" href={invoice.pdf} rel="noreferrer">
                    {t("billing_info:invoices.view_invoice")}
                  </a>
                </div>
              </Row>
            </ListItem>
          ))
        )}
      </List>
      {invoices.length > perPage ? <PaginationButtons onPageChange={setPage} pageCount={Math.ceil(invoices.length / perPage)} /> : null}
    </Column>
  );
};
