import React from "react";
import { Formik } from "formik";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Filler, FormFieldText, Page, Row } from "../../Components";
import { IResetPasswordRequest, resetPassword } from "../../Managers/API";
import { JumbotronHeader } from "./JumboTron";
import { LoginForm } from "./Forms";
import { URLS } from "../URLS";
import * as yup from "yup";
import { showSnackbar } from "../../AppState";
import { useTranslation } from "react-i18next";
import { ValidationMessages } from "../../Enums";
import { FormColumn } from "./Login";

const schema = yup.object({
  email: yup.string().email().required(ValidationMessages.EMAIL),
});

export const ForgotPassword: React.FC = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation("forgot_password");

  const handleReset = (values: IResetPasswordRequest, _formik: any) =>
    resetPassword(values)
      .then((r: any) => {
        // TODO: We need a test user to work with in dev. I didn't want to reset the test user we were provided
        showSnackbar(t("forgot_password:reset_success"), "success");
        console.log("Reset password result", r);
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          showSnackbar(t("forgot_password:reset_error_email_not_found"), "error");
        } else {
          showSnackbar(t("forgot_password:reset_error"), "error");
        }
      });

  const handleLogin = () => {
    navigate(URLS.Login);
  };

  const initialValues: IResetPasswordRequest = {
    email: "",
  };

  return (
    <Page>
      <div className={"jumbotron mobile-fullscreen"}>
        <JumbotronHeader />

        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleReset} enableReinitialize>
          {({ isSubmitting, handleSubmit }) => {
            return (
              <LoginForm>
                <div className={"row-to-tablet-mobile-column"}>
                  <FormColumn>
                    <FormFieldText
                      className="u-full-width"
                      label={t("forgot_password:email")}
                      dataTestId="email-input"
                      name="email"
                      disabled={isSubmitting}
                    />
                  </FormColumn>
                </div>

                <Row>
                  <Filler />
                  <Button variant="text" onClick={() => handleLogin()}>
                    {t("forgot_password:sign_in")}
                  </Button>

                  <Button variant="contained" onClick={() => handleSubmit()} disabled={isSubmitting}>
                    {t("forgot_password:reset")}
                  </Button>
                </Row>
              </LoginForm>
            );
          }}
        </Formik>
      </div>
      <Filler />
    </Page>
  );
});
