import { Endpoint, invalidateQuery } from "./API";
import { IInvitedUser } from "./Types";
import { useQuery } from "react-query";

const API_PATH = "api/invited_user";
const API_PATH_UNAUTHENTICATED = "api/invited_user_open";

export const createInvitedUser = (user: IInvitedUser) =>
  Endpoint.post<string | undefined>(`${API_PATH}/create`, user).then(() => invalidateQuery("invited_users"));

export const getInvitedUser = (userUniqueCode: string) =>
  Endpoint.get<IInvitedUser>(`${API_PATH_UNAUTHENTICATED}/${userUniqueCode}`).then((res) => res.data);

export const getInvitedUsers = () => Endpoint.get<IInvitedUser[]>(API_PATH).then((res) => res.data);
export const useInvitedUsers = () => useQuery(`invited_users`, () => getInvitedUsers());

export const createUserFromInvitation = (id: number, body: any) => Endpoint.post(`${API_PATH_UNAUTHENTICATED}/${id}`, body);

export const deleteInvitedUser = (id: number) => Endpoint.delete(`${API_PATH}/${id}`).then(() => invalidateQuery("invited_users"));
