import React, { useCallback, useEffect, useState } from "react";
import { AppState, setLoggedIn, showAppModal } from "../../AppState";
import { LegalModal, MarketingModal } from "../Login/Legal";
import { updateUser } from "../../Managers/UsersService";
import { Page } from "../../Components";
import { useNavigate } from "react-router-dom";
import { URLS } from "../URLS";
import { useTranslation } from "react-i18next";

export const TermsAndPolicy: React.FC = () => {
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(AppState.user?.is_terms_accepted ?? false);
  const [acceptedPolicy, setAcceptedPolicy] = useState<boolean>(AppState.user?.is_policy_accepted ?? false);
  const [acceptedMarketing, setAcceptedMarketing] = useState<boolean>(AppState.user?.is_marketing_accepted ?? false);
  const [marketingShown, setMarketingShown] = useState<boolean>(AppState.user?.is_marketing_shown ?? false);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!acceptedTerms) {
      openTerms();
    } else if (!acceptedPolicy) {
      openPolicy();
    } else if (!marketingShown) {
      openMarketing();
    } else {
      navigate(URLS.Dashboard);
    }
  }, [acceptedTerms, acceptedPolicy, marketingShown]);

  useEffect(() => {
    if (AppState.user?.is_marketing_shown && AppState.user?.is_policy_accepted && AppState.user?.is_terms_accepted) {
      navigate(URLS.Dashboard, { replace: true });
    }
  }, [navigate]);

  const navigateAfterMarketing = useCallback(async (value: boolean) => {
    setAcceptedMarketing(value);
    setMarketingShown(true);
    if (AppState.user) {
      AppState.user.is_marketing_accepted = value;
      AppState.user.is_marketing_shown = true;
      await updateUser(AppState.user);
    }
    navigate(URLS.Dashboard);
  }, []);
  const openTerms = () => {
    if (!acceptedTerms) {
      showAppModal(
        <LegalModal
          type="terms"
          onAccept={async () => {
            setAcceptedTerms(true);
            if (AppState.user) {
              AppState.user.is_terms_accepted = true;
              await updateUser(AppState.user);
            }
            navigate(URLS.Dashboard);
          }}
          onCancel={() => {
            navigate(URLS.Login);
            setLoggedIn(false, null, null);
          }}
          accepted={acceptedTerms}
        />,
      );
    }
  };

  const openPolicy = () => {
    if (acceptedTerms && !acceptedPolicy) {
      showAppModal(
        <LegalModal
          type="privacy"
          onAccept={async () => {
            setAcceptedPolicy(true);
            if (AppState.user) {
              AppState.user.is_policy_accepted = true;
              await updateUser(AppState.user);
            }
            navigate(URLS.Dashboard);
          }}
          onCancel={() => {
            navigate(URLS.Login);
            setLoggedIn(false, null, null);
          }}
          accepted={acceptedPolicy}
        />,
      );
    }
  };

  const openMarketing = () => {
    if (acceptedTerms && acceptedPolicy) {
      showAppModal(
        <MarketingModal
          onAccept={async () => {
            await navigateAfterMarketing(true);
          }}
          onCancel={async () => {
            await navigateAfterMarketing(false);
          }}
          type={"marketing"}
          accepted={marketingShown}
          cancelText={t("common:disagree")}
          noScroll={true}
        />,
      );
    }
  };

  return <Page></Page>;
};
