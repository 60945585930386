import { observer } from "mobx-react-lite";
import { FormFieldCheckbox, FormFieldSelect, FormFieldText, Page } from "../../Components";
import { Formik } from "formik";
import { createUserFromInvitation, getInvitedUser } from "../../Managers";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import * as yup from "yup";
import { ValidationMessages } from "../../Enums";
import { useTranslation } from "react-i18next";
import { Jumbotron } from "./JumboTron";
import { AppState, checkSession, showAppModal, showSnackbar } from "../../AppState";
import { URLS } from "../URLS";
import { IInvitedUser, ILanguage } from "../../Managers/Types";
import { PhoneInput } from "../../Components/PhoneInput";
import { useCountries } from "../../Managers/API";
import { PasswordInput } from "../../Components/PasswordInput";
import { LegalModal } from "./Legal";
import { useLanguages } from "../../Managers/LanguageService";
import { DEFAULT_LANGUAGE } from "../../i18n";

export const FinalizeRegistration: React.FC<any> = observer(() => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const [loadingError, setLoadingError] = useState<"expired" | "system" | "not_found">();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [languages, setLanguages] = useState<ILanguage[]>([]);

  const { t } = useTranslation(["common", "register"]);
  const navigate = useNavigate();
  const countryQuery = useCountries();

  const [user, setUser] = useState<IInvitedUser>();

  const languageQuery = useLanguages();

  useEffect(() => {
    setLanguages(languageQuery.data?.filter((l) => l.active) ?? []);
  }, [languageQuery.dataUpdatedAt]);

  useEffect(() => {
    const userCode = searchParams.get("code");
    if (userCode) {
      getInvitedUser(userCode)
        .then(setUser)
        .catch((e) => {
          switch (e.response.status) {
            case 406:
              return setLoadingError("expired");
            case 404:
              return setLoadingError("not_found");
            default:
              return setLoadingError("system");
          }
        });
    }
  }, []);

  useEffect(() => {
    // We are the default route. If we arrive here, and we're already logged in, hop over to dashboard
    if (AppState.isAuthenticated) {
      // Handle the case we land here but are already logged in
      console.log("[LOGIN] Already authenticated, going to dashboard");
      navigate(URLS.Dashboard);
    } else {
      // We often end up here via live-reload if it wipes too much of our state. So we need to repeat the session check.
      checkSession()
        .then((success) => {
          if (success) {
            navigate(URLS.Dashboard);
            console.log("[LOGIN] Loaded session, navigating to dashboard");
          }
        })
        .catch(() => {});
    }
  }, []);

  if (!user) {
    return (
      <Page>
        <Jumbotron>{loadingError ? <div>{t("register:finalize.error", { context: loadingError })}</div> : <CircularProgress />}</Jumbotron>
      </Page>
    );
  }

  const initialValues = {
    termsChecked: false,
    privacyChecked: false,
    marketingChecked: false,
    firstName: user.first_name,
    lastName: user.last_name,
    title: user.title,
    phoneCountry: countryQuery.data?.find((c) => c.code === "US"),
    password: "",
    confirmPassword: "",
    language: DEFAULT_LANGUAGE,
    phone: user.phone,
  };

  const validationSchema = yup.object({
    firstName: yup.string().required(t(ValidationMessages.REQUIRED)),
    lastName: yup.string().required(t(ValidationMessages.REQUIRED)),
    termsChecked: yup.boolean().required(t(ValidationMessages.REQUIRED)),
    privacyChecked: yup.boolean().required(t(ValidationMessages.REQUIRED)),
    marketingChecked: yup.boolean(),
    phoneCountry: yup.object(),
    password: yup
      .string()
      .min(8)
      .matches(/^(?=.*[a-z])/)
      .matches(/^(?=.*[A-Z])/)
      .matches(/^(?=.*[0-9])/)
      .matches(/^(?=.*[!@#$%^&*=()_+[\]{}|;:',.<>?/`~"\\-])/)
      .required(),
    confirmPassword: yup
      .string()
      .required(t(ValidationMessages.REQUIRED))
      .test("passwords-match", t("register:steps.account.password_match_error"), function (value) {
        return this.parent.password === value;
      }),
    language: yup.string(),
    phone: yup.string(),
  });

  const save = (values: any) => {
    if (user._id) {
      createUserFromInvitation(user._id, { ...values, phone_code: values.phoneCountry.dial_code })
        .then(() => {
          showSnackbar(t("register:finalize.success"), "success");
          navigate(URLS.Login);
        })
        .catch(() => {
          showSnackbar(t("register:finalize.error"), "error");
        });
    }
  };

  const openLegalModal = (
    e: React.MouseEvent<HTMLElement>,
    onAccept: () => void,
    checked: boolean,
    type: "privacy" | "terms",
    countryCode?: string,
  ) => {
    e.preventDefault();
    showAppModal(<LegalModal countryCode={countryCode} type={type} onAccept={() => onAccept()} accepted={checked} />);
  };

  return (
    <Page>
      <div className="jumbotron">
        <div className="type-large-regular jumbotron-header">{t("register:finalize.title")}</div>

        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={save} validationSchema={validationSchema}>
          {({ touched, values, submitForm, setFieldValue }) => (
            <>
              <div className="row" style={{ marginBottom: "16px" }}>
                <FormFieldText
                  required={true}
                  displayError={true}
                  name="firstName"
                  className="col-sm-6"
                  label={t("register:steps.account.first_name")}
                  type="text"
                />
                <FormFieldText
                  required={true}
                  displayError={true}
                  name="lastName"
                  className="col-sm-6"
                  label={t("register:steps.account.last_name")}
                  type="text"
                />
              </div>
              <div className="row" style={{ marginBottom: "16px" }}>
                <PhoneInput
                  onChange={(country) => setFieldValue("phoneCountry", country)}
                  required={true}
                  className="col-sm-6"
                  name="phone"
                  countryName="phoneCountry"
                  label={t("register:steps.account.phone")}
                  dataTestId="phone-input"
                />
                <FormFieldText
                  displayError={true}
                  name="title"
                  className="col-sm-6"
                  label={t("register:steps.account.account_title")}
                  type="text"
                />
              </div>
              <div className="row">
                <FormFieldSelect
                  className="col-sm-6"
                  name="language"
                  label={t("register:steps.account.language")}
                  options={languages.map((l) => ({
                    label: t(`common:language.${l.code}`),
                    value: l.code,
                  }))}
                />
              </div>
              <div className="row">
                <PasswordInput className="col-sm-6" password={values.password} touched={!!touched.password} />
                <FormFieldText
                  required={true}
                  className="col-sm-6"
                  displayError={true}
                  name="confirmPassword"
                  label={t("register:steps.account.confirm_password")}
                  type="password"
                />
              </div>

              <div className="terms">
                <FormFieldCheckbox
                  required={true}
                  name="termsChecked"
                  checked={values.termsChecked ?? false}
                  disabled={values.termsChecked}
                  label={`${t("register:steps.account.agree")} ${t("register:steps.account.terms_and_conditions")}`}
                  onChange={(e) =>
                    openLegalModal(e, () => setFieldValue("termsChecked", true), values.termsChecked, "terms", values.phoneCountry?.code)
                  }
                />
                <FormFieldCheckbox
                  required={true}
                  name="privacyChecked"
                  disabled={values.privacyChecked}
                  checked={values.privacyChecked ?? false}
                  label={`${t("register:steps.account.agree")} ${t("register:steps.account.privacy_policy")}`}
                  onChange={(e) =>
                    openLegalModal(
                      e,
                      () => setFieldValue("privacyChecked", true),
                      values.privacyChecked,
                      "privacy",
                      values.phoneCountry?.code,
                    )
                  }
                />
                <FormFieldCheckbox
                  onChange={() => setFieldValue("marketingChecked", !values.marketingChecked)}
                  required={false}
                  name="marketingChecked"
                  checked={values.marketingChecked ?? false}
                  label={t("register:steps.account.marketing")}
                />
              </div>
              {(!values.termsChecked || !values.privacyChecked) && submitAttempted && (
                <div className="account-error">{t("register:steps.account.legal_unchecked_error")}</div>
              )}
              <div style={{ textAlign: "end" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setSubmitAttempted(true);
                    return submitForm();
                  }}>
                  {t("register:finalize.create_profile")}
                </button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Page>
  );
});
