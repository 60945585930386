import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, refreshAppState, setLoggedIn, showAppModal, showSnackbar } from "../../AppState";
import {
  Column,
  CountrySelect,
  FormFieldText,
  PermissionBlocker,
  PlanDetails,
  Row,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  useScreenMode,
} from "../../Components";
import { getAccountSubscription, getPaymentLink, getSupportEmail, ICountry, resumeCancelledSubscription } from "../../Managers";
import { IBillingInfo, IInvoice, IPlanProps, ISubscription } from "../../Managers/Types";
import { InvoicesTable } from "./InvoicesTable";
import { CancelSubscription } from "./CancelSubscription";
import { UpdatePlanModal } from "../UpdatePlanModal";
import { CalculatePrice } from "../../Managers/PaymentOptionsService";
import { PermissionEnum, ValidationMessages, WindowSize } from "../../Enums";
import { Modal } from "../../Components/Modal";
import "./BillingInfo.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Formik } from "formik";
import * as yup from "yup";
import { invalidateQuery, useCountries } from "../../Managers/API";
import { QK_USERS } from "../../Managers/UsersService";
import { updateAccountBillingInformation } from "../../Managers/AccountsService";
import { URLS } from "../../Views/URLS";
import { useNavigate } from "react-router-dom";
import DemoRemainingDatesSection from "../../Components/DemoRemainingDatesSection";

interface SelfProps {
  expiredMode?: boolean;
}

type BillingTabs = "plan" | "billing";

export const BillingInfo: React.FC<SelfProps> = observer(({ expiredMode }) => {
  const user = AppState.user;
  const mailTo = getSupportEmail();
  const fetchedCountries = useCountries();
  const countries = fetchedCountries.data ?? [];
  const navigate = useNavigate();
  const mode = useScreenMode();

  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(
    countries.find((c: ICountry) => c.code === AppState.user?.country_code),
  );
  const [currentTab, setCurrentTab] = useState<BillingTabs>("plan");
  const [accountSubscription, setAccountSubscription] = useState<ISubscription[]>();
  const [accountInvoices, setAccountInvoices] = useState<IInvoice[]>();

  useEffect(() => {
    getAccountSubscription(user?.AccountId ?? 0).then((data) => {
      setAccountSubscription(data.subscription);
      setAccountInvoices(data.invoices);
    });
  }, [user?.AccountId]);

  const { t } = useTranslation(["billing_info", "register"]);

  const calculatePrice = (values: IPlanProps) =>
    CalculatePrice(values, AppState.user?.country_code ?? "US", AppState.user?.Account?.Currency?.iso_code ?? "USD").then((value) => {
      return value;
    });

  const showCancellationButton = (): boolean => {
    return !user?.Account?.blocked && (expiredMode === true || (!user?.Account?.cancel_at_period_end ?? false));
  };

  const showResumeButton = (): boolean => {
    return !user?.Account?.blocked && expiredMode !== true && (user?.Account?.cancel_at_period_end ?? false);
  };

  const isAccountInDemoMode = user?.Account?.is_demo || false;

  const resumeSubscription = () => {
    resumeCancelledSubscription(AppState.user?.Account?.stripe_subscription_id)
      .then(() => {
        showSnackbar(t("billing_info:subscription.resume_success"), "success");
        if (AppState.user && AppState.user.Account) {
          AppState.user.Account.cancel_at_period_end = false;
        }
        showAppModal(null);
      })
      .catch(() => {
        showSnackbar(t("billing_info:subscription.resume_error"), "error");
      });
  };

  const updatePaymentMethod = async () => {
    getPaymentLink().then((link) => {
      if (link.link.url) {
        window.location.href = link.link.url;
      }
    });
  };

  const contact = (
    <>
      {t("billing_info:subscription.contact_info")}{" "}
      <div className={"link"} onClick={() => (window.location.href = "mailto:" + mailTo)} style={{ display: "inline" }}>
        {mailTo}
      </div>
    </>
  );

  const logOut = () => {
    showAppModal(null);
    setLoggedIn(false, null, null);
    navigate({ pathname: URLS.Dashboard });
  };

  const changeTab = (event: React.SyntheticEvent, newValue: BillingTabs) => {
    setCurrentTab(newValue);
  };

  const initialValues = {
    country: countries.find((c) => c.code === user?.Account?.country),
    address: user?.Account?.address ?? "",
    billing_id: user?.Account?.billing_id ?? "",
    city: user?.Account?.city ?? "",
    zip: user?.Account?.zip ?? "",
    state: user?.Account?.state ?? "",
  };

  const validationSchema = yup.object({
    country: yup.object(),
    billing_id: yup.string().required(t(ValidationMessages.REQUIRED)),
    address: yup.string().required(t(ValidationMessages.REQUIRED)),
    city: yup.string().required(t(ValidationMessages.REQUIRED)),
    zip: yup.string().required(t(ValidationMessages.REQUIRED)),
    state: yup.string().required(t(ValidationMessages.REQUIRED)),
  });

  const onSubmit = useCallback((values) => {
    if (!user) return;
    const data: IBillingInfo = {
      ...values,
      country: values.country.code,
    };
    return updateAccountBillingInformation(data)
      .then(() => {
        showSnackbar(
          t("users:properties.user_creation_success", {
            first_name: user?.first_name,
            last_name: user?.last_name,
          }),
          "success",
        );
        showAppModal(null);
        invalidateQuery([...QK_USERS, user._id]);
        return refreshAppState();
      })
      .catch(() =>
        showSnackbar(
          t("users:properties.user_creation_error", {
            first_name: user?.first_name,
            last_name: user?.last_name,
          }),
          "error",
        ),
      );
  }, []);

  return (
    <Formik
      key={countries.length}
      validateOnMount={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ isSubmitting, isValid, submitForm, setFieldValue }) => {
        return (
          <Modal
            className={mode !== WindowSize.MOBILE ? "modal-lg" : ""}
            title={t("billing_info:subscription.title")}
            bodyClassName="modal-body modal-billing-tabs"
            buttons={
              currentTab === "plan" ? (
                <div className="billing-modal-footer">
                  <button className="btn btn-primary" onClick={() => (expiredMode ? logOut() : showAppModal(null))}>
                    {expiredMode ? t("billing_info:log_out") : t("common:close")}
                  </button>
                  <div className="u-mobile-only">{contact}</div>
                </div>
              ) : (
                <>
                  <>
                    <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                      {t("common:close")}
                    </button>
                    <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                      {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                      {t("common:save_changes")}
                    </button>
                  </>
                </>
              )
            }>
            <TabContext value={currentTab}>
              <TabList onChange={changeTab}>
                <Tab value="plan" label={t("billing_info:tab_label.plan")}></Tab>
                <Tab value="billing" label={t("billing_info:tab_label.billing")}></Tab>
              </TabList>
              <TabPanel value="plan">
                <PlanDetails
                  updatePaymentMethod={updatePaymentMethod}
                  displayUpdatePaymentButton={true}
                  accountSubscription={accountSubscription}
                  account={user?.Account}
                  expiredMode={expiredMode}
                />
                <div className="billing-buttons">
                  <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
                    <button
                      className="text-right btn btn-primary"
                      onClick={() => {
                        showAppModal(
                          <UpdatePlanModal
                            currentDevicesCount={accountSubscription?.find((sub) => sub.plan === "numberOfDevices")?.used}
                            currentUsersCount={accountSubscription?.find((sub) => sub.plan === "numberOfUsers")?.used}
                            calculateTotal={calculatePrice}
                          />,
                        );
                      }}>
                      {isAccountInDemoMode ? t("billing_info:subscription.update_to_regular") : t("billing_info:subscription.update_plan")}
                    </button>
                  </PermissionBlocker>
                  <PermissionBlocker permission={PermissionEnum.BILLING_ACCESS}>
                    <button className="u-mobile-hide text-right btn btn-primary" onClick={() => updatePaymentMethod()}>
                      {t("billing_info:subscription.update_payment_method")}
                    </button>
                  </PermissionBlocker>

                  {showCancellationButton() && (
                    <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
                      <button
                        className={classNames("text-right btn ", mode === WindowSize.MOBILE ? "btn-plain u-color-warning" : "btn-warning")}
                        onClick={() => showAppModal(<CancelSubscription />)}>
                        {t("billing_info:subscription.cancel_subscription")}
                      </button>
                    </PermissionBlocker>
                  )}

                  {showResumeButton() && (
                    <PermissionBlocker permission={PermissionEnum.SUBSCRIPTION_ACCESS}>
                      <button
                        className={classNames("text-right btn", mode === WindowSize.MOBILE ? "btn-plain u-color-warning" : "btn-warning")}
                        onClick={() => resumeSubscription()}>
                        {t("billing_info:subscription.resume_subscription")}
                      </button>
                    </PermissionBlocker>
                  )}
                </div>

                {user?.Account?.is_demo && user?.Account?.demo_start_date !== null && user?.Account?.demo_end_date !== null && (
                  <DemoRemainingDatesSection demoStartDate={user?.Account?.demo_start_date} demoEndDate={user?.Account?.demo_end_date} />
                )}

                {accountInvoices && (
                  <Column style={{ marginTop: 35 }}>
                    <Row>
                      <InvoicesTable invoices={accountInvoices} />
                    </Row>
                  </Column>
                )}

                <div className="type-small-regular email-link u-mobile-hide">{contact}</div>
              </TabPanel>
              <TabPanel value="billing">
                <div className="type-large-regular register-form-label">{t("register:steps.account.billing_info_title")}</div>
                <div className="row" style={{ marginBottom: "16px" }}>
                  <div className="col-sm-4">
                    <label className="input-label u-display-block">{t("register:steps.account.country")}</label>
                    <CountrySelect
                      name="country"
                      hidePhones={true}
                      value={selectedCountry}
                      formikControl={true}
                      selectedCountryLabel="name"
                      onChange={(value: ICountry) => {
                        setFieldValue("country", value);
                        setSelectedCountry(value);
                      }}
                    />
                  </div>
                  <FormFieldText
                    className="col-sm-4"
                    required={true}
                    displayError={true}
                    name="city"
                    label={t("register:steps.account.city")}
                    type="text"
                  />
                  <FormFieldText
                    required={true}
                    displayError={true}
                    name="address"
                    className="col-sm-4"
                    label={t("register:steps.account.address")}
                    type="text"
                  />
                </div>
                <div className="row" style={{ marginBottom: "16px" }}>
                  <FormFieldText
                    required={true}
                    displayError={true}
                    name="zip"
                    className="col-sm-4"
                    label={t("register:steps.account.zip")}
                    type="text"
                  />
                  <FormFieldText
                    required={true}
                    displayError={true}
                    name="state"
                    className="col-sm-4"
                    label={t("register:steps.account.state")}
                    type="text"
                  />
                  <FormFieldText
                    required={true}
                    displayError={true}
                    name="billing_id"
                    className="col-sm-4"
                    label={t("register:steps.account.billing_id")}
                    type="text"
                  />
                </div>
              </TabPanel>
            </TabContext>
          </Modal>
        );
      }}
    </Formik>
  );
});
