import {
  Step as MUIStep,
  StepIconProps,
  StepLabel as MUIStepLabel,
  StepLabelProps,
  Stepper as MUIStepper,
  StepperProps,
  StepProps,
} from "@mui/material";
import React from "react";
import "./Stepper.scss";
import { CircleIcon, RadioIcon } from "../icon";
import { useTranslation } from "react-i18next";
import { InputLabel } from "./InputLabel";

const CustomStepIcon = (props: StepIconProps) => {
  return props.completed || props.active ? <RadioIcon /> : <CircleIcon />;
};

export interface IFormStepProps {
  onClickBack: (values?: any) => void;
  onSubmit: (values?: any) => void;
}

export interface IStepperProps extends StepperProps {
  steps?: { key: any; label: string }[];
}

export const Stepper: React.FC<IStepperProps> = (props) => {
  const { activeStep, children: step, steps, ...rest } = props;

  const currentStep = steps ? steps[activeStep ?? 0] : undefined;
  return (
    <>
      <MUIStepper {...rest} activeStep={activeStep}>
        {steps?.map((step) => (
          <Step key={step.key}>
            <StepLabel className="u-mobile-hide">{step.label}</StepLabel>
            <StepLabel className="u-mobile-only" />
          </Step>
        ))}
      </MUIStepper>

      <div className={"u-mobile-only step-subtitle"}>
        <InputLabel name={""} label={currentStep?.label} />
        <div>{`Step ${(activeStep ?? 0) + 1}/${steps?.length}`}</div>
      </div>
      <div className="stepper-content">{step}</div>
    </>
  );
};

export const Step: React.FC<StepProps> = (props) => {
  return <MUIStep {...props} />;
};

export const StepLabel: React.FC<StepLabelProps> = (props) => {
  return <MUIStepLabel {...props} StepIconComponent={CustomStepIcon} />;
};

export interface IStepperActions {
  lastStep?: boolean;
  onClickBack: () => void;
  onClickNext: () => void;
  nextButtonMessage?: string;
  buttonDisabled?: boolean;
  previousButtonMessage?: string;
}

export const StepperActions: React.FC<IStepperActions> = ({
  onClickBack,
  onClickNext,
  lastStep = false,
  nextButtonMessage,
  buttonDisabled,
  previousButtonMessage,
  children,
}) => {
  const { t } = useTranslation("common");
  return (
    <div className="stepper-actions">
      <button className="btn btn-secondary" onClick={onClickBack}>
        {previousButtonMessage ?? t("common:back")}
      </button>
      {children}
      <button className="btn btn-primary" onClick={onClickNext} disabled={buttonDisabled}>
        {nextButtonMessage ?? (lastStep ? t("common:submit") : t("common:next"))}
      </button>
    </div>
  );
};
