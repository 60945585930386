import { Endpoint } from "./API";
import { IActualPaymentOptions, IPaymentOptions, IPlanProps } from "./Types";
import { ICurrency } from "./CurrencyService";
import { useQuery } from "react-query";

const API_PATH = "payment_options";

export const QK_SELECTED_AND_AVAILABLE = "payment_options";

export const GetPaymentOptions = (userCountryCode?: string, currencyCode?: string) =>
  Endpoint.get<IPaymentOptions>(`${API_PATH}/getAvailable?country_code=${userCountryCode}&currency_code=${currencyCode}`);

export const GetSelectedAndAvailablePaymentOptions = () => {
  return Endpoint.get<{ availableOptions: IPaymentOptions; selectedOptions: IActualPaymentOptions }>(
    `${API_PATH}/getAvailableWithActualSelected`,
  ).then((response) => {
    return response.data;
  });
};

export const useSelectedAndAvailablePaymentOptions = () =>
  useQuery(QK_SELECTED_AND_AVAILABLE, () => GetSelectedAndAvailablePaymentOptions());

export const CalculatePrice = (values: IPlanProps, userCountryCode: string, currencyCode: string) =>
  Endpoint.post<number>(`${API_PATH}/calculateTotal?country_code=${userCountryCode}&currency_code=${currencyCode}`, values).then(
    (res) => res.data,
  );

export const getCurrenciesForDropdown = (onlyActive = false, countryId?: number) =>
  Endpoint.get<ICurrency[]>(`${API_PATH}/getAvailableCurrencies?onlyActive=${onlyActive}&countryId=${countryId}`).then((res) => {
    return res.data as ICurrency[];
  });
