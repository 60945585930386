import classNames from "classnames";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import "./SubscriptionManagement.scss";
import { showAppModal } from "../../AppState";
import { XIcon } from "@heroicons/react/solid";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { FormFieldText, ToggleInput } from "../../Components";
import { Button } from "@mui/material";
import { SubscriptionOptionType } from "../../Enums";
import { Formik } from "formik";
import * as yup from "yup";
import { ICurrency } from "../../Managers/CurrencyService";

export interface INewOption {
  name: string;
  description: string;
  defaultPrice: number;
  value: number;
  active: boolean;
  default: boolean;
}

interface IAddOptionModalProps {
  onAdd: (values: INewOption) => void;
  copyToClipboard: (text: string) => void;
  currency?: ICurrency;
  optionType?: SubscriptionOptionType;
}

export const AddOptionModal: React.FC<IAddOptionModalProps> = observer(({ onAdd, copyToClipboard, currency, optionType }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation(["subscription_management", "common"]);

  const validationSchema = yup.object({
    active: yup.boolean(),
    default: yup.boolean(),
    defaultPrice: yup
      .number()
      .required(t("common:required"))
      .min(0, t("common:formik_errors.min", { value: 0 })),
    description: yup.string().required(t("common:required")),
    name: yup.string().required(t("common:required")),
    value: yup
      .number()
      .required(t("common:required"))
      .min(0, t("common:formik_errors.min", { value: 0 }))
      .integer(t("common:integer")),
  });

  const initialValues: INewOption = {
    name: "",
    description: "",
    defaultPrice: 0,
    value: 1,
    active: false,
    default: false,
  };

  const handleSubmit = (values: INewOption) => {
    setIsSaving(true);
    onAdd(values);
    showAppModal(null);
    setIsSaving(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, submitForm, setFieldValue, touched, errors, handleChange }) => {
        return (
          <Modal
            title={
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h4 className="modal-title">{t("subscription_management:add_option_modal.title")}</h4>
                <div style={{ flex: 1 }} />
                <button onClick={() => showAppModal(null)} style={{ background: "none", color: "white", border: "none" }}>
                  <XIcon style={{ width: "18px", marginBottom: "-4px" }} /> {t("common:close")}
                </button>
              </div>
            }
            buttons={
              <>
                <Button className="btn btn-secondary" variant="contained" disabled={isSaving} onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  onClick={submitForm}
                  style={{ textTransform: "capitalize" }}
                  className={classNames("btn btn-primary", { disabled: isSaving })}>
                  {isSaving ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("subscription_management:option.add")}
                </Button>
              </>
            }>
            <div className="mobile-view-item add-option-from-wrapper">
              <div className="mobile-row">
                <label className="input-label u-display-block">{t("subscription_management:item.name")}</label>
                <FormFieldText name="name" displayError={touched.name && Boolean(errors.name)} type="text" onChange={handleChange} />
              </div>
              <div className="mobile-row">
                <label className="input-label u-display-block">{t("subscription_management:option.description")}</label>
                <FormFieldText
                  name="description"
                  displayError={touched.description && Boolean(errors.description)}
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="mobile-row mobile-row-numbers">
                <div className="mobile-column">
                  <label className="input-label u-display-block">{t("subscription_management:option.stripe_id")}</label>
                  <Button variant="text" className="copy-button" onClick={() => copyToClipboard}>
                    <i className="fa fa-copy" /> {t("common:copy").toUpperCase()}
                  </Button>
                </div>
                <div className="mobile-column">
                  <label className="input-label u-display-block">
                    {t("subscription_management:option.price")} ({currency?.sign})
                  </label>
                  <FormFieldText
                    name="defaultPrice"
                    displayError={touched.defaultPrice && Boolean(errors.defaultPrice)}
                    type="number"
                    onChange={handleChange}
                  />
                </div>
                <div className="mobile-column">
                  <label className="input-label u-display-block">
                    {t("subscription_management:option.type", {
                      context: optionType === SubscriptionOptionType.transmission ? "time" : "amount",
                    })}
                  </label>
                  <FormFieldText name="value" displayError={touched.value && Boolean(errors.value)} type="number" onChange={handleChange} />
                </div>
              </div>
              <div className="mobile-row">
                <ToggleInput
                  className="active-toggle"
                  onChange={(value) => setFieldValue("active", value)}
                  name="active"
                  activeLabel={t("subscription_management:option.enabled")}
                  inactiveLabel={t("subscription_management:option.disabled")}
                  checked={values.active}
                />
              </div>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
});
