import { Modal } from "../Components/Modal";
import { useTranslation } from "react-i18next";
import { showAppModal, showSnackbar } from "../AppState";
import React, { useEffect, useState } from "react";
import { ICountry, myOrder, mySearch, saveMainCountries } from "../Managers";
import { useCountries } from "../Managers/API";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../Components/Table";
import { CheckboxInput, Flag, TextInput } from "../Components";
import "./CountriesModal.scss";

export const CountriesModal: React.FC = () => {
  const [mainCountries, setMainCountries] = useState<ICountry[]>([]);
  const [search, setSearch] = useState("");

  const { t } = useTranslation(["country_modal", "common"]);
  const countries = useCountries(false);

  useEffect(() => setMainCountries(countries.data?.filter((c) => c.main) ?? []), [countries.isFetched]);

  const saveMainCounties = () =>
    saveMainCountries(mainCountries)
      .then(() => {
        showSnackbar(t("country_modal:save_success"), "success");
        showAppModal(null);
      })
      .catch(() => {
        showSnackbar(t("country_modal:save_error"), "error");
      });

  const handleCountryClick = (country: ICountry) => {
    const index = mainCountries.findIndex((c) => c._id === country._id);
    const newMainCountries = mainCountries.slice();

    if (index > -1) {
      newMainCountries.splice(index, 1);
    } else {
      newMainCountries.push(country);
    }

    setMainCountries(newMainCountries);
  };

  return (
    <Modal
      className="modal-lg country-modal"
      bodyClassName="hide-scroll"
      title={t("country:title")}
      buttons={
        <>
          <button className="btn btn-info" onClick={() => showAppModal(null)}>
            {t("common:cancel")}
          </button>
          <button onClick={() => saveMainCounties()} className="btn btn-primary">
            {t("common:apply")}
          </button>
        </>
      }>
      <div style={{ height: "60vh", overflow: "auto" }}>
        <TextInput
          icon={<i className="fa fa-search" />}
          placeholder={t("common:find")}
          inputClass="dark"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          name="country-search"
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("country_modal:main")}</TableCell>
              <TableCell>{t("country_modal:name")}</TableCell>
              <TableCell>{t("country_modal:dial_code")}</TableCell>
              <TableCell>{t("country_modal:code")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myOrder(mySearch(countries.data ?? [], search), "name").map((c) => (
              <TableRow key={c.code}>
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleCountryClick(c)}
                    checked={mainCountries.some((mainCountry) => mainCountry._id === c._id)}
                    name={`country-${c.code}`}
                  />
                </TableCell>
                <TableCell>
                  <Flag countryCode={c.code} /> {c.name}
                </TableCell>
                <TableCell>{c.dial_code}</TableCell>
                <TableCell>{c.code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
};
