import React, { useEffect, useState } from "react";
import "./LeftRail.scss";
import classNames from "classnames";
import { ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "./StyledTooltip";
import { OrderBy } from "../Enums";
import { useTranslation } from "react-i18next";
import { Collapse } from "react-collapse";
import { CheckboxInput } from "./CheckboxInput";
import { mySearch } from "../Managers";
import { IGateway } from "../Managers/Types";

interface ILeftRailProps {
  header: JSX.Element;
  body: JSX.Element;
  onClick?: () => void;
  className?: string;
}

export const LeftRail: React.FC<ILeftRailProps> = ({ header, body, onClick, className }) => {
  return (
    <aside className={classNames("left-rail", className)} onClick={onClick}>
      <header className="left-rail-header">{header}</header>
      <section className="left-rail-body">{body}</section>
    </aside>
  );
};

interface ISortByNameLeftRailHeaderProps {
  order: OrderBy;
  setOrder: (order: OrderBy) => void;
}

export const SortByNameLeftRailHeader: React.FC<ISortByNameLeftRailHeaderProps> = ({ order, setOrder, children }) => {
  const { t } = useTranslation("common");
  return (
    <h2 className="left-rail-nav-header-title mod-with-btn">
      <StyledTooltip
        title={t("common:sort_by_name", { context: order === OrderBy.ASC ? "desc" : "asc" })}
        enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
        <button className="btn btn-plain" onClick={() => setOrder(order === OrderBy.DESC ? OrderBy.ASC : OrderBy.DESC)}>
          <i className={classNames("fa fa-sort-amount-asc", { "u-display-none": order === OrderBy.ASC })} />
          <i className={classNames("fa fa-sort-amount-desc", { "u-display-none": order !== OrderBy.ASC })} />
        </button>
      </StyledTooltip>{" "}
      {children}
    </h2>
  );
};

interface ILeftRailGatewayPickerProps {
  disabled: boolean;
  selectedGateways: IGateway[];
  setGatewayOrder: (order: OrderBy) => void;
  gatewayOrder: OrderBy;
  gateways: IGateway[];
  selectGateways: (gateways: IGateway[]) => void;
  searchString: string;
  setSearchString: (search: string) => void;
}

export const LeftRailGatewayPicker: React.FC<ILeftRailGatewayPickerProps> = ({
  disabled,
  gatewayOrder,
  selectedGateways,
  setGatewayOrder,
  gateways,
  selectGateways,
  searchString,
  setSearchString,
}) => {
  const [gatewaysOpened, setGatewaysOpened] = useState(false);

  useEffect(() => {
    if (gateways.length > 0) {
      setGatewaysOpened(true);
    }
  }, [gateways]);

  const { t } = useTranslation("common");

  const setGateways = (gateway: IGateway) => {
    const modifiedGateways = selectedGateways.slice();
    const gatewayIndex = selectedGateways.findIndex((g) => g._id === gateway._id);

    if (gatewayIndex > -1) {
      modifiedGateways.splice(gatewayIndex, 1);
    } else {
      modifiedGateways.push(gateway);
    }

    selectGateways(modifiedGateways);
  };

  return (
    <>
      {gateways.length > 10 ? (
        <div className="input-holder u-full-width">
          <input
            type="text"
            className="input input-default"
            placeholder={t("common:find_gateway")}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
          <i className="fa fa-search input-holder-icon" />
        </div>
      ) : null}
      <nav className="left-rail-nav">
        <div
          onClick={() => setGatewaysOpened(!gatewaysOpened)}
          className={classNames("left-rail-nav-header gateways-header", { disabled })}>
          <SortByNameLeftRailHeader order={gatewayOrder} setOrder={setGatewayOrder}>
            {t("common:gateways")}
            <i className={classNames("fa fa-chevron-down fa-rotate", { rotated: gatewaysOpened })} />
          </SortByNameLeftRailHeader>
        </div>
        <Collapse isOpened={gatewaysOpened}>
          <ul className="left-rail-nav-group">
            <li className="left-rail-nav-item" key="all-devices">
              <CheckboxInput
                label={t("common:all_gateways", { count: gateways?.length ?? 0 })}
                onChange={() => selectGateways(selectedGateways.length === gateways.length ? [] : gateways)}
                checked={selectedGateways.length === gateways?.length}
                name="all"
              />
            </li>
            {mySearch<IGateway>(gateways ?? [], searchString).map((gateway) => (
              <li className="left-rail-nav-item" key={gateway._id}>
                <CheckboxInput
                  label={gateway.name}
                  onChange={() => setGateways(gateway)}
                  checked={selectedGateways.some((g) => g._id === gateway._id)}
                  name={gateway.name}
                />
              </li>
            ))}
          </ul>
        </Collapse>
      </nav>
    </>
  );
};
