import { observer } from "mobx-react-lite";
import { LocationDropDown } from "../Dashboard/LocationDropdown";
import classNames from "classnames";
import { LeftRail, LeftRailGatewayPicker, SortByNameLeftRailHeader } from "../../Components";
import { mySearch } from "../../Managers";
import { IDevice, IGateway, ILocation } from "../../Managers/Types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderBy } from "../../Enums/SortingTypes";

export const AlertHistoryLeftRail: React.FC<{
  changeLocation: (location: ILocation) => void;
  location: ILocation;
  selectGateways: (gateway: IGateway[]) => void;
  selectedGateways: IGateway[];
  setSelectedDevice: (device: IDevice | null) => void;
  devices: IDevice[];
  searchString: string;
  setSearchString: (search: string) => void;
  setDeviceOrder: (order: OrderBy) => void;
  deviceOrder: OrderBy;
  selectedDevice: IDevice | null;
}> = observer(
  ({
    selectedGateways,
    selectGateways,
    location,
    changeLocation,
    deviceOrder,
    setDeviceOrder,
    selectedDevice,
    setSelectedDevice,
    searchString,
    setSearchString,
    devices,
  }) => {
    const { t } = useTranslation(["alert_history", "sensor_types", "common"]);

    const [gatewayOrder, setGatewayOrder] = useState<OrderBy>(OrderBy.ASC);

    return (
      <LeftRail
        className="u-mobile-hide u-mobile-hide"
        header={
          <div style={{ width: "100%" }}>
            <LocationDropDown displayAllOption onChange={(loc) => changeLocation(loc)} selected={location} />
          </div>
        }
        body={
          <>
            {location._id ? (
              <LeftRailGatewayPicker
                disabled={!location.Gateways?.length}
                selectedGateways={selectedGateways}
                setGatewayOrder={setGatewayOrder}
                gatewayOrder={gatewayOrder}
                gateways={location.Gateways ?? []}
                selectGateways={selectGateways}
                searchString={searchString}
                setSearchString={setSearchString}
              />
            ) : null}

            <nav className="left-rail-nav alert-history-nav">
              <div className="left-rail-nav-header">
                <SortByNameLeftRailHeader order={deviceOrder} setOrder={setDeviceOrder}>
                  {t("alert_history:device_list")}
                </SortByNameLeftRailHeader>
              </div>

              <ul className="left-rail-nav-group">
                <li className={classNames("left-rail-nav-item", { active: selectedDevice === null })} key="all-devices">
                  <a onClick={() => setSelectedDevice(null)}>{t("common:all_devices", { count: devices.length })}</a>
                </li>

                <div className="input-label">{t("alert_history:active")}</div>
                {mySearch<IDevice>(
                  devices.filter((d) => d.is_active),
                  searchString,
                ).map((device) => (
                  <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                    <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                  </li>
                ))}
                <div className="input-label">{t("alert_history:inactive")}</div>
                {mySearch<IDevice>(
                  devices.filter((d) => !d.is_active),
                  searchString,
                ).map((device) => (
                  <li className={classNames("left-rail-nav-item", { active: selectedDevice?._id === device._id })} key={device._id}>
                    <a onClick={() => setSelectedDevice(device)}>{device.name}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </>
        }
      />
    );
  },
);
