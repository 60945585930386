import * as yup from "yup";
import { Formik } from "formik";
import React, { useState } from "react";
import {
  CheckboxInput,
  CountrySelect,
  Flag,
  FormFieldSelect,
  FormFieldText,
  Row,
  SelectInput,
  StyledTooltip,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  ToggleInput,
} from "../Components";
import { useCountries, useCurrencies } from "../Managers/API";
import { addCurrency, getCurrencyIsoCodesForDropdown, ICurrency, updateCurrency } from "../Managers/CurrencyService";
import { showAppModal, showSnackbar } from "../AppState";
import classNames from "classnames";
import { ConfirmModal } from "./ConfirmModal";
import { Modal } from "../Components/Modal";
import { ICountry } from "../Managers";
import { useTranslation } from "react-i18next";
import "./CurrenciesModal.scss";

const REQUIRED_MESSAGE = "Field required";

const validationSchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE),
  iso_code: yup.string().required(REQUIRED_MESSAGE),
  sign: yup.string().required(REQUIRED_MESSAGE),
  active: yup.boolean(),
});

type CurrencyTabs = "main" | "countries";

export const CurrenciesModal: React.FC = () => {
  const fetchedCountries = useCountries(true);
  const currenciesQuery = useCurrencies();
  const countries = fetchedCountries.data!;

  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState<CurrencyTabs>("main");
  const [mode, setMode] = useState<"editCurrency" | "createCurrency">("createCurrency");
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(null);
  const [selectedCountries, setSelectedCountries] = useState<Array<ICountry>>(selectedCurrency?.Countries ?? []);
  const { t } = useTranslation(["currencies_modal", "common", "countries"]);

  const initialValues = {
    _id: selectedCurrency?._id ?? 0,
    name: selectedCurrency?.name ?? "",
    iso_code: selectedCurrency?.iso_code ?? "",
    sign: selectedCurrency?.sign ?? "",
    Countries: selectedCurrency !== null ? selectedCurrency.Countries : [countries.find((c) => c.code === "US")!],
    active: selectedCurrency?.active ?? false,
  };

  const selectCurrency = (currency: ICurrency | null) => {
    if (currency == null) {
      setSelectedCurrency(null);
      return;
    }

    setMode("editCurrency");
    setIsSaving(false);
    setSelectedCurrency(currency);
    setSelectedCountries(currency.Countries);
  };

  const handleAddCurrency = () => {
    if (mode !== "createCurrency") {
      setMode("createCurrency");
    }

    setSelectedCurrency(null);
    setSelectedCountries([]);
  };

  const _updateCurrency = (values: ICurrency) => {
    setIsSaving(true);

    return updateCurrency(composeValues(values))
      .then(() => showSnackbar(t("currencies_modal:update_success"), "success"))
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("currencies_modal:update_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const createCurrency = (values: ICurrency) => {
    setIsSaving(true);

    return addCurrency(composeValues(values))
      .then((r) => {
        console.log("Add result", r);
        const newSelectedCurrency = { ...r };
        newSelectedCurrency.country = countries.find((c) => c.code === r.country_code);
        setSelectedCurrency(r);
        showSnackbar(t("currencies_modal:add_success"), "success");
      })
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("currencies_modal:add_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const editCurrency = (values: ICurrency) => {
    setIsSaving(true);
    if (mode === "editCurrency" && selectedCurrency) {
      _updateCurrency(composeValues(values));
    } else if (mode === "createCurrency") {
      createCurrency(composeValues(values));
    }
    showAppModal(null);
  };

  const composeValues = (values: ICurrency) => {
    return {
      ...values,
      Countries: selectedCountries,
    } as ICurrency;
  };

  const showDeleteConfirm = () => {
    const currencyToSoftDelete = selectedCurrency;
    showAppModal(
      <ConfirmModal
        header={t("currencies_modal:delete_title")}
        confirmText="Delete Currency"
        onConfirm={() => {
          if (currencyToSoftDelete == null) return;
        }}>
        <p>{t("currencies_modal:delete_confirm")}</p>
        <p>{t("currencies_modal:delete_confirm_info")}</p>
      </ConfirmModal>,
    );
  };

  const renderValue = (country: any) => (
    <>
      <Flag countryCode={country.code} /> {getText(country)}
    </>
  );

  const getText = (country: ICountry) => t(`countries:${country.code}`);

  const changeTab = (event: React.SyntheticEvent, newValue: CurrencyTabs) => {
    setCurrentTab(newValue);
  };

  const toggleCountry = (country: ICountry) => {
    setSelectedCountries(
      selectedCountries.includes(country) ? selectedCountries.filter((c) => c.code !== country.code) : [...selectedCountries, country],
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={editCurrency} enableReinitialize={true}>
      {({ isSubmitting, submitForm, setFieldValue, values }) => {
        return (
          <Modal
            title={t("currencies_modal:title")}
            bodyClassName="currency-modal"
            buttons={
              <>
                <button className="btn btn-info" onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-default" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting || isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {mode === "editCurrency" ? t("common:save_changes") : t("currencies_modal:add_currency")}
                </button>
              </>
            }>
            <div className="left-rail left-panel">
              <div className="left-rail-body">
                <nav className="left-rail-nav">
                  <div className="left-rail-nav-header">
                    <h5 className="left-rail-nav-header-title">{t("currencies_modal:add_currency")}</h5>
                    <StyledTooltip title={t("currencies_modal:currencies")} enterDelay={500}>
                      <div className="btn btn-circle btn-primary add-button" onClick={handleAddCurrency}>
                        <i className="fa fa-plus" />
                        <span className="sr-only">{t("currencies_modal:add_currency")}</span>
                      </div>
                    </StyledTooltip>
                  </div>

                  <ul className="left-rail-nav-group">
                    {currenciesQuery.data?.map((currency) => (
                      <li
                        key={currency._id}
                        className={classNames("left-rail-nav-item", {
                          active: selectedCurrency !== null && selectedCurrency?._id === currency._id,
                        })}>
                        <div onClick={() => selectCurrency(currency)} style={{ display: "grid" }}>
                          {currency.name || "Currency " + currency._id}
                          <span className="left-rail-nav-item-sub u-display-block"> {currency.iso_code}</span>
                        </div>
                      </li>
                    ))}

                    {mode === "createCurrency" ? <li className="left-rail-nav-item active">{t("currencies_modal:new_currency")}</li> : null}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="right-panel main">
              <TabContext value={currentTab}>
                <TabList onChange={changeTab}>
                  <Tab value="main" label={t("currencies_modal:tab_label.main")}></Tab>
                  <Tab value="countries" label={t("currencies_modal:tab_label.countries")}></Tab>
                </TabList>
                <TabPanel value="main">
                  {mode === "editCurrency" && currenciesQuery.data && currenciesQuery.data.length > 1 ? (
                    <StyledTooltip title={t("currencies_modal:delete_currency")} enterDelay={500}>
                      <button className="btn btn-icon delete-button currencies" onClick={showDeleteConfirm}>
                        {!isSubmitting ? <i className="fa fa-trash" /> : <></>}
                        <span className="sr-only">{t("currencies_modal:delete_currency")}</span>
                      </button>
                    </StyledTooltip>
                  ) : null}

                  <div>
                    <FormFieldText
                      name="name"
                      className="col-sm-12"
                      label={t("currencies_modal:currency_name")}
                      placeholder={t("currencies_modal:currency_name_placeholder")}
                      required={true}
                    />

                    <FormFieldSelect
                      value={values?.iso_code}
                      label={t("currencies_modal:currency_iso")}
                      name="iso_code"
                      className="col-sm-6"
                      required={true}
                      options={getCurrencyIsoCodesForDropdown()}
                    />

                    <Row>
                      <FormFieldText
                        name="sign"
                        className="col-sm-6"
                        label={t("currencies_modal:currency_sign")}
                        placeholder={t("currencies_modal:currency_sign_placeholder")}
                        required={true}
                      />
                    </Row>

                    <ToggleInput
                      className="col-sm-6"
                      style={{ marginTop: "10px" }}
                      onChange={(value) => setFieldValue("active", value)}
                      name="active"
                      activeLabel={t("common:active")}
                      inactiveLabel={t("common:inactive")}
                      checked={!!values?.active || false}
                    />
                  </div>
                </TabPanel>
                <TabPanel value="countries">
                  <CheckboxInput
                    label={t("currencies_modal:all_countries", { count: countries.length ?? 0 })}
                    onChange={(e) => setSelectedCountries(e.target.checked ? countries : [])}
                    checked={selectedCountries.length === countries.length}
                    name="all"
                  />
                  <ul className="left-rail-nav-group country-list">
                    {countries.map((country) => {
                      const checked = selectedCountries.some((c) => c.code === country.code);
                      return (
                        <li className={classNames("left-rail-nav-item", checked ? "checked" : "")} key={country.code}>
                          <CheckboxInput
                            label={renderValue(country)}
                            onChange={() => toggleCountry(country)}
                            checked={checked}
                            name={country.name}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </TabPanel>
              </TabContext>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};
