import React, { useEffect, useState } from "react";
import { AppState, showAppModal } from "../AppState";
import { Button, Snackbar } from "@mui/material";
import { Modal } from "../Components/Modal";
import { closeNotification, getMessagesByType } from "../Managers/ManageSystemMessageService";
import { IManageSystemMessage } from "../Managers/Types";
import { Colors } from "../Theme";
import { ModalOverlay } from "../Components";
import { useTranslation } from "react-i18next";

const SystemMessageModal = () => {
  const [isWelcomeMessageShown, setIsWelcomeMessageShown] = useState(false);
  const [isAnnouncementShown, setIsAnnouncementShown] = useState(false);
  const [isMaintenanceNotificationShown, setIsMaintenanceNotificationShown] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState<IManageSystemMessage | null>(null);
  const [announcementMessage, setAnnouncementMessage] = useState<IManageSystemMessage | null>(null);
  const [maintenanceMessage, setMaintenanceMessage] = useState<IManageSystemMessage | null>(null);

  const { t } = useTranslation(["system_message", "common"]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const welcomeMessageFromState = AppState.systemMessages.find((msg) => msg.type === "welcome");
        const announcementMessageFromState = AppState.systemMessages.find((msg) => msg.type === "announcement");
        const maintenanceMessageFromState = AppState.systemMessages.find((msg) => msg.type === "maintenance");

        if (welcomeMessageFromState) {
          setWelcomeMessage(welcomeMessageFromState);
        } else {
          const messages = await getMessagesByType("welcome");
          const welcomeMsg = messages.find((msg) => msg.type === "welcome");
          if (welcomeMsg) {
            setWelcomeMessage(welcomeMsg);
          }
        }

        if (announcementMessageFromState) {
          setAnnouncementMessage(announcementMessageFromState);
        } else {
          const messages = await getMessagesByType("announcement");
          const announcementMsg = messages.find((msg) => msg.type === "announcement");
          if (announcementMsg) {
            setAnnouncementMessage(announcementMsg);
          }
        }

        if (maintenanceMessageFromState) {
          setMaintenanceMessage(maintenanceMessageFromState);
        } else {
          const messages = await getMessagesByType("maintenance");
          const maintenanceMsg = messages.find((msg) => msg.type === "maintenance");
          if (maintenanceMsg) {
            setMaintenanceMessage(maintenanceMsg);
          }
        }
      } catch (error) {
        console.error("Error fetching system messages:", error);
      }
    };

    if (AppState.user) {
      setIsAnnouncementShown(AppState.user.is_announcement_shown);
      setIsWelcomeMessageShown(AppState.user.is_welcome_message_shown);
      setIsMaintenanceNotificationShown(AppState.user.is_maintenance_notification_closed);
      fetchMessages();
    }
  }, [AppState.user]);

  const handleClose = async (type: "welcome" | "announcement" | "maintenance") => {
    if (AppState.user) {
      try {
        await closeNotification(type, AppState.user._id);
        if (type === "announcement") {
          setIsAnnouncementShown(true);
          AppState.user.is_announcement_shown = true;
        } else if (type === "welcome") {
          setIsWelcomeMessageShown(true);
          AppState.user.is_welcome_message_shown = true;
        } else if (type === "maintenance") {
          setIsMaintenanceNotificationShown(true);
          AppState.user.is_maintenance_notification_closed = true;
        }
      } catch (error) {
        console.error("Error closing notification:", error);
      }
    }
    showAppModal(null);
  };

  return (
    <>
      {!isAnnouncementShown && announcementMessage && announcementMessage.active && (
        <ModalOverlay>
          <Modal
            title={t("system_message:announcement")}
            buttons={
              <Button variant="contained" onClick={() => handleClose("announcement")}>
                {t("common:ok")}
              </Button>
            }>
            <div dangerouslySetInnerHTML={{ __html: announcementMessage.message }} />
          </Modal>
        </ModalOverlay>
      )}

      {!isWelcomeMessageShown && welcomeMessage && welcomeMessage.active && (
        <ModalOverlay>
          <Modal
            title={t("system_message:welcome")}
            buttons={
              <Button variant="contained" onClick={() => handleClose("welcome")}>
                {t("common:ok")}
              </Button>
            }>
            <div dangerouslySetInnerHTML={{ __html: welcomeMessage.message }} />
          </Modal>
        </ModalOverlay>
      )}

      {!isMaintenanceNotificationShown && maintenanceMessage && maintenanceMessage.active && (
        <Snackbar
          color="error"
          className="maintenance-notification-snackbar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          ContentProps={{ sx: { background: Colors.alert, fontSize: 15 } }}
          open={maintenanceMessage.active}
          message={maintenanceMessage.message}
          action={
            <button className="close-button" onClick={() => handleClose("maintenance")}>
              x
            </button>
          }
        />
      )}
    </>
  );
};

export default SystemMessageModal;
